
namespace Sparks
{
    export class Enum
    {
        //#region Public Methods

        //public static parse(enumType: Type, value: string): number
        //{
        //    if (!enumType.isEnum)
        //        throw new Error("Supplied type " + enumType.fullname + " is not an enum type");

        //    var enumValue = enumType.typeReference[value];
        //    if (!Spark.Object.isNumber(value))
        //        throw new Error("Unable to parse supplied value '" + value + "' for enum type " + enumType.fullname);

        //    return value;
        //}

        //public static getName(enumType: Type, value: number): string;
        //public static getName(enumTypeReference: Native.Object, value: number): string;
        //public static getName(enumTypeOrTypeReference: Type, value: number): string
        //{
        //    var enumTypeReference = (enumTypeOrTypeReference instanceof Type) ? enumTypeOrTypeReference.typeReference : enumTypeOrTypeReference;
        //    var name = enumTypeReference[value];
        //    if (!String.isString(name))
        //        throw new Error("Invalid value " + value + " for enum type " + enumTypeReference["$typeinfo"].fullname);
        //    return name;
        //}

        //public static getNames(enumType: Type): string[];
        //public static getNames(enumTypeReference: Native.Object): string[];
        //public static getNames(enumTypeOrTypeReference: Type | Native.Object): string[]
        //{
        //    if (enumTypeOrTypeReference instanceof Type)
        //    {
        //        if (!enumTypeOrTypeReference.isEnum)
        //            throw new Error("Supplied type " + enumTypeOrTypeReference.fullname + " is not an enum type");

        //        enumTypeOrTypeReference = <Native.Object>enumTypeOrTypeReference.typeReference;
        //    }
            
        //    return Spark.Object.getOwnPropertyNames(enumTypeOrTypeReference).filter(
        //        entry => Spark.Object.isNumber(enumTypeOrTypeReference[entry]));
        //}

        //public static getValues(enumType: Type): any[];
        //public static getValues(enumTypeReference: Native.Object): any[]
        //public static getValues(enumTypeOrTypeReference: Type | Native.Object): any[]
        //{
        //    if (enumTypeOrTypeReference instanceof Type)
        //    {
        //        if (!enumTypeOrTypeReference.isEnum)
        //            throw new Error("Supplied type " + enumTypeOrTypeReference.fullname + " is not an enum type");

        //        enumTypeOrTypeReference = <Native.Object>enumTypeOrTypeReference.typeReference;
        //    }

        //    return Spark.Object.getOwnPropertyNames(enumTypeOrTypeReference).filter(
        //        entry => !isNaN(parseInt(entry))).map(entry => enumTypeOrTypeReference[entry]);
        //}

        //public static toString(enumTypeReference: Object, value: number): string
        //{
        //    return this.getName(enumTypeReference, value);
        //}

        //#endregion
    }
}
