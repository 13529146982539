
namespace Sparks.Apps.UI
{
    export class View
    {
        //#region Constructor

        public constructor(name: string)
        {
            this.name = name;
        }

        //#endregion


        //#region Public Methods

        public createView(name: string): View
        {
            if (this.views[name])
                throw new Error("A view already exists with name '" + name + "' in this context.");
            var view = new View(name);
            this.addView(view);
            return view;
        }

        public addView(view: View): void
        {
            if (this.views[view.name])
                throw new Error("A view already exists with name '" + view.name + "' in this context.");

            view.showing.add(this.view_showing);
            //view.hiding.add(this.view_hiding);

            this.views[view.name] = view;
        }

        public show(): void
        {
            if (this.isViewable)
                return;

            this.isViewable = true;
            this.showing.invoke(this);
            this.updated.invoke(this);
        }

        public hide(): void
        {
            if (!this.isViewable)
                return;

            this.isViewable = false;
            this.hiding.invoke(this);

            Sparks.Map.getValues(this.views)
                .forEach(item => item.hide());

            this.updated.invoke(this);
        }

        //#endregion


        //#region Public Events

        public showing = new Sparks.Event();
        public hiding = new Sparks.Event();
        public updated = new Sparks.Event();

        //#endregion


        //#region Public Properties

        public name: string;

        public isViewable: boolean = false;

        public views: Sparks.Map<View> = {};

        //#endregion


        //#region Private Methods

        private view_showing =
            (view: View) =>
            {
                if (!this.isViewable)
                    this.show();

                Sparks.Map.getValues(this.views)
                    .filter(item => item != view)
                    .forEach(item => item.hide());
            };

        //private view_hiding =
        //    () =>
        //    {
        //    };

        //#endregion
    }
}
