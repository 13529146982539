
namespace Sparks.Apps.Forms
{
    export enum FormState
    {
        Undefined = 0,

        NotReady,
        Ready,
        Validating,
        Validated,
        Submitting,
        Submitted,

        Error = 0xf000,
        ValidationError,
        SubmissionError,
    }
}