
//namespace Simpleo.CustomerPortal
//{
//    export class Navigator
//    {
//        //#region Constructor

//        public constructor()
//        {
//        }

//        //#endregion


//        //#region Public Methods

//        public initialize(): void
//        {
           
//        }

//        public navigateTo(location: string): Promise<void>
//        {
//            throw new Error();
//        }

//        //#endregion


//        //#region Public Events

//        public navigating = new Sparks.Event();
//        public updated = new Sparks.Event();

//        //#endregion


//        //#region Public Properties

//        public location: string = null;
//        public destination: string = null;
                
//        //#endregion


//        //#region Protected Methods


//        //#endregion
//    }
//}
