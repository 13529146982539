
namespace Simpleo.CustomerPortal
{
    export class RoutingManager extends Sparks.Apps.Routing.RoutingManager
    {
        //#region Constructor

        public constructor()
        {
            super();
        }

        //#endregion


        //#region Public Methods

        public static loadRoutes(services: Application.ServiceProvider): void
        {
            var appRoutes =
                Sparks.Apps.Routing.RouteBuilder.create()
                    .when(() => services.app.isSignedIn())
                    .useRoute(null);

        }

        //#endregion
    }
}
