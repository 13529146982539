/// <reference path="../Core/Event.ts" />

namespace Sparks.DOM
{
    export class DOMEvent extends Sparks.Event<EventArgs>
    {
        //#region Constructor

        public constructor(eventTarget: EventTarget, eventType: EventType)
        {
            super();
            this._eventTarget = eventTarget;
            this._eventType = eventType;
            this._eventListener = evt => this.onEvent(evt);
        }
        
        //#endregion


        //#region Public Methods

        public add(eventHandler: EventHandler<EventArgs>): void;
        public add(target: Object, eventHandler: EventHandler<EventArgs>): void;
        public add(): void
        {
            super.add(arguments[0], arguments[1]);
            if (this.delegates.length == 1)
            {
                var eventName = EventType[this._eventType].toLowerCase();
                this._eventTarget.addEventListener(eventName, this._eventListener);
            }
        }

        public remove(eventHandler: EventHandler<EventArgs>): void;
        public remove(target: Object, eventHandler: EventHandler<EventArgs>): void;
        public remove(): void
        {
            super.remove(arguments[0], arguments[1]);
            if (this.delegates.length < 1)
            {
                var eventName = EventType[this._eventType].toLowerCase();
                this._eventTarget.removeEventListener(eventName, this._eventListener);
            }   
        }

        public invoke(sender: any, eventArgs: EventArgs): any
        {
            var ret = super.invoke(sender, eventArgs);
            if (ret === false && eventArgs.preventDefault)
                eventArgs.preventDefault();
            return ret;
        }

        //#endregion


        //#region Private Methods

        private onEvent(evt: EventArgs) : any
        {
            return this.invoke.apply(this, [this._eventTarget, evt]);
        }

        //#endregion


        //#region Private Fields
        
        private _eventTarget: EventTarget;
        private _eventType: EventType;
        private _eventListener: EventListener;

        //#endregion
    }
}
