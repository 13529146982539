///<reference path="../FormState.ts" />

namespace Sparks.Apps.Forms
{
    export class FormController
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        public initialize(): void
        {
            this.state = (this.state >= FormState.Ready) ? this.state : FormState.Ready;
        }

        public addField(fieldController: FieldController): void
        {
            if (this.fields[fieldController.fieldName])
                throw new Error("Duplicate field controllers for " + fieldController.fieldName);

            this.fields[fieldController.fieldName] = fieldController;
        }

        public async validate(): Promise<boolean>
        {
            if (this.isValidationDisabled)
                return true;

            this.onValidating();

            var fields = Map.getValues(this.fields);

            try
            {
                var validationResults = await Promise.all(fields.map(field => field.validate()));
                var valid = validationResults.every(result => result === true);
            }
            catch (error)
            {
                this.onValidationFailed();
                return false;
            }

            if (valid)
                this.onValidated();
            else
                this.onValidationFailed();

            return valid;
        }

        public submit(): Promise<boolean>
        {
            if (!this._submission)
            {
                this._submission = new Promise<boolean>(
                    async (resolve, reject) =>
                    {
                        this.error = null;

                        try
                        {
                            var valid = await this.validate();
                            if (!valid)
                                return resolve(false);

                            var submitted = await this.onSubmitting(this.getData());
                            if (submitted !== false)
                                this.onSubmitted();

                            return resolve(true);
                        }
                        catch (error)
                        {
                            this.onSubmissionFailed(error);
                            return reject(error);
                        }
                        finally
                        {
                            this._submission = null;
                        }
                    });
            }

            return this._submission;
        }

        public retry(): void
        {
            //if (this.state != FormState.Ready)
            //{
                this.state = FormState.Ready;
                this.updated.invoke(this);
            //}
        }

        public reset(): void
        {
            Map.forEach(this.fields, (name, field) => field.reset());
            this.retry();
        }

        public getData(): Map<any>
        {
            return Map.remap(this.fields, (key, field) => field.getValue());
        }

        public hasErrors(): boolean
        {
            return !!this.error || Sparks.Map.some(this.fields, (name, field) => field.hasErrors);
        }

        //#endregion


        //#region Public Events

        public validating = new Event();

        public validated = new Event();

        public validationFailed = new Event();

        public submitting = new Event<Map<any>>();

        public submitted = new Event();

        public submissionFailed = new Event<Error>();

        public updated = new Event();

        //#endregion


        //#region Public Properties

        public fields: Map<FieldController> = {};

        public state: FormState = FormState.NotReady;

        public error: Error = null;

        public isValidationDisabled: boolean = false;
                
        //#endregion


        //#region Protected Methods

        protected onValidating(): void
        {
            this.state = FormState.Validating;
            this.validating.invoke(this);
            this.updated.invoke(this);
        }

        protected onValidated(): void
        {
            this.state = FormState.Validated;
            this.validated.invoke(this);
            this.updated.invoke(this);
        }

        protected onValidationFailed(): void
        {
            this.state = FormState.ValidationError;
            this.validationFailed.invoke(this);
            this.updated.invoke(this);
        }

        protected onSubmitting(data: Map<any>): Promise<boolean>
        {
            this.state = FormState.Submitting;
            var submission = this.submitting.invoke(this, data);
            this.updated.invoke(this);
            return (submission instanceof Promise) ? submission : Promise.resolve(submission);
        }

        protected onSubmitted(): void
        {
            this.state = FormState.Submitted;
            this.submitted.invoke(this);
            this.updated.invoke(this);
        }

        protected onSubmissionFailed(): void;
        protected onSubmissionFailed(error: Error): void;
        protected onSubmissionFailed(error?: Error): void
        {
            this.state = FormState.SubmissionError;
            this.error = error;
            this.submissionFailed.invoke(this, error);
            this.updated.invoke(this);
        }

        //#endregion


        //#region Private Fields

        private _submission: Promise<boolean> = null;

        //#endregion
    }
}