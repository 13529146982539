
namespace Sparks.UI
{
    export class Processor
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        public process(processingContext: ProcessingContext): void
        {
            var changed = this.processIteration(processingContext);
            if (changed)
            {
                processingContext = new ProcessingContext(processingContext.scope);
                changed = this.processIteration(processingContext);
            }
        }

        //#endregion


        //#region Public Properties

        public static defaultProcessor = new Processor();

        //#endregion


        //#region Private Methods

        private processIteration(processingContext: ProcessingContext): boolean
        {
            try
            {
                var changed = false;

                // Listen events
                processingContext.scope.disposing.add(onScopeDisposing);
                processingContext.scope.changed.add(this, onScopeChanged);

                // Process
                while (processingContext.watches.length > 0 || processingContext.scopes.length > 0)
                {
                    // Watch
                    for (var watch = processingContext.watches.shift(); watch; watch = processingContext.watches.shift())
                    {
                        if (processingContext.scope.watches.has(watch))
                            changed = watch.update() || changed;
                    }

                    // Children
                    for (var childScope = processingContext.scopes.shift(); childScope; childScope = processingContext.scopes.shift())
                    {
                        if (processingContext.scope.children.has(childScope))
                            changed = this.processIteration(new ProcessingContext(childScope)) || changed;
                    }
                }

                // Return changed
                return changed;
            }
            finally
            {
                // Stop listening events
                processingContext.scope.disposing.remove(onScopeDisposing);
                processingContext.scope.changed.remove(onScopeChanged);
            }

            // Event handlers
            function onScopeDisposing(): void
            {
                processingContext.watches = [];
                processingContext.scopes = [];
            }

            function onScopeChanged(scope: Scope, changes: Scope.ChangedEventArgs): void
            {
                if (changes.watchAdded)
                    processingContext.watches.push(changes.watchAdded);
                if (changes.scopeAdded)
                    processingContext.scopes.push(changes.scopeAdded)
            }
        }

        //#endregion
    }
}