///<reference path="../Core/Interop.ts" />
///<reference path="../Native/DOM.ts" />
///<reference path="../Xml/Node.ts" />
///<reference path="../Xml/Element.ts" />
///<reference path="Node.ts" />

namespace Sparks.DOM
{
    export interface IHTMLElementExtension extends Sparks.DOM.INodeExtension, Sparks.Xml.IElementExtension
    {
        //#region Methods

        append(element: HTMLElement, node: Node): HTMLElement;
        append(element: HTMLElement, nodes: Node[]): HTMLElement;
        clone(element: HTMLElement, deep: boolean): HTMLElement;
        clone(element: HTMLElement, deep: boolean, $document: HTMLDocument): HTMLElement;
        getCommonAncestor(first: Node, second: Node): HTMLElement;
        getCommonAncestor(first: Node, second: Node, includeSelf: boolean): Node;
        getComputedStyle(element: HTMLElement, styleName: string): string;
        insert(container: Node, position: number, node: Node): void;
        insert(container: Node, position: number, nodes: Node[]): void;
        isElement(node: Node): node is HTMLElement;
        isElement(node: Node, tagName: string): node is HTMLElement;
        isElement(node: Node, tagName: "img"): node is HTMLImageElement;
        isElement(node: Node, tagName: "input"): node is HTMLInputElement;
        isElement(node: Node, tagName: "link"): node is HTMLLinkElement;
        isElement(node: Node, tagName: "script"): node is HTMLScriptElement;
        isElement(node: Node, tagName: "select"): node is HTMLSelectElement;
        isElement(node: Node, tagName: "textarea"): node is HTMLTextAreaElement;
        isInline(element: HTMLElement): boolean;
        isInline(tagName: string): boolean;
        isVoid(element: HTMLElement): boolean;
        isVoid(tagName: string): boolean;
        prepend(element: HTMLElement, node: Node): HTMLElement;
        prepend(element: HTMLElement, nodes: Node[]): HTMLElement;

        //#endregion 
    }
    
    export class HTMLElementExtension
    {
        //#region Public Methods
        
        public static append(element: HTMLElement, nodeOrNodes: any): HTMLElement
        {
            var nodes: Node[] = Array.isArray(nodeOrNodes) ? nodeOrNodes : [nodeOrNodes];

            if (element.ownerDocument.contains(element))
            {
                nodes.forEach(
                    node =>
                    {
                        if (Sparks.DOM.Node.isElement(node))
                        {
                            if (node.tagName == "SCRIPT")
                            {
                                //HTMLScriptElementExtension.setCurrent(<HTMLScriptElement>node);
                                element.appendChild(node);
                            }
                            else if (node.getElementsByTagName("SCRIPT").length > 0)
                            {
                                var children = HTMLElement.removeChildren(node);
                                element.appendChild(node);
                                children.forEach(child => HTMLElementExtension.append(node, child));
                            }
                            else
                            {
                                element.appendChild(node);
                            }
                        }
                        else
                        {
                            element.appendChild(node);
                        }
                    });
            }
            else
            {
                nodes.forEach(node => element.appendChild(node));
            }

            return element;
        }
        
        public static getComputedStyle(element: HTMLElement, styleName: string): string
        {
            var $document = element.ownerDocument;
            var $window = HTMLDocument.getOwnerWindow($document);
            return $window.getComputedStyle(element).getPropertyValue(styleName);
        }

        public static isInline(elementOrTagName: HTMLElement | string): boolean
        {
            var tagName = (String.isString(elementOrTagName) ? elementOrTagName : elementOrTagName.tagName).toLowerCase();
            return !!HTMLElementExtension.InlineTagNames[tagName];
        }

        public static isVoid(elementOrTagName: HTMLElement | string): boolean
        {
            var tagName = (String.isString(elementOrTagName) ? elementOrTagName : elementOrTagName.tagName).toLowerCase();
            return !!HTMLElementExtension.VoidTagNames[tagName];
        }

        public static prepend(element: HTMLElement, nodeOrNodes: any): HTMLElement
        {
            if (element.firstChild)
            {
                var nodes: Node[] = Array.isArray(nodeOrNodes) ? nodeOrNodes : [nodeOrNodes];
                var reference = element.firstChild;
                nodes.forEach(node => Node.insertBefore(reference, node));
            }
            else
            {
                HTMLElementExtension.append(element, nodeOrNodes);
            }

            return element;
        }

        //#endregion


        //#region Private Constants

        private static InlineTagNames: Map<boolean> =
        {
            "a": true,
            "abbr": true,
            "acronym": true,
            "b": true,
            "bdo": true,
            "br": true,
            "big": true,
            "cite": true,
            "code": true,
            "dfn": true,
            "em": true,
            "font": true,
            "i": true,
            "img": true,
            "kbd": true,
            "q": true,
            "s": true,
            "samp": true,
            "span": true,
            "strong": true,
            "sub": true,
            "sup": true,
            "tt": true,
            "u": true,
            "var": true
        };

        private static VoidTagNames: Map<boolean> =
        {
            "br": true,
            "hr": true,
            "img": true,
            "input": true,
            "link": true,
            "meta": true
        };        

        //#endregion
    }
    
    export interface HTMLElementConstructor extends IHTMLElementExtension
    {
        prototype: HTMLElement;
        new (): HTMLElement;
    }

    export type HTMLElement = Native.HTMLElement;

    export var HTMLElement: HTMLElementConstructor = 
        Interop.extend(
            Native.HTMLElement,
            [
                Sparks.Xml.NodeExtension,
                Sparks.Xml.ElementExtension,
                Sparks.DOM.NodeExtension,
                Sparks.DOM.HTMLElementExtension
            ]);
}
