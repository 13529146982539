
namespace Sparks.Code.SourceMaps
{
    export class VlqEncoder
    {
        //#region Public Methods

        public static encode(value: number): string
        {
            value = (value < 0) ? (((-value) << 1) | 1) : (value << 1);

            var vlq = 0;
            for (var i = 0; value > 0; i++)
            {
                var digit = value & VlqBitmask.Base;
                value = value >> 5;
                if (value > 0)
                    digit = digit | VlqBitmask.Continuation;
                vlq = vlq | (digit << (i * 6));
            }
            
            return Base64Encoder.encode(vlq);
        }

        public static decode(vlqData: string): number[]
        {
            var values: number[] = [];
            
            var value = 0;
            for (var i = 0, shift = 0; i < vlqData.length; i++)
            {
                var digit = Base64Encoder.decode(vlqData.charAt(i));
                var continuation = ((digit & VlqBitmask.Continuation) > 0);
                value = value | ((digit & VlqBitmask.Base) << shift);
                shift += 5;
                if (continuation)
                    continue;

                value = ((value & VlqBitmask.Sign) > 0) ? -(value >> 1) : (value >> 1);
                values.push(value);
                value = 0;
                shift = 0;
            }

            return values;
        }

        //#endregion


        //#region Private Constants

        private static VlqBase = (1 << 5) - 1;
        private static VlqContinuation

        //#endregion
    }
}
