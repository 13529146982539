
//namespace Sparks.Apps.Routing
//{
//    export interface IRoute
//    {
//        //#region PRoperties

//        predicate?: (context: IContext) => boolean;

//        handler?: (args: Sparks.Map<string>) => void;

//        routes?: Route[];

//        //#endregion
//    }
//}
