
namespace Sparks.Net
{
    export class WebService
    {
        //#region Constructor

        public constructor(webServiceUrl: string)
        {
            this._serviceUrl = webServiceUrl;
        }

        //#endregion


        //#region Public Methods

        public invoke(httpMethod: string, path: string, data?: any): Promise<any>;
        public invoke<TResult>(httpMethod: string, path: string, data?: any): Promise<TResult>;
        public invoke(httpMethod: string, path: string, data?: any): Promise<any>
        {
            path = String.trimStart(path, '/');
            var request: Sparks.Http.Request =
                {
                    method: httpMethod,
                    url: this._serviceUrl + (path ? ('/' + path) : ""),
                    headers: (arguments.length > 3) ? { "Content-Type": "application/json" } : null,
                    data: data || null
                };
            return Http.getResponse(request, null, null).then(
                response =>
                {
                    if (response.statusCode != 200)
                    {
                        var error = (response.data) ? response.data.error : null;
                        var errorMessage =
                            (error) ?
                                ((Object.isObject(error) && error.message) ?
                                    error.message :
                                    (String.isString(error) ?
                                        error :
                                        error.toString())) :
                                "Service request error (" + request.method + " " + request.url + "): " + response.statusCode + " - " + response.status;
                        throw new InvocationError(errorMessage, request, response);
                    }
                    
                    return response.data;
                });
        }

        //#endregion


        //#region Private Fields

        private _serviceUrl: string;

        //#endregion
    }
}
