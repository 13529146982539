///<reference path="../Ast/ArrayInstantiationNode.ts" />
///<reference path="../Ast/AssignmentExpressionNode.ts" />
///<reference path="../Ast/BinaryOperationNode.ts" />
///<reference path="../Ast/ConditionExpressionNode.ts" />
///<reference path="../Ast/ExpressionStatementNode.ts" />
///<reference path="../Ast/IndexerNode.ts" />
///<reference path="../Ast/LambdaExpressionNode.ts" />
///<reference path="../Ast/LiteralValueNode.ts" />
///<reference path="../Ast/MemberReferenceNode.ts" />
///<reference path="../Ast/MethodInvocationNode.ts" />
///<reference path="../Ast/MethodReferenceNode.ts" />
///<reference path="../Ast/ObjectInstantiationNode.ts" />
///<reference path="../Ast/ParameterReferenceNode.ts" />
///<reference path="../Ast/StatementBlockNode.ts" />
///<reference path="../Ast/ThisReferenceNode.ts" />
///<reference path="../Ast/TypeReferenceNode.ts" />
///<reference path="../Ast/UnaryOperationNode.ts" />
///<reference path="../Ast/VariableReferenceNode.ts" />
///<reference path="CodeNodeType.ts" />

namespace Sparks.Code
{
    export class Processor<TResult>
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        public process(codeNode: CodeNode): TResult
        {
            var nodeProcessor = Processor._processors[codeNode.codeNodeType];
            if (!nodeProcessor)
                throw new Error("Not implemented");
            return nodeProcessor(this, codeNode);
        }

        //#endregion


        //#region Protected Methods

        protected processArrayInstantiation(codeNode: ArrayInstantiationNode): TResult
        {
            throw new Error("Not implemented");
        }

        protected processAssignmentExpression(codeNode: AssignmentExpressionNode): TResult
        {
            throw new Error("Not implemented");
        }

        protected processBinaryOperation(codeNode: BinaryOperationNode): TResult
        {
            throw new Error("Not implemented");
        }

        protected processConditionExpression(codeNode: ConditionExpressionNode): TResult
        {
            throw new Error("Not implemented");
        }

        protected processExpressionSequence(codeNode: ExpressionSequenceNode): TResult
        {
            throw new Error("Not implemented");
        }

        protected processExpressionStatement(codeNode: ExpressionStatementNode): TResult
        {
            throw new Error("Not implemented");
        }

        protected processIndexer(codeNode: IndexerNode): TResult
        {
            throw new Error("Not implemented");
        }

        protected processLambdaExpression(codeNode: LambdaExpressionNode): TResult
        {
            throw new Error("Not implemented");
        }

        protected processLiteralValue(codeNode: LiteralValueNode): TResult
        {
            throw new Error("Not implemented");
        }

        protected processMemberReference(codeNode: MemberReferenceNode): TResult
        {
            throw new Error("Not implemented");
        }

        protected processMethodInvocation(codeNode: MethodInvocationNode): TResult
        {
            throw new Error("Not implemented");
        }
        
        protected processObjectInstantiation(codeNode: ObjectInstantiationNode): TResult
        {
            throw new Error("Not implemented");
        }

        protected processParameterReference(codeNode: ParameterReferenceNode): TResult
        {
            throw new Error("Not implemented");
        }
        
        protected processStatementBlock(codeNode: StatementBlockNode): TResult
        {
            throw new Error("Not implemented");
        }

        protected processThisReference(codeNode: ThisReferenceNode): TResult
        {
            throw new Error("Not implemented");
        }

        protected processTypeReference(codeNode: TypeReferenceNode): TResult
        {
            throw new Error("Not implemented");
        }

        protected processUnaryOperation(codeNode: UnaryOperationNode): TResult
        {
            throw new Error("Not implemented");
        }

        protected processVariableReference(codeNode: VariableReferenceNode): TResult
        {
            throw new Error("Not implemented");
        }

        //#endregion


        //#region Private Methods

        private static loadProcessors(): Map<(processor: Processor<any>, codeNode: CodeNode) => any>
        {
            var processors: Map<(processor: Processor<any>, codeNode: CodeNode) => any> = {};

            processors[CodeNodeType.ArrayInstantiation] = (processor, codeNode) => processor.processArrayInstantiation(<ArrayInstantiationNode>codeNode);
            processors[CodeNodeType.AssignmentExpression] = (processor, codeNode) => processor.processAssignmentExpression(<AssignmentExpressionNode>codeNode);
            processors[CodeNodeType.BinaryOperation] = (processor, codeNode) => processor.processBinaryOperation(<BinaryOperationNode>codeNode);
            processors[CodeNodeType.ConditionExpression] = (processor, codeNode) => processor.processConditionExpression(<ConditionExpressionNode>codeNode);
            processors[CodeNodeType.ExpressionSequence] = (processor, codeNode) => processor.processExpressionSequence(<ExpressionSequenceNode>codeNode);
            processors[CodeNodeType.ExpressionStatement] = (processor, codeNode) => processor.processExpressionStatement(<ExpressionStatementNode>codeNode);
            processors[CodeNodeType.Indexer] = (processor, codeNode) => processor.processIndexer(<IndexerNode>codeNode);
            processors[CodeNodeType.LambdaExpression] = (processor, codeNode) => processor.processLambdaExpression(<LambdaExpressionNode>codeNode);
            processors[CodeNodeType.LiteralValue] = (processor, codeNode) => processor.processLiteralValue(<LiteralValueNode>codeNode);
            processors[CodeNodeType.MemberReference] = (processor, codeNode) => processor.processMemberReference(<MemberReferenceNode>codeNode);
            processors[CodeNodeType.MethodInvocation] = (processor, codeNode) => processor.processMethodInvocation(<MethodInvocationNode>codeNode);
            processors[CodeNodeType.ObjectInstantiation] = (processor, codeNode) => processor.processObjectInstantiation(<ObjectInstantiationNode>codeNode);
            processors[CodeNodeType.ParameterReference] = (processor, codeNode) => processor.processParameterReference(<ParameterReferenceNode>codeNode);
            processors[CodeNodeType.StatementBlock] = (processor, codeNode) => processor.processStatementBlock(<StatementBlockNode>codeNode);
            processors[CodeNodeType.ThisReference] = (processor, codeNode) => processor.processThisReference(<ThisReferenceNode>codeNode);
            processors[CodeNodeType.TypeReference] = (processor, codeNode) => processor.processTypeReference(<TypeReferenceNode>codeNode);
            processors[CodeNodeType.UnaryOperation] = (processor, codeNode) => processor.processUnaryOperation(<UnaryOperationNode>codeNode);
            processors[CodeNodeType.VariableReference] = (processor, codeNode) => processor.processVariableReference(<VariableReferenceNode>codeNode);

            return processors;
        }

        //#endregion


        //#region Private Fields

        private static _processors: Map<(processor: Processor<any>, codeNode: CodeNode) => any> = Processor.loadProcessors();
        
        //#endregion
    }
}
