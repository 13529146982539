
namespace Sparks
{

    export interface IDisposable
    {
        //#region Methods

        dispose(): void;

        //#endregion
    }

}