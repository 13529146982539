
namespace Sparks.Apps.Routing
{
    export interface IContext
    {
        //#region Properties

        //args?: Sparks.Map<string;

        path?: string;

        //#endregion
    }
}
