
namespace Sparks.Code
{
    export interface IndexerNode extends ExpressionNode
    {
        //#region Properties

        index: ExpressionNode;
        target: ExpressionNode;
                
        //#endregion
    }
}
