
namespace Sparks.Code
{
    export interface TypeReferenceNode extends ExpressionNode
    {
        //#region Properties

        typeName: string;
        //genericArguments: TypeReferenceNode[];
        
        //#endregion
    }
}
