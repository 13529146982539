
namespace Sparks
{
    export class AppDomain
    {
        //#region Constructor

        public constructor()
        {
            window.onerror = AppDomain.window_error;
            //this.invoke = this.safeInvoke;
        }

        //#endregion


        //#region Public Methods

        public getType(typeName): TypeReference<any>
        {
            return this._types[typeName] || null;
        }

        public registerType(typeName: string, typeReference: TypeReference<any>): void
        {
            this._types[typeName] = typeReference;
        }

        public handleError(error: Native.Error): void
        {
            this.onError(error);
        }

        //#endregion


        //#region Public Events

        // TODO: solve TypeScript compiler issue (compile error TS4025) to restore Event<Native.Error> declaration
        public unhandledError = new Event<any>();

        //#endregion


        //#region Public Properties
        
        public static get currentDomain(): AppDomain
        {
            return this._currentDomain || (this._currentDomain = new AppDomain());
        }

        //#endregion


        //#region Protected Methods

        protected onError(error: Native.Error): void
        {
            try
            {
                console.log("Unhandled error:");
                this.logError(error);
                this.unhandledError.invoke(this, error);
            }
            catch (e)
            {
                setTimeout(() => this.onError(e), 0);
            }
        }

        //#endregion


        //#region Private Methods

        private logError(error: Native.Error): void
        {
            console.error(error);
            while (error = error["innerError"])
            {
                console.log("Caused by: ");
                console.error(error);
            }
        }
        
        private safeInvoke(target: Function, thisArg?: any, args?: any[]): any
        {
            try
            {
                return target.apply(thisArg, args);
            }
            catch (error)
            {
                this.onError(error);
            }
        }

        private unsafeInvoke(target: Function, thisArg?: any, args?: any[]): any
        {
            return target.apply(thisArg, args);
        }

        private static window_error(message: any, source?: string, fileno?: number, columnNumber?: number, error?: Native.Error): boolean
        {
            error = error || new Error(message);
            AppDomain.currentDomain.onError(error);
            return true;
        }

        //#endregion


        //#region Private Fields

        private _types: Map<TypeReference<any>> =
        {
            "Array": Native.Array,
            "Object": Native.Object
        };

        private static _currentDomain: AppDomain;

        //#endregion
    }
}
