
namespace Sparks.UI
{
    export class TemplateDirective extends Directive
    {
        //#region Constructor

        public constructor(assignable: Expression<Template>, template: Template)
        {
            super();
            this.assignable = assignable;
            this.template = template;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: ActivationContext): void
        {
            this.assignable.assign(this.template, activationContext.scope);
        }

        //#endregion


        //#region Public Properties

        public assignable: Expression<Template>;
        public template: Template;

        //#endregion
    }

    export namespace TemplateDirective
    {
        export class TemplateDirectiveCompiler extends BaseAttributeDirectiveCompiler
        {
            //#region Constructor

            public constructor()
            {
                super("sx-template");
            }

            //#endregion


            //#region Public Methods

            public compile(node: HTMLElement, compilationContext: CompilationContext): Directive
            {
                var assignable = this.compileArguments<Template>(node);
                if (!assignable.isAssignable)
                    throw new Error("Supplied expresison is not assignable");

                // Interrupt node compilation
                compilationContext.queue = [];

                // Build template with remaining node directives
                var template = compilationContext.compiler.compile([node]);
                return new TemplateDirective(assignable, template);
            }

            //#endregion
        }
    }
}