
namespace Sparks
{
    export interface Collection<TItem>
    {
        //#region Properties

        readonly length: number;
        [index: number]: TItem;

        //#endregion
    }

    export interface CollectionMethods
    {
        //#region Methods

        forEach<TItem>(collection: Collection<TItem>, action: (item: TItem) => void): void;
        join<TItem>(...collections: Collection<TItem>[]): TItem[];
        join<TItem>(collections: Collection<TItem>[]): TItem[];
        map<TItem, TValue>(collection: Collection<TItem>, selector: (item: TItem) => TValue): TValue[];
        toArray<TItem>(collection: Collection<TItem>): TItem[];
        toMap<TValue>(collection: Collection<MapEntry<TValue>>): Map<TValue>;
        toMap<TItem>(collection: Collection<TItem>, keySelector: (item: TItem) => string | number): Map<TItem>;
        toMap<TItem, TValue>(collection: Collection<TItem>, keySelector: (item: TItem) => string | number, valueSelector: (item: TItem) => TValue);

        //#endregion
    }

    export var Collection: CollectionMethods =
        {
            forEach:
                function <TItem>(collection: Collection<TItem>, action: (item: TItem) => void): void
                {
                    for (var i = 0; i < collection.length; i++)
                        action(collection[i]);
                },

            join:
                function <TItem>(): TItem[]
                {
                    var $arguments = Collection.map(arguments, item => !Array.isArray(item) ? Collection.toArray(item) : item);
                    return Array.prototype.concat.apply([], ($arguments.length > 1) ? $arguments : $arguments[0]);
                },

            map:
                function <TItem, TValue>(collection: Collection<TItem>, selector: (item: TItem) => TValue): TValue[]
                {
                    return Array.prototype.map.apply(collection, [selector]);
                },

            toArray:
                function <TItem>(collection: Collection<TItem>): TItem[]
                {
                    return Array.prototype.slice.apply(collection);
                },

            toMap:
                function <TItem, TValue>(collection: Collection<TItem | MapEntry<TValue> | TValue>, keySelector?: (item: TItem) => string | number, valueSelector?: (item: TItem) => TValue): Map<TValue>
                {
                    var map: Map<TValue> = {};

                    if (valueSelector)
                        Collection.forEach(collection, item => map[keySelector(<TItem>item)] = valueSelector(<TItem>item));
                    else if (keySelector)
                        Collection.forEach(collection, item => map[keySelector(<TItem>item)] = <TValue>item);
                    else
                        Collection.forEach(collection, item => map[(<MapEntry<TValue>>item).key] = (<MapEntry<TValue>>item).value);

                    return map;
                }
        };
}
