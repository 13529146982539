///<reference path="../Native/Object.ts" />
///<reference path="Interop.ts" />

namespace Sparks
{
    export interface IObjectExtension
    {
        //#region Methods

        as<TType>(type: TypeReference<TType>, value: any): TType;
        clone<TObject>(source: TObject, shallow?: boolean): TObject;
        copy<TObject>(target: TObject, source: any, properties?: string[]): TObject;
        instantiate<TType>(type: TypeReference<TType>, args: any[]): TType;
        isDefined(value: any): boolean;
        isObject(value: any): value is Native.Object;
        isObject<TObject>(value: any): value is TObject;
        isValue(value: any): boolean
        
        //#endregion


        //#region Properties

        ObjectIdField: string;

        //#endregion
    }
    
    export class ObjectExtension
    {
        //#region Public Methods

        public static as<TType>(type: TypeReference<TType>, value: any): TType
        {
            return (value instanceof type) ? value : null;
        }

        public static clone<TObject>(source: TObject, shallow?: boolean): TObject;
        public static clone(source: any, shallow?: boolean): any
        {
            if (source instanceof Native.Array)
            {
                var array = [];

                for (var i = 0; i < source.length; i++)
                    array[i] = shallow ? source[i] : ObjectExtension.clone(source[i]);

                return array;
            }
            else if (source instanceof Native.Date)
            {
                return new Native.Date(source.valueOf());
            }
            else if ((typeof source) == "object" && source != null)
            {
                var ctor = source.constructor;
                if (!ctor)
                {
                    ctor = function () { };
                    ctor.prototype = source.prototype;
                }

                var clone = new ctor();

                for (var p in source)
                {
                    if (p == Object.ObjectIdField)
                        continue;
                    if (source.hasOwnProperty(p))
                        clone[p] = shallow ? source[p] : ObjectExtension.clone(source[p]);
                }

                return clone;
            }
            else
            {
                return source;
            }
        }

        public static copy<TObject>(target: TObject, source: any, properties?: string[]): TObject
        {
            if (!properties)
                properties = Native.Object.getOwnPropertyNames(source);

            for (var i = 0; i < properties.length; i++)
            {
                var property = properties[i];
                if (source.hasOwnProperty(property))
                    target[property] = source[property];
            }

            return target;
        }

        public static instantiate<TType>(type: TypeReference<TType>, args: any[]): TType;
        public static instantiate<TType>(type: Function, args: any[]): TType
        {
            var ctor = type.apply(null, args);
            return new ctor;
        }
                
        public static isDefined(value: any): value is Native.Object
        {
            return "undefined" != typeof (value);
        }

        public static isObject(value: any): value is Native.Object
        {
            return "object" == typeof (value);
        }

        public static isValue(value: any): boolean
        {
            var type = typeof value;
            return type == "number" || type == "string" || type == "boolean";
        }

        //#endregion


        //#region Private Constants

        private static ExtensionTagName = "_spark_";
        
        //#endregion


        //#region Private Fields

        private static _nextId = 1;

        //#endregion
    }

    export interface ObjectConstructor extends Native.ObjectConstructor, IObjectExtension
    {
    }
    
    export type Object = Native.Object;

    export var Object: ObjectConstructor = Interop.extend(Native.Object, ObjectExtension);
}