///<reference path="Component.ts" />

namespace Sparks.Apps.Components
{
    export abstract class Selector<TObject> extends Browser<TObject>
    {
        //#region Constructor

        public constructor()
        {
            super();
        }

        //#endregion


        //#region Public Methods

        public start(...arg: any[]): Promise<TObject>
        {
            if (this._selection)
                throw new Error("Already started.");

            var selection = this._selection = new Promise<TObject>(
                (resolve, reject) =>
                {
                    this.selectedItem = null;
                    this._submit = resolve;

                    this.started.invoke(this);
                });
            selection.finally(
                () =>
                {
                    this._selection = null;
                    this._submit = null;

                    this.ended.invoke(this);
                    this.updated.invoke(this);
                });

           // if (this.items.length < 1)
                this.update();
            //else
            //    this.updated.invoke(this);

            return selection;
        }

        public select(item: TObject, silent?: boolean): void
        {
            this.selectedItem = item;

            if (!silent)
                this.updated.invoke(this);
        }

        public submit(): void
        {
            if (!this._selection)
                throw new Error("Not started.");

            this._submit(this.selectedItem);
        }

        public cancel(): void
        {
            if (!this._selection)
                throw new Error("Not started.");

            this._submit(null);
        }

        public isStarted(): boolean
        {
            return !!this._selection;
        }

        //#endregion


        //#region Public Events

        public started = new Sparks.Event();
        public ended = new Sparks.Event();

        //#endregion


        //#region Public Properties

        private selectedItem: TObject = null;

        //#endregion


        //#region Private Fields

        private _selection: Promise<TObject> = null;
        private _submit: (result: TObject) => void = null;

        //#endregion
    }
}
