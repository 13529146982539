
namespace Sparks.Code
{
    export interface ParseResult<TCodeNode extends CodeNode>
    {
        //#region Properties

        parseInfo: Native.Map<CodeNode, ParseInfo>;

        result: TCodeNode;

        //#endregion
    }
}
