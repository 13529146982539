
namespace Sparks.Apps.Diagnostics
{
    export interface IErrorHandler extends IService
    {
        //#region Methods

        handle(error: Error): void;

        //#endregion
    }
}
