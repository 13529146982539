
namespace Simpleo.CustomerPortal
{
    export class AccountManagementService extends Sparks.Net.WebService
    {
        //#region Constructor

        public constructor(serviceUrl: string)
        {
            super(serviceUrl);
        }

        //#endregion


        //#region Public Methods


        //#endregion
    }
}
