
namespace Sparks
{
    export class Http
    {
        //#region Public Methods

        public static get(url: string, headers?: Map<string>): Promise<string>
        {
            return Http.request("GET", url, null, headers, null, null);
        }

        public static post(url: string, content: string, headers?: Map<string>, progressCallback?: ProgressCallback, state?: any): Promise<any>;
        public static post(url: string, content: Object, headers?: Map<string>, progressCallback?: ProgressCallback, state?: any): Promise<any>;
        public static post(url: string, formData: FormData, headers?: Map<string>, progressCallback?: ProgressCallback, state?: any): Promise<any>;
        public static post(url: string, data: string | Object | FormData, headers?: Map<string>, progressCallback?: ProgressCallback, state?: any): Promise<any>
        {
            return Http.request("POST", url, data, headers, progressCallback, state);
        }

        public static async request(method: string, url: string, content: string, headers?: Map<string>, progressCallback?: ProgressCallback, state?: any): Promise<any>;
        public static async request(method: string, url: string, content: Object, headers?: Map<string>, progressCallback?: ProgressCallback, state?: any): Promise<any>;
        public static async request(method: string, url: string, formData: FormData, headers?: Map<string>, progressCallback?: ProgressCallback, state?: any): Promise<any>;
        public static async request(method: string, url: string, data: string | Object | FormData, headers?: Map<string>, progressCallback?: ProgressCallback, state?: any): Promise<any>
        {
            var httpRequest: Http.Request =
            {
                method: method,
                url: url,
                headers: headers,
                data: data
            };

            var httpResponse = await Http.getResponse(httpRequest, progressCallback, state);

            if (httpResponse.statusCode != 200)
            {
                var errorMessage = (httpResponse.statusCode > 0) ?
                    "Http request failed [" + httpResponse.statusCode + " - " + httpResponse.status + "] for " + method + " request on '" + url + "'" :
                    "Http request aborted by client for " + method + " request on '" + url + "'";

                throw new HttpError(httpResponse.statusCode, errorMessage);
            }

            return httpResponse.data;
        }

        public static getResponse(httpRequest: Http.Request): Promise<Http.Response>;
        public static getResponse(httpRequest: Http.Request, progressCallback: ProgressCallback): Promise<Http.Response>;
        public static getResponse(httpRequest: Http.Request, progressCallback: ProgressCallback, state: any): Promise<Http.Response>;
        public static getResponse(httpRequest: Http.Request, progressCallback?: ProgressCallback, state?: any): Promise<Http.Response>
        {
            return new Promise<Http.Response>(
                (resolve, reject) =>
                {
                    var request = new XMLHttpRequest();
                    var response: Http.Response = {};

                    request.withCredentials = true;

                    request.open(httpRequest.method, httpRequest.url, true);

                    if (httpRequest.headers)
                        Map.forEach(httpRequest.headers, (name, value) => request.setRequestHeader(name, value));

                    request.onreadystatechange = function ()
                    {
                        if (request.readyState == XMLHttpRequest.HEADERS_RECEIVED)
                        {
                            var responseHeaders = request.getAllResponseHeaders();
                            response.statusCode = request.status;
                            response.status = request.statusText;
                            response.headers = Http.parseHeaders(responseHeaders);
                        }
                        else if (request.readyState == XMLHttpRequest.LOADING)
                        {
                            response.data = request.responseText || response.data;
                        }
                        else if (request.readyState == XMLHttpRequest.DONE)
                        {
                            response.data = request.responseText || response.data;

                            if (response.headers[Http.ContentTypeHeader.toLowerCase()] == Http.JsonContentType)
                                response.data = JSON.parse(response.data);

                            resolve(response);
                        }
                    }

                    if (progressCallback)
                    {
                        request.upload.addEventListener(
                            "progress", progressEvent => progressCallback(state, progressEvent.lengthComputable, progressEvent.loaded, progressEvent.total));
                    }

                    var data = httpRequest.data;
                    if (Object.isObject(data) && data != null && !(data instanceof FormData))
                    {
                        if (httpRequest.headers && httpRequest.headers[Http.ContentTypeHeader])
                        {
                            var contentType = httpRequest.headers[Http.ContentTypeHeader];
                            if (contentType != Http.JsonContentType)
                                throw new Error("Not implemented");
                        }
                        else
                        {
                            request.setRequestHeader(Http.ContentTypeHeader, Http.JsonContentType);
                        }

                        data = JSON.stringify(data);
                    }

                    request.send(data);
                });
        }
        
        //#endregion


        //#region Private Methods
        
        private static parseHeaders(headersText: string): Map<string>
        {
            var headers: Map<string> = {};

            var lines = headersText.split("\r\n").filter(line => line);
            lines.forEach(
                line =>
                {
                    var entry = line.split(": ");
                    headers[entry[0].toLowerCase()] = entry[1];
                });

            return headers;
        }

        //#endregion


        //#region Private Constants

        private static ContentTypeHeader = "Content-Type";
        private static JsonContentType = "application/json";
        
        //#endregion
    }

    export namespace Http
    {
        export interface Request
        {
            //#region Public Properties
            
            method: string
            url: string;
            headers: Map<string>;
            data: any;

            //#endregion
        }

        export interface Response
        {
            //#region Public Properties
            
            statusCode?: number;
            status?: string;
            headers?: Map<string>;
            data?: any;

            //#endregion
        }
    }
}
