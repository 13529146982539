///<reference path="../Native/DOM.ts" />

namespace Sparks.DOM
{
    export interface IWindowExtension
    {
        //#region Methods

        executeWhenLoaded($window: Window, eventHandler: Function): void;
        executeWhenLoaded($window: Window, eventHandler: Function, delay: number): void;
        getScroll($window?: Window): Point;
        getScrollLeft($window: Window): number;
        getScrollTop($window?: Window): number;
        getSize($window?: Window): Size;
        onError($window: Window, error: Error): void;
        removeEventHandler(window: Window, eventType: EventType, target: Object, method: EventHandler<EventArgs>): void;
        waitFrame($window: Window): Promise<void>;

        //#endregion
    }
    
    export class WindowExtension extends Sparks.DOM.NodeExtension
    {
        //#region Public Methods

        public static executeWhenLoaded($window: Window, handler: Function, delay?: number): void
        {
            var deferredHandler =
                (arguments.length > 2) ?
                    () => setTimeout(handler, delay || 0) :
                    handler;

            if ($window.document.readyState === "complete")
                deferredHandler();
            else
                $window.addEventListener("load", () => deferredHandler(), { once: true });
        }

        public static getScroll($window?: Window): Point
        {
            $window = $window || window;
            var $document = $window.document;
            var documentElement = $document.documentElement;
 
            return {
                left: (window.pageXOffset || documentElement.scrollLeft) - (documentElement.clientLeft || 0),
                top: (window.pageYOffset || documentElement.scrollTop) - (documentElement.clientTop || 0)
            };
        }

        public static getScrollLeft($window: Window): number
        {
            $window = $window || window;
            var $document = $window.document;
            var documentElement = $document.documentElement;
            return (window.pageXOffset || documentElement.scrollLeft) - (documentElement.clientLeft || 0);
        }

        public static getScrollTop($window?: Window): number
        {
            $window = $window || window;
            var $document = $window.document;
            var documentElement = $document.documentElement;
            return(window.pageYOffset || documentElement.scrollTop) - (documentElement.clientTop || 0);
        }

        public static getSize($window?: Window): Size
        {
            $window = $window || window;

            if ($window.innerWidth)
                return { width: $window.innerWidth, height: $window.innerHeight };

            var documentElement = $window.document.documentElement;
            if (documentElement.clientWidth)
                return { width: documentElement.clientWidth, height: documentElement.clientHeight };

            var body = $window.document.getElementsByTagName('body')[0];
            return { width: body.clientWidth, height: body.clientHeight };
        }
        
        public static waitFrame($window?: Window): Promise<void>
        {
            $window = $window || window;
            return new Promise<void>(resolve => $window.requestAnimationFrame(() => resolve()));
        }

        //#endregion
    }
    
    export interface WindowConstructor extends IWindowExtension
    {
        prototype: Window;
        new (): Window;
    }
    
    export type Window = Native.Window;

    export var Window: WindowConstructor = <any>WindowExtension;
}
