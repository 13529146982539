
namespace Sparks.UI
{
    export abstract class Watch<TValue>
    {
        //#region Constructor

        public constructor(scope: Scope, expression: Expression<TValue>, handler: Handler<TValue>)
        {
            this.scope = scope;
            this.expression = expression;
            this.handler = handler;
        }

        //#endregion


        //#region Public Methods
        
        public abstract update(): boolean;

        //#endregion


        //#region Public Properties

        public scope: Scope;
        
        public expression: Expression<TValue>;

        public handler: Handler<TValue>;

        //#endregion
    }

    export interface Handler<TValue>
    {
        (newValue: TValue, oldValue: TValue): Promise<any> | any;
    }
}