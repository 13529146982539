///<reference path="../Native/Boolean.ts" />
///<reference path="Interop.ts" />

namespace Sparks
{
    export interface IBooleanExtension
    {
        //#region Methods

        isBoolean(value: any): value is boolean;
        parse(value: any): boolean;

        //#endregion
    }

    export class BooleanExtension
    {
        //#region Public Methods

        public static isBoolean(value: any): value is boolean
        {
            return "boolean" == typeof (value);
        }

        public static parse(value: any): boolean
        {
            if ((typeof (value)) == "string")
            {
                var lowerCaseValue = (<string>value).toLowerCase();
                switch (lowerCaseValue)
                {
                    case "true": return true;
                    case "false": return false;
                    default: return undefined;
                }
            }

            return value;
        }

        //#endregion
    }

    export interface BooleanConstructor extends Native.BooleanConstructor, IBooleanExtension
    {
    }

    export type Boolean = Native.Boolean;

    export var Boolean: BooleanConstructor = Interop.extend(Native.Boolean, Sparks.BooleanExtension);
}
