
namespace Sparks
{
    export interface Size
    {
        //#region Prope

        width: number;
        height: number;

        //#endregion
    }
}
