
namespace Sparks.Code
{
    export interface StatementNode extends CodeNode
    {
        //#region Properties


        //#endregion
    }
}
