
namespace Simpleo.CustomerPortal
{
    export class AccountManager
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        
        //#endregion


        //#region Public Events

        public updated = new Sparks.Event();

        //#endregion


        //#region Public Properties
    
        

        //#endregion


        //#region Protected Methods

        //#endregion


        //#region Protected Properties


        //#endregion


        //#region Private Fields


        //#endregion
    }
}
