
namespace Sparks.UI
{
    export class InstanceDirective extends Directive
    {
        //#region Constructor

        public constructor(template: Expression<Template>, $this?: Expression<any>, locals?: Map<Expression<any>>, directives?: Directive[])
        {
            super();
            this.template = template;
            this.$this = $this || null;
            this.locals = locals || null;
            //this.directives = directives || null;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: ActivationContext): void
        {
            if (activationContext.instantiates)
            {
                // Create range
                var instanceRange = activationContext.range.addRange("sx-instance=\"" + this.template.source + "\"");

                // Initialize
                this.initialize(activationContext.activator, activationContext.scope, instanceRange);
            }
            else
            {
                // Create range
                var reference = activationContext.target.ownerDocument.createComment("sx-instance=\"" + this.template.source + "\"");
                activationContext.target.parentElement.insertBefore(reference, activationContext.target);
                var instanceRange = new Range(activationContext.target.parentElement, reference);

                // Remove node
                activationContext.target.parentElement.removeChild(activationContext.target);

                // Initialize
                this.initialize(activationContext.activator, activationContext.scope, instanceRange);
            }
        }

        //#endregion


        //#region Public Properties

        public template: Expression<Template>;
        public $this: Expression<any>;
        public locals: Map<Expression<any>>;
        //public directives: Directive[];

        //#endregion


        //#region Private Methods

        private initialize(activator: Activator, scope: Scope, range: Range): void
        {
            var instanceScope: Scope = null;

            // Watch template changes
            scope.watch(
                this.template,
                template =>
                {
                    // Dispose scope and clear range
                    if (instanceScope)
                        instanceScope.dispose();
                    range.clear();

                    // Create new scope, asssign this and locals
                    instanceScope = new Scope(scope);
                    instanceScope.isIsolated = false;

                    if (this.$this)
                    {
                        var $this = this.$this.evaluate(scope.locals);
                        instanceScope.locals.set("this", $this);
                    }
                    if (this.locals)
                    {
                        Map.forEach(
                            this.locals,
                            (name, expression) =>
                            {
                                var value = expression.evaluate(scope.locals);
                                instanceScope.locals.set(name, value);
                            });
                    }

                    // Instantiate
                    activator.instantiate(range, template.directives, instanceScope);
                });

            // Watch changes on this
            if (this.$this)
            {
                scope.watch(
                    this.$this,
                    $this => 
                    {
                        if (instanceScope)
                            instanceScope.locals.set("this", $this);
                    });
            }

            // Watch changes on locals
            if (this.locals)
            {
                Map.forEach(
                    this.locals,
                    (name, expression) =>
                    {
                        scope.watch(
                            expression,
                            value =>
                            {
                                if (instanceScope)
                                    instanceScope.locals.set(name, value);
                            });
                    });
            }
        }

        //#endregion
    }

    export namespace InstanceDirective
    {
        export class InstanceDirectiveCompiler extends BaseAttributeDirectiveCompiler
        {
            //#region Constructor

            public constructor()
            {
                super("sx-instance");
            }

            //#endregion


            //#region Public Methods

            public compile(node: HTMLElement, compilationContext: CompilationContext): Directive
            {
                // Retrieve arguments
                var $arguments = this.parseArguments(this.getArguments(node));
                var template = ExpressionCompiler.compileExpression<Template>($arguments.template);
                var $this = ($arguments.this) ? ExpressionCompiler.compileExpression<any>($arguments.this) : null;
                var locals = ($arguments.locals) ? this.compileLocals($arguments.locals) : null;

                // Interrupt compilation
                var remainingDirectiveCompilers = compilationContext.queue;
                compilationContext.queue = [];

                // TODO: transfer remainingDirectiveCompilers to template

                return new InstanceDirective(template, $this, locals);
            }

            //#endregion


            //#region Protected Methods

            protected parseArguments($arguments: string): InstanceDirective.Arguments
            {
                var parser = /^(.+?)(?:\s+using\s+(.+?))?(?:\s+with\s+(\{(?:.+)\}))?$/;
                var parseResult = parser.exec($arguments);
                
                if (!parseResult)
                    throw new Error("Invalid statement for " + InstanceDirective.name + ": " + $arguments);

                return {
                    template: parseResult[1],
                    this: parseResult[2] || null,
                    locals: parseResult[3] || null
                };
            }

            protected compileLocals(locals: string): Map<Expression<any>>
            {
                var parsedLocals = ExpressionCompiler.parseExpression(locals);
                if (!parsedLocals || !Sparks.Code.CodeNode.isObjectInstantiation(parsedLocals) || !parsedLocals.initializedProperties)
                    return null;
                var compiledLocals = Map.remap(parsedLocals.initializedProperties, (name, expressionNode) => ExpressionCompiler.compileExpression<any>(expressionNode));
                return compiledLocals;
            }

            //#endregion
        }

        export interface Arguments
        {
            //#region Properties

            template?: string;
            this?: string;
            locals?: string;

            //#endregion
        }
    }
}