
namespace Sparks.UI
{
    export class StyleDirective extends Directive
    {
        //#region Constructor

        public constructor(styles: Sparks.Map<Expression<any>>)
        {
            super();
            this.styles = styles;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: ActivationContext): void
        {
            // Bind expressions
            var target = <HTMLElement>activationContext.target;
            var locals = { "$node": target, "$scope": activationContext.scope };
            var expressions = Map.remap(this.styles, (styleName, expression) => ExpressionCompiler.bindLocals(expression, locals));

            // Create watches
            var watches = Map.remap(
                expressions,
                (styleName, expression) =>
                    activationContext.scope.watch(
                        expression,
                        value =>
                        {
                            if (value != null)
                                target.style[styleName] = value;
                            else
                                target.style.removeProperty(styleName);
                        }));

            // Initialize on render frame
            window.requestAnimationFrame(() => Map.forEach(watches, (styleName, watch) => watch.update()));
        }

        //#endregion


        //#region Public Properties

        public styles: Sparks.Map<Sparks.Expression<any>>;

        //#endregion
    }

    export namespace StyleDirective
    {
        export class StyleDirectiveCompiler extends BaseAttributeDirectiveCompiler
        {
            public constructor()
            {
                super("sx-style");
            }
            
            public compile(node: HTMLElement, compilationContext: CompilationContext): Directive
            {
                var styles = this.compileNamedArguments(node);
                return new StyleDirective(styles);
            }
        }
    }
}