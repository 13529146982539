
namespace Sparks.Code
{
    export interface ConditionExpressionNode extends ExpressionNode
    {
        //#region Properties

        condition: ExpressionNode;
        trueExpression: ExpressionNode;
        falseExpression: ExpressionNode;

        //#endregion
    }
}
