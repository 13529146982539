
namespace Sparks.UI
{
    export class ElementDirective extends Directive
    {
        //#region Constructor

        public constructor(tagName?: string, attributes?: Sparks.Map<string>)
        {
            super();

            this.tagName = tagName || null;
            this.attributes = attributes || null;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: ActivationContext): void
        {
            if (activationContext.instantiates)
            {
                // Create element and set as target
                var element = activationContext.target = activationContext.range.addElement(this.tagName, this.attributes);
                var targetRange = new Range(element);

                // Complete current activation
                activationContext.activator.activate(activationContext);

                // Process children
                var childrenDirectives = this.children.slice();
                for (var childDirectives = childrenDirectives.shift(); childDirectives; childDirectives = childrenDirectives.shift())
                {
                    activationContext.activator.instantiate(targetRange, childDirectives, activationContext.scope);
                }
            }
            else
            {
                // Use existing element
                var element = <HTMLElement>activationContext.target;

                // Complete current node activation
                activationContext.activator.activate(activationContext);

                // Compile and activate children
                var children = Array.from(element.children);
                children.forEach(
                    child =>
                    {
                        // TODO: specify shallow compilation (skip children)
                        var directives = activationContext.compiler.compileNode(child);
                        activationContext.activator.activateNode(child, directives, activationContext.scope);
                    });
            }
        }

        //#endregion


        //#region Public Properties

        public tagName: string;

        public attributes: Sparks.Map<string>;

        public children: Directive[][] = [];

        //#endregion
    }

    export namespace ElementDirective
    {
        export class ElementDirectiveCompiler extends DirectiveCompiler
        {
            //#region Public Methods

            public canCompile(node: Node): boolean
            {
                return Sparks.DOM.Node.isElement(node);
            }

            public compile(node: HTMLElement, compilationContext: CompilationContext): Directive
            {
                // Immediately add directive to context
                var elementDirective = new ElementDirective();
                compilationContext.directives.push(elementDirective);

                // Complete node compilation
                compilationContext.compiler.compileNode(node, compilationContext);

                // Compile for instantiation
                if (compilationContext.instantiates)
                {
                    // Retrieve name and remaining attributes
                    elementDirective.tagName = node.nodeName;
                    elementDirective.attributes = Sparks.DOM.HTMLElement.getAttributes(node);

                    // Compile children
                    var children = Array.from(node.childNodes);
                    for (var child = children.shift(); child; child = children.shift())
                    {
                        var childDirectives = compilationContext.compiler.compileNode(child, true);
                        elementDirective.children.push(childDirectives);
                    }
                }

                // Return populated element directive
                return elementDirective;
            }

            //#endregion
        }
    }
}