///<reference path="../Core/Interop.ts" />

declare namespace Sparks.DOM
{
    interface EventArgs extends Native.Event
    {
    }

    var EventArgs: EventArgs;
}

Sparks.Interop.define("Sparks.DOM.EventArgs", Event);
