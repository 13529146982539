
namespace Simpleo.CustomerPortal
{
    export class UIManager extends Sparks.Apps.UI.UIManager
    {
        //#region Constructor

        public constructor()
        {
            super();
        }

        //#endregion


        ////#region Public Methods

        //public initialize(services: Application.ServiceProvider): void
        //{
        //    // Services
        //    this.confirmationBox = services.confirmationBox;
        //    this.messageBox = services.messageBox;

        //    // Initialize Navigation
        //    Sparks.UI.NavigationManager.initialize();

        //    // Initialize UI
        //    this._processor = new Sparks.UI.Processor();
        //    this.viewContext = new Sparks.UI.ViewContext(this._processor, this.node);
        //    this.viewContext.set("navigation", Sparks.UI.NavigationManager);
        //    Sparks.Map.forEach(services, (name, service) => this.viewContext.set(name, service));

        //    // Process & update
        //    var initialization = 
        //        this._processor.process(this.node, this.viewContext)
        //            .then(() => this.update());
        //}

        //public confirm(title: string, message: string, confirm?: string, cancel?: string): Promise<boolean>
        //{
        //    return this.confirmationBox.confirm(title, message, confirm, cancel);
        //}

        //public showMessage(title: string, message: string): Promise<void>
        //{
        //    return this.messageBox.show(title, message);
        //}

        //public update(): void
        //{
        //    this.viewContext.update();
        //}

        ////#endregion


        ////#region Public Properties

        //public node: Node = null;
        //public viewContext: Sparks.UI.ViewContext = null;

        ////#endregion


        ////#region Protected Properties

        //protected confirmationBox: ConfirmationBox;
        //protected messageBox: MessageBox;

        ////#endregion


        ////#region Private Fields

        //private _processor: Sparks.UI.Processor = null;

        ////#endregion
    }
}
