
namespace Sparks.Code
{
    export interface CompileResult<TResult>
    {
        //#region Properties

        result: TResult;

        sourceMap: Sparks.Code.SourceMaps.SourceMap;

        //#endregion
    }
}
