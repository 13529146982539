
namespace Sparks.Apps.UI
{
    export class PromptBox
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        public async prompt(title: string, statement: string): Promise<string>;
        public async prompt(title: string, statement: string, defaultValue?: string): Promise<string>;
        public async prompt(title: string, statement: string, defaultValue?: string): Promise<string>
        {
            if (this._prompt)
                throw new Error("Already started");

            try
            {
                var prompt = this._prompt = new Promise<string>(resolve => this._submit = resolve);

                this.content =
                {
                    title: title,
                    statement: statement,
                    value: defaultValue
                };

                this.updated.invoke(this);

                return await prompt;
            }
            finally
            {
                this._prompt = null;
                this._submit = null;
                this.updated.invoke(this);
            }
        }

        public isActive(): boolean
        {
            return !!this._prompt;
        }

        //#endregion


        //#region Public Events

        public updated = new Sparks.Event();

        //#endregion


        //#region Public Methods

        public submit(value?: string): void
        {
            if (!this._prompt)
                throw new Error("Not started");

            this._submit((value != null) ? value : this.content.value);
        }

        public cancel(): void
        {
            if (!this._prompt)
                throw new Error("Not started");

            this._submit(null);
        }

        //#endregion


        //#region Protected Properties

        protected content: PromptBox.Content = {};

        //#endregion


        //#region Private Fields

        private _prompt: Promise<string> = null;
        private _submit: (value: string) => void;

        //#endregion
    }

    export namespace PromptBox
    {
        export interface Content
        {
            title?: string;
            statement?: string;
            value?: string;
        }
    }
}
