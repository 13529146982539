
namespace Sparks.UI
{
    export class TextNodeDirective extends Directive
    {
        //#region Constructor

        public constructor(content: string)
        {
            super();

            this.content = content || null;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: ActivationContext): void
        {
            if (activationContext.instantiates)
            {
                activationContext.target = activationContext.range.addText(this.content);
            }
        }

        //#endregion


        //#region Public Properties

        public content: string;

        //#endregion
    }

    export namespace TextNodeDirective
    {
        export class TextNodeDirectiveCompiler extends DirectiveCompiler
        {
            //#region Public Methods

            public canCompile(node: Node): boolean
            {
                // TODO: don't compile if not compiling for instantiation (template)
                return Sparks.DOM.Node.isTextNode(node);
            }

            public compile(node: Text, compilationContext: CompilationContext): Directive
            {
                return new TextNodeDirective(node.textContent);
            }

            //#endregion
        }
    }
}