
namespace Sparks.UI
{
    export class Range
    {
        //#region Constructor

        public constructor(container: HTMLElement, reference?: Node)
        {
            if (!container)
                throw new Error("No container supplied");

            this._container = container;
            this._reference = reference || null;
        }

        //#endregion


        //#region Public Methods

        public dispose(): void
        {
            this.clear();

            if (this._reference)
            {
                this._reference.parentElement.removeChild(this._reference);
                this._reference = null;
            }
        }

        public clear(): void
        {
            this._children.forEach(child => child.dispose());
            this._children.length = 0;

            this.nodes.forEach(node => node.parentElement.removeChild(node));
            this.nodes.length = 0;
        }

        public addElement(tagName: string, attributes?: Sparks.Map<string>): HTMLElement
        {
            var element = this._container.ownerDocument.createElement(tagName);
            if (attributes)
                Sparks.Map.forEach(attributes, (name, value) => element.setAttribute(name, value));
            this.addNode(element);
            return element;
        }

        public addText(content: string): Text
        {
            var text = this._container.ownerDocument.createTextNode(content);
            this.addNode(text);
            return text;
        }

        public addRange(id?: string): Range
        {
            var comment = this._container.ownerDocument.createComment(id || "");
            if (this._reference)
                this._container.insertBefore(comment, this._reference);
            else
                this._container.appendChild(comment);

            var range = new Range(this._container, comment);
            this._children.push(range);

            return range;
        }

        //#endregion


        //#region Public Properties

        public nodes: Node[] = [];

        //#endregion


        //#region Private Methods

        private addNode(node: Node): void
        {
            this.nodes.push(node);
            if (this._reference)
                this._container.insertBefore(node, this._reference);
            else
                this._container.appendChild(node);
        }

        //#endregion


        //#region Private Fields

        private _container: HTMLElement;
        private _reference: Node;
        private _children: Range[] = [];

        //#endregion
    }
}