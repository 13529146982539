
namespace Sparks.Code
{
    export interface ParameterReferenceNode extends ExpressionNode
    {
        //#region Properties

        parameterName: string;

        //#endregion
    }
}
