
//namespace Simpleo.CustomerPortal
//{
//    export interface PaymentInfo
//    {
//        //#region Properties
        
//        cardHolder: string;

//        cardNumber: string;

//        expiryMonth: number;

//        expiryYear: number;

//        chargedAmount: number;

//        //#endregion
//    }
//}
