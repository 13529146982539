
namespace Sparks.UI
{
    export class TextDirective extends Directive
    {
        //#region Constructor

        public constructor(text: Expression<string>)
        {
            super();
            this.text = text;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: ActivationContext): void
        {
            var target = <HTMLElement>activationContext.target;
            activationContext.scope.watch(this.text, text => target.innerHTML = text);
        }

        //#endregion


        //#region Public Properties

        public text: Expression<string>;

        //#endregion
    }

    export namespace TextDirective
    {
        export class TextDirectiveCompiler extends BaseAttributeDirectiveCompiler
        {
            public constructor()
            {
                super("sx-text");
            }
            
            public compile(node: HTMLElement, compilationContext: CompilationContext): Directive
            {
                var text = this.compileArguments<string>(node);
                return new TextDirective(text);
            }
        }
    }
}