/// <reference path="../Core/CodeNodeProcessor.ts" />

namespace Sparks.Code.Compilation
{
    export class ExpressionGenerator extends CodeNodeProcessor
    {
        //#region Constructor

        public constructor()
        {
            super();            
        }

        //#endregion


        //#region Protected Methods
        
        protected processAssignmentExpression(assignmentExpressionNode: AssignmentExpressionNode): CodeNode
        {
            if (CodeNode.isVariableReference(assignmentExpressionNode.target))
            {
                var contextVariableAssignment =
                    CodeNode.createMethodInvocation(
                        CodeNode.createMemberReference(CodeNode.createParameterReference("$context"), "set"),
                        [CodeNode.createLiteral(assignmentExpressionNode.target.variableName), this.process(assignmentExpressionNode.expression)]);
                return contextVariableAssignment;
            }
            else
            {
                return super.processAssignmentExpression(assignmentExpressionNode);
            }
        }

        protected processThisReference(thisReferenceNode: ThisReferenceNode): CodeNode
        {
            var contextThisReference =
                CodeNode.createMethodInvocation(
                    CodeNode.createMemberReference(CodeNode.createParameterReference("$context"), "get"),
                    [CodeNode.createLiteral("this")]);
            return contextThisReference;
        }

        protected processVariableReference(variableReferenceNode: VariableReferenceNode): CodeNode
        {
            var contextVariableReference =
                CodeNode.createMethodInvocation(
                    CodeNode.createMemberReference(CodeNode.createParameterReference("$context"), "get"),
                    [CodeNode.createLiteral(variableReferenceNode.variableName)]);
            return contextVariableReference;
        }
        
        //#endregion
    }
}
