
namespace Sparks.Apps.UI
{
    export class MessageBox
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        public async show(title: string, message: string): Promise<void>
        {
            if (this._notification)
                throw new Error("Already started");

            try
            {
                var notification = this._notification = new Promise<void>(resolve => this._close = resolve);

                this.content =
                {
                    title: title,
                    message: message
                };

                this.updated.invoke(this);

                return await notification;
            }
            finally
            {
                this._notification = null;
                this._close = null;
                this.updated.invoke(this);
            }
        }

        public close(): void
        {
            if (!this._notification)
                throw new Error("Not started");

            this._close();
        }

        public isActive(): boolean
        {
            return !!this._notification;
        }

        //#endregion


        //#region Public Events

        public updated = new Sparks.Event();

        //#endregion


        //#region Protected Properties

        protected content: MessageBox.Content = {};

        //#endregion


        //#region Private Fields

        private _notification: Promise<void> = null;
        private _close: () => void;

        //#endregion
    }

    export namespace MessageBox
    {
        export interface Content
        {
            title?: string;
            message?: string;
        }
    }
}
