
namespace Sparks
{
    export class Delegate
    {
        //#region Constructor

        constructor(method: Function);
        constructor(target: Object, method: Function);
        constructor()
        {
            if (Function.isFunction(arguments[1]))
            {
                this.target = arguments[0];
                this.method = arguments[1];
            }
            else
            {
                this.target = null;
                this.method = arguments[0];
            }
        }

        //#endregion


        //#region Public Methods
        
        public invoke(...args: any[]): any;
        public invoke(): any
        {
            return this.method.apply(this.target, arguments);
        }

        public apply(args: any[]): any
        {
            return this.method.apply(this.target, args);
        }
        
        //#endregion


        //#region PUblic Properties

        public target: Object;
        public method: Function;

        //#endregion
    }
}