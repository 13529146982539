
namespace Simpleo.CustomerPortal
{
    // TODO: consider ServiceRegistry, ServiceInventoryManager, ServerManager
    export class ServiceManager implements IService
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        public initialize(services: Application.ServiceProvider): void
        {
            this.serviceManagementService = services.serviceManagementService;
        }

        public async getService(number: string): Promise<ServiceInfo>
        {
            if (!this.services)
                this.update();

            if (this._retrieval)
                await this._retrieval;

            return this.services.find(item => item.number == number);
        }

        public updateService(service: ServiceInfo): void
        {
            this.services = this.services.filter(item => item.number != service.number);
            this.services.push(service);
            this.updated.invoke(this);
        }

        public async update(): Promise<void>
        {
            if (!this._retrieval)
            {
                var retrieval = this._retrieval = this.serviceManagementService.getServices();

                try
                {
                    this.services = await retrieval;
                    this.updated.invoke(this);
                }
                finally
                {
                    this._retrieval = null;
                }
            }
            else
            {
                await this._retrieval;
            }
        }

        //#endregion


        //#region Public Events

        public updated = new Sparks.Event();

        //#endregion


        //#region Public Properties

        public services: ServiceInfo[] = null;        

        //#endregion


        //#region Protected Properties

        protected serviceManagementService: ServiceManagementService;

        //#endregion


        //#region Private Fields

        private _retrieval: Promise<ServiceInfo[]> = null;
        
        //#endregion
    }
}
