
declare module _Native
{
    type _Boolean = Boolean;
    type _BooleanConstructor = BooleanConstructor;
}

module Native
{
    export type Boolean = _Native._Boolean;
    export var Boolean: _Native._BooleanConstructor;
    export type BooleanConstructor = _Native._BooleanConstructor;
}

Native.Boolean = Boolean;
