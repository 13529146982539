
namespace Simpleo.CustomerPortal
{
    export interface UserInfo
    {
        //#region Properties

        name: string;

        emailAddress: string;
        
        //#endregion
    }
}
