
namespace Sparks.UI
{
    export class ListenDirective extends Directive
    {
        //#region Constructor

        public constructor($arguments: Expression<ListenDirective.Arguments>)
        {
            super();
            this.arguments = $arguments;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: ActivationContext): void
        {
            var $arguments = this.arguments.evaluate(activationContext.scope);
            $arguments.event.add($arguments.handler);
            activationContext.scope.disposing.add(() => $arguments.event.remove($arguments.handler));
        }

        //#endregion


        //#region Public Properties

        public arguments: Expression<ListenDirective.Arguments>;

        //#endregion
    }

    export namespace ListenDirective
    {
        export class ListenDirectiveCompiler extends BaseAttributeDirectiveCompiler
        {
            public constructor()
            {
                super("sx-listen");
            }
            
            public compile(node: HTMLElement, compilationContext: CompilationContext): Directive
            {
                var $arguments = this.compileArguments<Arguments>(node);
                return new ListenDirective($arguments);
            }
        }

        export interface Arguments
        {
            //#region Properties

            event?: Sparks.Event<any>;
            handler?: (sender: any, eventArgs: any) => void;

            //#endregion
        }
    }
}