///<reference path="../Native/Array.ts" />
///<reference path="Interop.ts" />

namespace Sparks
{
    export interface IArrayExtension
    {
        //#region Methods

        append<TItem>(array: TItem[], items: TItem[]): TItem[];
        areEqual<TItem>(first: TItem[], second: TItem[]): boolean; 
        clone<TItem>(array: TItem[]): TItem[];
        filter<TItem>(array: TItem[], filterFunction: (item: TItem) => boolean): TItem[];
        forEach<TItem>(array: TItem[], action: (item: TItem) => any): void;
        indexOf<TItem>(array: TItem[], searchFunction: (item: TItem) => boolean, start?: number): number;
        join<TItem>(arrays: TItem[][]): TItem[];
        ofType<TType>(array: any[], type: TypeReference<TType>): TType[];
        removeAll<TItem>(array: TItem[], value: TItem): void;
        toMap<TValue>(array: MapEntry<TValue>[]): Map<TValue>;
        toMap<TItem, TKey>(array: TItem[], keySelector: (item: TItem, index?: number) => TKey): Map<TItem>;
        toMap<TItem, TKey, TValue>(array: TItem[], keySelector: (item: TItem, index?: number) => TKey, valueSelector: (item: TItem, index?: number) => TValue): Map<TValue>;

        //#endregion


        //#region Constants

        Empty: any[];

        //#endregion
    }
    
    export class ArrayExtension
    {
        //#region Public Methods

        public static append<TItem>(array: TItem[], items: TItem[]): TItem[]
        {
            for (var i = 0; i < items.length; i++)
                array.push(items[i]);
            return array;
        }

        public static areEqual<TItem>(first: TItem[], second: TItem[]): boolean
        {
            if (first.length != second.length)
                return false

            for (var i = 0; i < first.length; i++)
            {
                if (first[i] != second[i])
                    return false;
            }

            return true;
        }

        public static clone<TItem>(array: TItem[]);
        public static clone<TItem>(array: any): TItem[]
        public static clone(array: any): any[]
        {
            return Native.Array.prototype.slice.apply(array);
        }
        
        public static filter<TItem>(array: TItem[], filterFunction: (item: TItem) => boolean): TItem[]
        {
            var result: TItem[] = [];

            for (var i = 0; i < array.length; i++)
            {
                if (filterFunction.apply(array[i], [array[i]]))
                    result.push(array[i]);
            }

            return result;
        }
        
        public static forEach<TItem>(array: TItem[], action: (item: TItem) => any): void
        {
            for (var i = 0; i < array.length; i++)
                action.apply(array[i], [array[i]]);
        }

        public static indexOf<TItem>(array: TItem[], searchFunction: (item: TItem) => boolean, start?: number): number
        {
            for (var i = 0; i < array.length; i++)
                if (searchFunction.apply(array[i], [array[i]]))
                    return i;

            return -1;
        }

        public static join<TItem>(arrays: TItem[][]): TItem[]
        {
            return Native.Array.prototype.concat.apply([], arrays);
        }

        public static ofType<TType>(array: any[], type: TypeReference<TType>): TType[]
        {
            return array.filter(item => item instanceof type);
        }

        public static removeAll<TItem>(array: TItem[], value: TItem): void
        {
            for (var index = array.indexOf(value); index > -1; index = array.indexOf(value))
                array.splice(index, 1);
        }
        
        public static toMap<TValue>(array: MapEntry<TValue>[]): Map<TValue>;
        public static toMap<TItem>(array: TItem[], keySelector: (item: TItem, index?: number) => string | number): Map<TItem>;
        public static toMap<TItem, TValue>(array: TItem[], keySelector: (item: TItem, index?: number) => string | number, valueSelector: (item: TItem, index?: number) => TValue): Map<TValue>;
        public static toMap<TItem, TValue>(array: (TItem | MapEntry<TValue> | TValue)[], keySelector?: (item: TItem, index?: number) => string | number, valueSelector?: (item: TItem, index?: number) => TValue): Map<TValue>
        {
            var map: Map<TValue> = {};

            if (valueSelector)
                array.forEach((item, index) => map[keySelector(<TItem>item, index)] = valueSelector(<TItem>item, index));
            else if (keySelector)
                array.forEach((item, index) => map[keySelector(<TItem>item, index)] = <TValue>item);
            else
                array.forEach(item => map[(<MapEntry<TValue>>item).key] = (<MapEntry<TValue>>item).value);

            return map;
        }

        //#endregion


        //#region Public Constants

        public static Empty = [];

        //#endregion
    }
    
    export interface ArrayConstructor extends Native.ArrayConstructor, IArrayExtension
    {
    }
    
    export type Array<T> = Native.Array<T>;
    
    export var Array: ArrayConstructor = Interop.extend(Native.Array, Sparks.ArrayExtension);
}