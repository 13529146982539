
namespace Sparks.UI
{
    export class ScopeDirective extends Directive
    {
        //#region Constructor

        public constructor($arguments: Expression<ScopeDirective.Arguments>)
        {
            super();
            this.arguments = $arguments;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: ActivationContext): void
        {
            var $arguments = (this.arguments) ? this.arguments.evaluate(activationContext.scope) : null;

            // TODO : create scope only if target is different from instantiationContext.range.container ? The current scope is already sufficiently isolated for instances in If and Foreach directives
            //var range = new Range(<HTMLElement>instantiationContext.target);
            var scope = new Scope(activationContext.scope);

            // TODO: remove this override, fix with above
            activationContext.scope = scope;

            if ($arguments && $arguments.propagate)
                scope.isIsolated = false;

            if ($arguments && $arguments.listen)
            {
                var listener = () => scope.update();
                $arguments.listen.forEach(event => event.add(listener));
                activationContext.scope.disposing.add(() => $arguments.listen.forEach(event => event.remove(listener)));
            }
        }

        //#endregion


        //#region Public Properties

        public arguments: Expression<ScopeDirective.Arguments>;

        //#endregion
    }

    export namespace ScopeDirective
    {
        export class ScopeDirectiveCompiler extends BaseAttributeDirectiveCompiler
        {
            public constructor()
            {
                super("sx-scope");
            }
            
            public compile(node: HTMLElement, compilationContext: CompilationContext): Directive
            {
                var $arguments = this.compileArguments<Arguments>(node);
                return new ScopeDirective($arguments);
            }
        }

        export interface Arguments
        {
            //#region Properties

            listen?: Sparks.Event<any>[];
            propagate?: boolean;

            //#endregion
        }
    }
}