///<reference path="Component.ts" />

namespace Sparks.Apps.Components
{
    export abstract class Viewer<TObject> extends Component
    {
        //#region Constructor

        public constructor()
        {
            super();
        }

        //#endregion


        //#region Public Events

        public updated = new Sparks.Event();

        //#endregion


        //#region Public Properties

        public item: TObject = null;

        //#endregion


        //#region Protected Methods

        protected load(...args: any[]): Promise<TObject>;
        protected load(): Promise<TObject>
        {
            var retrieval = this.runTask(<Promise<TObject>>this.fetch.apply(this, arguments))
                .then(
                    item =>
                    {
                        this.item = item;
                        this.updated.invoke(this);
                        return item;
                    });

            this.updated.invoke(this);

            return retrieval;
        }

        protected abstract fetch(...args): Promise<TObject>;
        
        //#endregion
    }
}
