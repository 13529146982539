/// <reference path="../Core/Error.ts" />

namespace Sparks
{
    export class HttpError extends Error
    {
        //#region Constructor

        public constructor(statusCode: number, message?: string, innerError?: Native.Error)
        {
            super(message, innerError);
            this.statusCode = statusCode;
        }

        //#endregion


        //#region Public Properties

        public statusCode: number;

        //#endregion
    }
}
