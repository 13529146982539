
namespace Sparks.UI
{
    export abstract class DirectiveCompiler
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        public abstract canCompile(node: Node): boolean;

        public abstract compile(node: Node, compilationContext: CompilationContext): Directive;

        //#endregion
    }
}