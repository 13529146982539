
namespace Simpleo.CustomerPortal
{
    export class Authenticator implements IService
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        public initialize(services: Application.ServiceProvider): void
        {
            this.authenticationService = services.authenticationService;
            this.uiManager = services.uiManager;
        }

        public async authenticate(): Promise<UserInfo>
        {
            if (this._authentication)
                return await this._authentication;

            try
            {
                var authentication = this._authentication = new Promise<UserInfo>(resolve => this._accept = resolve);
                                        
                var userRetrieval = this._task = this.authenticationService.getAuthentication();
                this.updated.invoke(this);

                var user = await userRetrieval;
                if (user)
                    this._accept(user);
                else
                    this.isRequested = true;
            }
            finally
            {
                this._task = null;
                this.updated.invoke(this);
            }

            try
            {
                return this.user = await authentication;
            }
            finally
            {
                this.isRequested = false;
                this.updated.invoke(this);
            }
        }

        public async submit(emailAddress: string, password: string): Promise<void>
        {
            if (this._task)
                throw new Error("Another request is under progress.");

            var now = Date.now();

            try
            {
                var authentication = this._task = this.authenticationService.authenticate(emailAddress, password);
                var user = await authentication;
                if (!user)
                    throw new Error("Authentication failed");
                this._accept(user);
            }
            catch (error)
            {
                await Sparks.Timer.wait(250, now);
                this.updated.invoke(this);
                throw error;
            }
            finally
            {
                this._task = null;
                this.updated.invoke(this);
            }
        }

        public async resetPassword(emailAddress: string): Promise<void>
        {
            if (this._task)
                throw new Error("Another request is under progress.");

            if (!emailAddress)
            {
                this.uiManager.showMessage("Email", "Please type a valid email address.");
                return Promise.resolve();
            }

            var confirmation = this.uiManager.confirm("Lost Password", "Do you wish to proceed with account access recovery for " + emailAddress + "?");
            var confirmed = await confirmation;
            if (confirmed)
            {
                try
                {
                    var recoveryRequest = this._task = this.authenticationService.resetPassword(emailAddress);
                    this.updated.invoke(this);
                    await recoveryRequest;
                    
                }
                finally
                {
                    this._task = null;
                    this.updated.invoke(this);
                }

                this.uiManager.showMessage("Lost Password", "An email was sent to " + emailAddress + " with instructions to reset your password.");
            }
        }

        public isAuthenticated(): boolean
        {
            return !!this.user;
        }

        public isWaiting(): boolean
        {
            return !!this._task;
        }

        public revoke(): Promise<void>
        {
            if (!this._authentication)
                return Promise.resolve();

            this._authentication = null;
            this._accept = null;
            this.user = null;
            this.updated.invoke(this);

            return this.authenticationService.terminate();
        }

        //#endregion


        //#region Public Events

        public updated = new Sparks.Event();

        //#endregion


        //#region Public Properties
    
        public isRequested: boolean = false;
        public user: UserInfo;

        //#endregion


        //#region Protected Properties

        protected authenticationService: AuthenticationService;
        protected uiManager: UIManager;

        //#endregion


        //#region Private Fields

        private _authentication: Promise<UserInfo> = null;
        private _accept: (user: UserInfo) => void;
        private _task: Promise<any> = null;

        //#endregion
    }
}
