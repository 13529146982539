
namespace Sparks.Apps.Tasks
{
    export class TaskManager
    {
        //#region Constructor

        public constructor(errorHandler?: (error: Error | string) => void)
        {
            if (errorHandler)
                this.errorOccurred.add((sender, error) => errorHandler(error));
        }

        //#endregion


        //#region Public Methods

        public run<TResult>(task: Promise<TResult>): Promise<TResult>;
        public run<TResult>(task: () => Promise<TResult>): Promise<TResult>;
        public run<TResult>(task: Promise<TResult> | (() => Promise<TResult>)): Promise<TResult>
        {
            task = (task instanceof Function) ? task() : task;

            this.tasks.push(task);

            this.updated.invoke(this);

            task.catch(this.task_error);
            task.finally(
                () =>
                {
                    this.tasks = this.tasks.filter(item => item != task);
                    this.updated.invoke(this);
                });

            return task;
        }

        public isWaiting(): boolean
        {
            return this.tasks.length > 0;
        }

        //#endregion


        //#region Public Events

        public errorOccurred = new Sparks.Event<Error | string>();
        public updated = new Sparks.Event();

        //#endregion


        //#region Public Properties

        public tasks: Promise<any>[] = [];

        //#endregion


        //#region Private Methods

        private task_error =
            (error: Error | string) => this.errorOccurred.invoke(this, error);

        //#endregion
    }
}