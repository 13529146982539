///<reference path="../Native/Number.ts" />
///<reference path="Interop.ts" />

namespace Sparks
{
    export interface INumberExtension
    {
        //#region Methods

        isNumber(value: any): value is number;
        parse(value: any): number;

        //#endregion
    }

    export class NumberExtension
    {
        //#region Public Methods

        public static isNumber(value: any): value is number
        {
            return "number" == typeof (value);
        }

        public static parse(value: any): number
        {
            return parseInt(value);
        }

        //#endregion
    }

    export interface NumberConstructor extends Native.NumberConstructor, INumberExtension
    {
    }

    export type Number = Native.Number;

    export var Number: NumberConstructor = Interop.extend(Native.Number, Sparks.NumberExtension);
}