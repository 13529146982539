
namespace Sparks.Apps.Routing
{
    export class RoutingManager
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        // TODO: consider move to NavigationManager
        public initialize(): void
        {
            window.addEventListener("popstate", this.window_popState);
            window.addEventListener("hashchange", this.window_hashChange);
        }

        public addRoute(route: Route): void
        {
            this.routes.push(route);
        }

        public resolve(context?: IContext): Route[]
        {
            context = context || { path: this.getCurrentPath() };

            return Router.resolveRoute(this.routes, context);
        }

        public apply(routePath: Route[], context?: IContext): void
        {
            var currentPath = this.getCurrentPath();

            context = context || { path: this.getCurrentPath() };

            var args: Sparks.Map<string> = {};
            routePath.forEach(
                route =>
                {
                    var routeArgs = route.getArguments(context);
                    if (routeArgs)
                        Sparks.Object.copy(args, routeArgs);
                });

            this.current = routePath;

            // TODO: consider move to NavigationManager
            if (currentPath != context.path)
                window.history.pushState(context.path, null, "#" + context.path);

            var handlers = Array.join(routePath.filter(route => route.handlers).map(route => route.handlers));
            handlers.forEach(handler => handler(args));

            this.updated.invoke(this);
        }

        public update(context?: IContext): void
        {
            context = context || { path: this.getCurrentPath() };
            var routePath = this.resolve(context);
            this.apply(routePath, context);
        }

        //#endregion


        //#region Public Events

        public updated = new Sparks.Event();

        //#endregion


        //#region Public Properties

        public routes: Route[] = [];

        public current: Route[] = null;

        //#endregion


        //#region Private Methods

        private getCurrentPath(): string
        {
            return Sparks.String.trim(window.location.hash, "#");
        }

        // TODO: consider move to NavigationManager
        private window_popState =
            () => { };

        // TODO: consider move to NavigationManager
        private window_hashChange =
            () => this.update();

        //#endregion
    }
}