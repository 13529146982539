
namespace Simpleo.CustomerPortal
{
    export class App
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        public start(): void
        {
            // Inject services
            this.services.app = this;
            this.services.accessRecovery = new AccessRecovery();
            this.services.accountManagementService = new AccountManagementService("/api/v1/account");
            this.services.accountManager = new AccountManager();
            this.services.authenticationService = new AuthenticationService("/api/v1/authentication");
            this.services.authenticator = new Authenticator();
            this.services.confirmationBox = new Sparks.Apps.UI.ConfirmationBox();
            this.services.invoiceManagementService = new InvoiceManagementService("/api/v1/invoices");
            this.services.invoiceManager = new InvoiceManager();
            this.services.messageBox = new Sparks.Apps.UI.MessageBox();
            this.services.paymentProcessor = new PaymentProcessor();
            this.services.renewalManager = new RenewalManager();
            this.services.routingManager = new RoutingManager();
            this.services.serviceManagementService = new ServiceManagementService("/api/v1/services");
            this.services.serviceManager = new ServiceManager();
            this.services.serviceViewer = new ServiceViewer();
            this.services.uiManager = new UIManager();
            this.services.views = this.services.uiManager.views;

            // Views
            var appView =
                Sparks.Apps.UI.ViewBuilder.create("appView")
                    .addView("welcomeScreen")
                    .addView("accessRecoveryScreen")
                    .addView(
                        "mainScreen",
                        builder =>
                            builder
                                .addView("dashboard")
                                .addView("serviceBrowser")
                                .addView("serviceViewer")
                                .addView("invoiceBrowser"))
                    .build();
            this.services.uiManager.addView(appView);

            // Routing
            var views = this.services.views;
            var appRoutes =
                Sparks.Apps.Routing.RouteBuilder.create()
                    .when(() => this.isSignedIn())
                    .useRoute(builder => builder.when("").useHandler(() => views.dashboard.show()))
                    .useRoute(builder => builder.when("/services").useHandler(() => views.serviceBrowser.show()))
                    .useRoute(
                        builder =>
                            builder.when("/services/{id}")
                                .useHandler(args => this.services.serviceViewer.load(args.id))
                                .useHandler(() => views.serviceViewer.show()))
                    .useRoute(builder => builder.when("/invoices").useHandler(() => views.invoiceBrowser.show()))
                    .build();
            this.services.routingManager.addRoute(appRoutes);

            var guestRoutes =
                Sparks.Apps.Routing.RouteBuilder.create()
                    .useRoute(
                        builder =>
                            builder.when("/recover-access/{key}")
                                .useHandler(args => this.recoverAccess(args.key))
                                .useHandler(args => views.accessRecoveryScreen.show()))
                    .useRoute(
                        builder =>
                            builder
                                .useHandler(() => this.signIn())
                                .useHandler(() => views.welcomeScreen.show()))
                    .build();
            this.services.routingManager.addRoute(guestRoutes);

            // Initialize services
            Sparks.Map.getValues(this.services).forEach(service => (service.initialize) ? service.initialize(this.services) : undefined);

            // Update routing
            this.services.routingManager.update();
        }

        public async signIn(): Promise<void>
        {
            var authentication = this.services.authenticator.authenticate();
            this.user = await authentication;
            
            this.services.serviceManager.update();
            this.services.invoiceManager.update();

            this.services.routingManager.update();

            this.updated.invoke(this);
        }

        public async signOut(): Promise<void>
        {
            this.user = null;
            this.updated.invoke(this);

            await this.services.authenticator.revoke();

            this.services.routingManager.update();
        }

        public isSignedIn(): boolean
        {
            return this.services.authenticator.isAuthenticated();
        }

        public async recoverAccess(key: string): Promise<void>
        {
            return this.services.accessRecovery.recoverAccess(key);

        }

        //#endregion


        //#region Public Events

        public updated = new Sparks.Event();

        //#endregion


        //#region Public Properties
                
        public user: UserInfo = null;
        public services: Application.ServiceProvider = {};

        //#endregion
    }

    export namespace Application
    {
        export interface ServiceProvider extends Sparks.Map<any>
        {
            app?: App;
            accessRecovery?: AccessRecovery;
            accountManagementService?: AccountManagementService;
            accountManager?: AccountManager;
            authenticationService?: AuthenticationService;
            authenticator?: Authenticator;
            confirmationBox?: Sparks.Apps.UI.ConfirmationBox;
            invoiceManagementService?: InvoiceManagementService;
            invoiceManager?: InvoiceManager;
            messageBox?: Sparks.Apps.UI.MessageBox;
            paymentProcessor?: PaymentProcessor;
            renewalManager?: RenewalManager;
            routingManager?: RoutingManager;
            serviceManagementService?: ServiceManagementService;
            serviceManager?: ServiceManager;
            serviceViewer?: ServiceViewer;
            uiManager?: UIManager;
            views?: Sparks.Map<Sparks.Apps.UI.View>;
        }
    }
}
