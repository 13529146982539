
namespace Sparks.Code
{
    export interface UnaryOperationNode extends ExpressionNode
    {
        //#region Properties

        operand: ExpressionNode;
        operator: UnaryOperator;

        //#endregion
    }
}
