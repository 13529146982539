
namespace Sparks.UI
{
    export class ProcessingContext
    {
        //#region Constructor

        public constructor(scope: Scope)
        {
            this.scope = scope;
            this.watches = Array.from(scope.watches);
            this.scopes = Array.from(scope.children);
        }

        //#endregion


        //#region Public Properties

        public scope: Scope;
        public watches: Watch<any>[];
        public scopes: Scope[];
        
        //#endregion
    }
}