
namespace Sparks.Apps.UI
{
    export class UIManager
    {
        //#region Constructor

        public constructor(appTemplate?: string, node?: HTMLElement)
        {
            this.appTemplate = appTemplate || "app";
            this.node = node || document.body;
        }

        //#endregion


        //#region Public Methods

        public initialize(services: UIServiceProvider): void
        {
            // Services
            this.confirmationBox = services.confirmationBox;
            this.messageBox = services.messageBox;
            this.promptBox = services.promptBox;

            // Initialize compiler and activator
            this._compiler = new Sparks.UI.Compiler(Sparks.UI.Compiler.defaultCompiler.directiveCompilers);
            this._compiler.load(
                [
                    new Sparks.Apps.Forms.FormDirective.FormDirectiveCompiler(),
                    new Sparks.Apps.Forms.FormSubmitDirective.FormSubmitDirectiveCompiler(),
                    new Sparks.Apps.Forms.FieldDirective.FieldDirectiveCompiler(),
                    new Sparks.Apps.Forms.FieldValidateDirective.FieldValidateDirectiveCompiler()
                ]);
            this._activator = new Sparks.UI.Activator(this._compiler);

            // Create and initalize root scope
            this.scope = new Sparks.UI.Scope();
            this.scope.set("Sparks", Sparks);
            this.scope.set("templates", this.getTemplate.bind(this));
            Sparks.Map.forEach(services, (name, service) => this.scope.set(name, service));

            // Instantiate app template
            var appTemplate = this.getTemplate(this.appTemplate);
            if (!appTemplate)
                throw new Error("Can't find template '" + this.appTemplate + "'");
            this.instantiate(appTemplate);

            // Update
            this.update();
        }

        public addView(view: View): void
        {
            if (this.views[view.name] == view)
                return;
            
            if (this.views[view.name])
                throw new Error("View is already defined: " + view.name);

            this.views[view.name] = view;
            view.updated.add(this.view_updated);

            Sparks.Map.forEach(view.views, (name, childView) => this.addView(childView));
        }

        public getTemplate = (name: string): Sparks.UI.Template =>
        {
            var template = this.templates[name];

            var templateScript = this.node.ownerDocument.getElementById(name);
            if (!templateScript)
                throw new Error("Resource not found: " + name);
            var templateHtml = templateScript.innerHTML;

            if (template)
            {
                if (templateHtml == this._templatesHtml[name])
                    return template;
            }

            this._templatesHtml[name] = templateHtml;
            var templateNodes = Sparks.DOM.HTMLDocument.loadHtml(this.node.ownerDocument, templateHtml);
            template = this.templates[name] = this._compiler.compile(templateNodes);

            return template;
        }

        public instantiate(template: Sparks.UI.Template, space?: Sparks.UI.Space): Sparks.UI.Space
        {
            space = space || { range: new Sparks.UI.Range(this.node), scope: this.scope };
            this._activator.instantiate(space.range, template.directives, space.scope);
            return space;
        }

        public confirm(title: string, message: string, confirm?: string, cancel?: string): Promise<boolean>
        {
            return this.confirmationBox.confirm(title, message, confirm, cancel);
        }

        public prompt(title: string, statement: string, defaultValue?: string): Promise<string>
        {
            return this.promptBox.prompt(title, statement, defaultValue || null);
        }

        public showMessage(title: string, message: string): Promise<void>
        {
            return this.messageBox.show(title, message);
        }

        public update(): void
        {
            this.scope.update();
        }

        //#endregion


        //#region Public Events

        public viewUpdated = new Sparks.Event<View>();

        //#endregion


        //#region Public Properties

        public appTemplate: string;
        public node: HTMLElement;
        public templates: Sparks.Map<Sparks.UI.Template> = {};
        public scope: Sparks.UI.Scope = null;
        public views: Sparks.Map<View> = {};

        //#endregion


        //#region Protected Properties

        protected confirmationBox: ConfirmationBox;
        protected messageBox: MessageBox;
        protected promptBox: PromptBox;

        //#endregion


        //#region Private Methods

        private view_updated =
            (view: View): void => this.viewUpdated.invoke(this, view);

        //#endregion


        //#region Private Fields

        private _compiler: Sparks.UI.Compiler = null;
        private _activator: Sparks.UI.Activator = null;
        private _templatesHtml: Sparks.Map<string> = {};

        //#endregion
    }

    export interface UIServiceProvider extends IServiceProvider
    {
        //#region Properties

        confirmationBox?: ConfirmationBox;
        messageBox?: MessageBox;
        promptBox?: PromptBox;

        //#endregion
    }
}
