
namespace Sparks.Apps.Common
{
    export interface ServiceProvider extends IServiceProvider
    {
        //#region Properties

        errorHandler?: Diagnostics.IErrorHandler;

        //#endregion
    }
}
