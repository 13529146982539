///<reference path="../Native/Date.ts" />
///<reference path="Interop.ts" />

namespace Sparks
{
    export interface IDateExtension
    {
        //#region Methods

        addDays(date: Date, days: number): Date;
        addHours(date: Date, hours: number): Date;
        addMilliseconds(date: Date, milliseconds: number): Date;
        addMinutes(date: Date, minutes: number): Date;
        addMonths(date: Date, months: number): Date;
        addSeconds(date: Date, seconds: number): Date;
        addYears(date: Date, years: number): Date;
        areEqual(left: Date, right: Date): boolean;
        format(date: Date, format: string): string;
        getDate(): Date;
        getDate(date: Date): Date;
        getDaysInMonth(year: number, month: number): number;
        isDate(value: any): value is Native.Date;
        isLeapYear(year: number): boolean;
        isValid(value: Date): boolean;
        toIsoString(date: Date): string;
        toLocal(date: Date): Date;
        toUtc(date: Date): Date;

        //#endregion
    }
    
    export class DateExtension
    {
        //#region Public Methods
        
        public static addDays(date: Date, days: number): Date
        {
            var result = new Native.Date(date.valueOf());
            result.setDate(date.getDate() + days);
            return result;
        }

        public static addHours(date: Date, hours: number): Date
        {
            var result = new Native.Date(date.valueOf());
            result.setHours(date.getHours() + hours);
            return result;
        }

        public static addMilliseconds(date: Date, milliseconds: number): Date
        {
            var result = new Native.Date(date.valueOf());
            result.setMilliseconds(date.getMilliseconds() + milliseconds);
            return result;
        }

        public static addMinutes(date: Date, minutes: number): Date
        {
            var result = new Native.Date(date.valueOf());
            result.setMinutes(date.getMinutes() + minutes);
            return result;
        }

        public static addMonths(date: Date, months: number): Date
        {
            var result = new Native.Date(date.valueOf());
            result.setMonth(date.getMonth() + months);
            return result;
        }

        public static addSeconds(date: Date, seconds: number): Date
        {
            var result = new Native.Date(date.valueOf());
            result.setSeconds(date.getSeconds() + seconds);
            return result;
        }

        public static addYears(date: Date, years: number): Date
        {
            var result = new Native.Date(date.valueOf());
            result.setFullYear(date.getFullYear() + years);
            return result;
        }

        public static areEqual(left: Date, right: Date): boolean
        {
            if (left == right)
                return true;

            if (!left || !right)
                return false;

            return left.getTime() == right.getTime();
        }

        public static format(date: Date, format: string): string
        {
            var values: Sparks.Map<any> =
            {
                year: String.pad(date.getFullYear(), 4, '0'),
                month: String.pad(date.getMonth() + 1, 2, '0'),
                longMonth: this._longMonths[date.getMonth()],
                shortMonth: this._shortMonths[date.getMonth()],
                day: String.pad(date.getDate(), 2, '0'),
                hours: String.pad(date.getHours(), 2, '0'),
                minutes: String.pad(date.getMinutes(), 2, '0'),
                seconds: String.pad(date.getSeconds(), 2, '0')
            };

            return format.replace(/{([^\}]*)}/g, function (match, token) { return values[token]; });
        }

        public static getDaysInMonth(year: number, month: number): number
        {
            return new Native.Date(year, month + 1, 0).getDate();
        }        

        public static getDate(): Date;
        public static getDate(date: Date): Date;
        public static getDate(date?: Date): Date
        {
            date = date || new Native.Date();
            return new Native.Date(date.getFullYear(), date.getMonth(), date.getDate());
        }

        public static isDate(value: any): value is Native.Date
        {
            return value instanceof Native.Date;
        }

        public static isLeapYear(year: number): boolean
        {
            return new Native.Date(year, 1, 29).getMonth() == 1;
        }

        public static isValid(value: Date): boolean
        {
            return Date.isDate(value) && !isNaN(value.valueOf());
        }

        public static toLocal(date: Date): Date
        {
            var offset = date.getTimezoneOffset() * 60 * 1000;
            return new Native.Date(date.valueOf() - offset);
        }

        public static toUtc(date: Date): Date
        {
            var offset = date.getTimezoneOffset() * 60 * 1000;
            return new Native.Date(date.valueOf() + offset);
        }

        public static toIsoString(date: Date): string
        {
            var isoString =
                date.getFullYear() + "-" + String.pad(date.getMonth() + 1, 2, '0') + "-" + String.pad(date.getDate(), 2, '0') + " " +
                String.pad(date.getHours(), 2, '0') + ":" + String.pad(date.getMinutes(), 2, '0') + ":" + String.pad(date.getSeconds(), 2, '0');
            return isoString;
        }

        //#endregion


        //#region Private Fields

        private static _longMonths: string[] = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
        private static _shortMonths: string[] = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

        //#endregion
    }
    
    export interface DateConstructor extends Native.DateConstructor, IDateExtension
    {
    }

    export type Date = Native.Date;

    export var Date: DateConstructor = Interop.extend(Native.Date, DateExtension);
}