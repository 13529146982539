///<reference path="Node.ts" />

namespace Sparks.Xml
{
    export interface IElementExtension extends INodeExtension
    {
        //#region Methods
        
        append(element: Element, node: Node): Element;
        append(element: Element, nodes: Node[]): Element;
        getAttributes(element: Element): Map<string>;
        prepend(element: Element, node: Node): Element;
        prepend(element: Element, nodes: Node[]): Element;
        removeChildren(element: Element): Node[];
        updateAttribute(element: Element, name: string, value: string): void;
        updateAttributes(element: Element, attributes: Map<string>, removeUndefined?: boolean): void;

        //#endregion
    }
    
    export class ElementExtension extends Sparks.Xml.NodeExtension
    {
        //#region Public Methods

        public static append(element: Element, node: Node): Element;
        public static append(element: Element, nodes: Node[]): Element;
        public static append(element: Element, nodeOrNodes: Node | Node[]): Element;
        public static append(element: Element, nodeOrNodes: Node | Node[]): Element
        {
            if (Array.isArray(nodeOrNodes))
                (<Node[]>nodeOrNodes).forEach(node => element.appendChild(node));
            else
                element.appendChild(nodeOrNodes);

            return element;
        }

        public static getAttributes(element: Element): Map<string>
        {
            var attributes: { [s: string]: string } = {};

            for (var i = 0; i < element.attributes.length; i++)
            {
                var attribute = element.attributes[i];
                attributes[attribute.name] = attribute.value;
            }

            return attributes;
        }

        public static prepend(element: Element, node: Node): Element;
        public static prepend(element: Element, nodes: Node[]): Element;
        public static prepend(element: Element, nodeOrNodes: any): Element
        {
            if (element.firstChild)
            {
                Node.insertBefore(element.firstChild, nodeOrNodes);
                return element;
            }
            else
            {
                Element.append(element, nodeOrNodes);
                return element;
            }
        }

        public static removeChildren(element: Element): Node[]
        {
            var children = Collection.toArray(element.childNodes);;

            while (element.firstChild)
                element.removeChild(element.firstChild);

            return children;
        }

        public static updateAttribute(element: Element, name: string, value: string): void
        {
            if (value != null)
                element.setAttribute(name, value);
            else
                element.removeAttribute(name);
        }

        public static updateAttributes(element: Element, attributes: Map<string>, removeUndefined?: boolean): void
        {
            if (removeUndefined)
            {
                var current = Element.getAttributes(element);
                for (var name in current)
                {
                    if (!(name in attributes))
                        element.removeAttribute(name);
                }
            }

            for (var name in attributes)
                Element.updateAttribute(element, name, attributes[name]);
        }

        //#endregion
    }
    
    export interface ElementConstructor extends IElementExtension
    {
        prototype: Element;
        new (): Element;
    }

    export type Element = Native.Element;

    export var Element: ElementConstructor = <any>ElementExtension;
}
