
namespace Sparks.Apps.Forms
{
    export class FormDirective extends Sparks.UI.Directive
    {
        //#region Constructors

        public constructor(formName?: string)
        {
            super();
            this.formName = formName || "form";
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: Sparks.UI.ActivationContext): void
        {
            // Create and assign form controller
            var formController = new FormController();
            if (activationContext.scope.has(this.formName, true))
                console.log("Warning: there is already a symbol '" + this.formName + "' in the way");
            activationContext.scope.set(this.formName, formController);

            // Import FormState
            activationContext.scope.set("FormState", FormState);

            // Update
            formController.updated.add(() => activationContext.scope.update());

            // Submit event
            if (Sparks.DOM.Node.isElement(activationContext.target, "form"))
            {
                activationContext.target.addEventListener(
                    "submit",
                    function (eventArgs)
                    {
                        formController.submit();
                        eventArgs.preventDefault();
                        return false;
                    });
            }

            // Finish activation
            activationContext.activator.activate(activationContext);

            // Initialize
            formController.state = FormState.NotReady;
            formController.initialize();
        }

        //#endregion


        //#region Public Properties

        public formName: string;

        //#endregion
    }


    export namespace FormDirective
    {
        export class FormDirectiveCompiler extends Sparks.UI.BaseAttributeDirectiveCompiler
        {
            //#region Constructor

            public constructor()
            {
                super("sx-form");
            }

            //#endregion


            //#region Public Methods

            public compile(node: HTMLElement, compilationContext: Sparks.UI.CompilationContext): Sparks.UI.Directive
            {
                return new FormDirective();
            }

            //#endregion
        }
    }
}