
namespace Sparks.UI
{
    export interface Space
    {
        //#region Properties

        range: Range;
        scope: Scope;

        //#endregion
    }
}