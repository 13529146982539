
namespace Sparks.UI
{
    export class Activator
    {
        //#region Constructor

        public constructor(compiler?: Compiler)
        {
            this.compiler = compiler || Compiler.defaultCompiler;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: ActivationContext): void
        {
            for (var next = activationContext.queue.shift(); next; next = activationContext.queue.shift())
                next.activate(activationContext);
        }

        public activateNode(node: Node, directives: Directive[], scope: Scope): void
        {
            var activationContext = new ActivationContext(this, this.compiler, Processor.defaultProcessor, directives, node, null, scope);
            this.activate(activationContext);
            activationContext.activated.invoke(this);
        }

        public instantiate(range: Range, directives: Directive[], scope: Scope): void
        {
            var activationContext = new ActivationContext(this, this.compiler, Processor.defaultProcessor, directives, null, range, scope);
            activationContext.instantiates = true;
            this.activate(activationContext);
            activationContext.activated.invoke(this);
        }

        //#endregion


        //#region Public Properties

        public compiler: Compiler;

        //#endregion
    }

    export class ActivationContext
    {
        //#region Constructor

        public constructor(activator: Activator, compiler: Compiler, processor: Processor, directives: Directive[], target: Node, range: Range, scope: Scope)
        {
            this.activator = activator;
            this.compiler = compiler;
            this.processor = processor;
            this.directives = directives;
            this.target = target || null;
            this.range = range || null;
            this.scope = scope;

            this.queue = this.directives.slice();
        }

        //#endregion


        //#region Public Events

        public activated = new Sparks.Event();

        //#endregion


        //#region Public Properties

        public activator: Activator;
        public compiler: Compiler;
        public processor: Processor;
        public directives: Directive[];
        public range: Range;
        public target: Node;
        public scope: Scope;
        public queue: Directive[];
        public instantiates: boolean = false;

        //#endregion
    }
}