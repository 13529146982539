
namespace Sparks.UI
{
    export class ForeachDirective extends Directive
    {
        //#region Constructor

        public constructor(items: Expression<any[]>, template: Template, valueIterator: string, indexer?: string)
        {
            super();
            this.items = items;
            this.template = template;
            this.valueIterator = valueIterator;
            this.indexer = indexer || null;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: ActivationContext): void
        {
            if (activationContext.instantiates)
            {
                // Create range
                var range = activationContext.range.addRange("sx-foreach=\"" + this.items.source + "\"");

                // Initialize
                this.initialize(activationContext.activator, activationContext.scope, range);
            }
            else
            {
                // Create range
                var reference = activationContext.target.ownerDocument.createComment("sx-foreach=\"" + this.items.source + "\"");
                activationContext.target.parentElement.insertBefore(reference, activationContext.target);
                var range = new Range(activationContext.target.parentElement, reference);

                // Remove node
                activationContext.target.parentElement.removeChild(activationContext.target);

                // Initialize
                this.initialize(activationContext.activator, activationContext.scope, range);
            }
        }

        //#endregion


        //#region Public Properties

        public valueIterator: string;
        public indexer: string;
        public items: Expression<any[]>;
        public template: Template;

        //#endregion


        //#region Private Methods

        private initialize(activator: Activator, scope: Scope, range: Range): void
        {
            var itemScopes: Scope[] = [];
            var itemRanges: Range[] = [];

            scope.watchCollection(
                this.items,
                items =>
                {
                    items = items || [];

                    // Remove exceeding
                    while (itemScopes.length > items.length)
                    {
                        itemScopes.pop().dispose();
                        itemRanges.pop().dispose();
                    }

                    // Add missing
                    while (itemScopes.length < items.length)
                    {
                        // Create range
                        var itemRange = range.addRange("  " + this.items.source + "[" + (items.length - 1) + "]");
                        itemRanges.push(itemRange);

                        // Create scope
                        var itemScope = new Scope(scope);
                        itemScope.isIsolated = false;
                        itemScopes.push(itemScope);

                        // Instantiate
                        activator.instantiate(itemRange, this.template.directives, itemScope);
                    }

                    // Update
                    itemScopes.forEach(
                        (scope, index) =>
                        {
                            scope.set(this.valueIterator, items[index]);
                            if (this.indexer)
                                scope.set(this.indexer, index);
                        });
                });
        }

        //#endregion
    }

    export namespace ForeachDirective
    {
        export class ForeachDirectiveCompiler extends BaseAttributeDirectiveCompiler
        {
            //#region Constructor

            public constructor()
            {
                super("sx-foreach");
            }

            //#endregion


            //#region Public Methods
            
            public compile(node: HTMLElement, compilationContext: CompilationContext): Directive
            {
                // Retrieve arguments
                var $arguments = this.parseArguments(this.getArguments(node));
                var items = ExpressionCompiler.compileExpression<any[]>($arguments.collection);

                // Interrupt node compilation
                compilationContext.queue = [];

                // Build template with remaining node directives
                var template = compilationContext.compiler.compile([node]);
                return new ForeachDirective(items, template, $arguments.value, $arguments.index);
            }

            //#endregion


            //#region Protected Methods

            protected parseArguments($arguments: string): ForeachDirective.Arguments
            {
                var parser = /^(?:(\w+)|\((\w+),\s*(\w+)\))\s+in\s+(.+?)(?:\s+by\s+(\w+))?$/;
                var parseResult = parser.exec($arguments);

                if (!parseResult)
                    throw new Error("Invalid statement for " + ForeachDirective.name + ": " + $arguments);

                return {
                    //key: parseResult[2] || null,
                    value: parseResult[1] || parseResult[3],
                    index: parseResult[5] || null,
                    collection: parseResult[4]
                };
            }

            //#endregion
        }
        
        export interface Arguments
        {
            //#region Properties

            //key?: string;
            value?: string;
            index?: string;
            collection?: string;

            //#endregion
        }
    }
}