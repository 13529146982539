
namespace Sparks.Code
{
    export interface Tracer
    {
        //#region Public Methods
        
        ($function: Function, target: any, $arguments: Collection<any>): any;
                
        //#endregion
    }
}
