
namespace Simpleo.CustomerPortal
{
    export interface InvoiceInfo
    {
        //#region Properties

        number: string;

        reference: string;

        date: Date;

        amount: number;

        status: string;

        items: InvoiceItemInfo[];

        url: string;

        //#endregion
    }

    export namespace InvoiceInfo
    {
        export var serializer = new Sparks.Serializer<InvoiceInfo>(
            {
                "date": (value: Date) => (value) ? value.toJSON() : null
            },
            {
                "date": value => (value) ? new Date(value) : null
            });
    }
}
