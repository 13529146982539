
namespace Sparks
{
    export class Context implements IContext
    {
        //#region Constructor

        public constructor();
        public constructor(parentContext: IContext);
        public constructor(parentContext: IContext, locals: Map<any>);
        public constructor(parentContext?: IContext, locals?: Map<any>)
        {
            this.parent = parentContext || null;        

            this.get = (parentContext) ? this.getGlobal : this.getLocal;
            this.set = this.setLocal;
            this.has = (parentContext) ? this.hasGlobal : this.hasLocal;
            this.enumerate = (parentContext) ? this.enumerateGlobal : this.enumerateLocal;

            this._locals = locals || {};
        }

        //#endregion


        //#region Public Methods

        public get: (name: string) => any;

        public set: (name: string, value: any) => any;

        public has: (name: string) => boolean;

        public enumerate: () => string[];

        //#endregion


        //#region Public Properties

        public parent: IContext;

        //#endregion


        //#region Private Methods
    
        private getLocal(name: string): any
        {
            return this._locals[name];
        }

        private getGlobal(name: string): any
        {
            return (this._locals.hasOwnProperty(name)) ? this._locals[name] : this.parent.get(name);
        }

        private setLocal(name: string, value: any): any
        {
            return this._locals[name] = value;
        }
        
        private hasLocal(name: string): boolean
        {
            return this._locals.hasOwnProperty(name);
        }

        private hasGlobal(name: string): boolean
        {
            return this._locals.hasOwnProperty(name) || this.parent.has(name);
        }
    
        private enumerateLocal(): string[]
        {
            return Object.getOwnPropertyNames(this._locals);
        }

        private enumerateGlobal(): string[]
        {
            return Array.join([Object.getOwnPropertyNames(this._locals), this.parent.enumerate()]);
        }
        
        //#endregion


        //#region Private Fields

        private _locals: Map<any>;

        //#endregion
    }
}
