
namespace Simpleo.CustomerPortal
{
    export interface RenewalInfo
    {
        //#region Properties

        serviceNumber: string;

        orderNumber: string;

        invoiceNumber: string;

        renewalLength: number;

        expiry: Date;

        amount: number;

        fees: number;

        total: number;

        //#endregion
    }

    export namespace RenewalInfo
    {
        export var serializer = new Sparks.Serializer<RenewalInfo>(
            {
                "expiry": (value: Date) => (value) ? value.toJSON() : null,
            },
            {
                "expiry": value => (value) ? new Date(value) : null
            }
        );
    }
}
