
namespace Sparks.Code
{
    export enum CodeNodeType
    {
        ArrayInstantiation = 0,
        AssignmentExpression,
        //AttributeReference,
        //BaseReference,
        BinaryOperation,
        //BlockExpression,
        //BreakStatement,
        //CastExpression,
        //CatchDeclaration,
        //ClassDeclaration,
        //ClassReference,
        //CodeSnippetExpression,
        //CodeSnippetStatement,
        //CodeUnit,
        ConditionExpression,
        //ConstructorDeclaration,
        //ContinueStatement,
        //DefaultExpression,
        //DelegateDeclaration,
        //EventDeclaration,
        ExpressionSequence,
        ExpressionStatement,
        //FieldDeclaration,
        //FieldReference,
        //ForeachStatement,
        //ForStatement,
        //GenericTypeParameter,
        //IfStatement,
        Indexer,
        //InterfaceDeclaration,
        //InterfaceReference,
        LambdaExpression,
        LiteralValue,
        //LockStatement,
        MemberReference,
        MethodDeclaration,
        MethodInvocation,
        //MethodReference,
        //NamespaceDeclaration,
        ObjectInstantiation,
        //ParameterDeclaration,
        ParameterReference,
        //PropertyDeclaration,
        //PropertyReference,
        //ReturnStatement,
        StatementBlock,
        ThisReference,
        //ThrowStatement,
        //TryCatchFinallyStatement,
        TypeReference,
        UnaryOperation,
        //UsingNamespaceClause,
        //UsingStatement,
        //UsingTypeClause,
        //ValueReference,
        //VariableDeclaration,
        //VariableDeclarationStatement,
        VariableReference,
        //WhileStatement
    }
}
