
namespace Sparks.UI
{
    export class AttrDirective extends Directive
    {
        //#region Constructor

        public constructor(attributes: Sparks.Map<Expression<string>>)
        {
            super();
            this.attributes = attributes;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: ActivationContext): void
        {
            // Bind expressions
            var target = <HTMLElement>activationContext.target;
            var locals = { "$node": target, "$scope": activationContext.scope };
            var expressions = Map.remap(this.attributes, (className, expression) => ExpressionCompiler.bindLocals(expression, locals));

            // Create watches
            var watches = Map.remap(
                expressions,
                (attributeName, expression) =>
                    activationContext.scope.watch(
                        expression,
                        value =>
                        {
                            if (value != null)
                                target.setAttribute(attributeName, value);
                            else
                                target.removeAttribute(attributeName);
                        }));

            // Initialize on render frame
            window.requestAnimationFrame(() => Map.forEach(watches, (className, watch) => watch.update()));
        }

        //#endregion


        //#region Public Properties

        public attributes: Sparks.Map<Expression<string>>;

        //#endregion
    }

    export namespace AttrDirective
    {
        export class AttrDirectiveCompiler extends BaseAttributeDirectiveCompiler
        {
            public constructor()
            {
                super("sx-attr");
            }
            
            public compile(node: HTMLElement, compilationContext: CompilationContext): Directive
            {
                var attributes = this.compileNamedArguments(node);
                return new AttrDirective(attributes);
            }
        }
    }
}