/// <reference path="../Core/Processor.ts" />

namespace Sparks.Code.Compilation
{
    export class CodeNodeProcessor extends Processor<CodeNode>
    {
        //#region Constructor

        public constructor()
        {
            super();
        }

        //#endregion


        //#region Protected Methods

        protected processArrayInstantiation(arrayInstantiationNode: ArrayInstantiationNode): CodeNode
        {
            arrayInstantiationNode.initialValues = arrayInstantiationNode.initialValues ? arrayInstantiationNode.initialValues.map(item => this.process(item)) : null;
            arrayInstantiationNode.itemType = (arrayInstantiationNode.itemType) ? this.processTypeReference(arrayInstantiationNode.itemType) : null;
            arrayInstantiationNode.size = (arrayInstantiationNode.size) ? this.process(arrayInstantiationNode.size) : null;
            return arrayInstantiationNode;
        }

        protected processAssignmentExpression(assignmentExpressionNode: AssignmentExpressionNode): CodeNode
        {
            assignmentExpressionNode.target = this.process(assignmentExpressionNode.target);
            assignmentExpressionNode.expression = this.process(assignmentExpressionNode.expression);
            return assignmentExpressionNode;
        }

        protected processBinaryOperation(binaryOperationNode: BinaryOperationNode): CodeNode
        {
            binaryOperationNode.leftOperand = this.process(binaryOperationNode.leftOperand);
            binaryOperationNode.rightOperand = this.process(binaryOperationNode.rightOperand);
            return binaryOperationNode;
        }

        protected processConditionExpression(conditionExpressionNode: ConditionExpressionNode): CodeNode
        {
            conditionExpressionNode.condition = this.process(conditionExpressionNode.condition);
            conditionExpressionNode.trueExpression = this.process(conditionExpressionNode.trueExpression);
            conditionExpressionNode.falseExpression = this.process(conditionExpressionNode.falseExpression);
            return conditionExpressionNode;
        }
        
        protected processExpressionSequence(expressionSequenceNode: ExpressionSequenceNode): CodeNode
        {
            expressionSequenceNode.expressions = expressionSequenceNode.expressions.map(expression => this.process(expression));
            return expressionSequenceNode;
        }

        protected processExpressionStatement(expressionStatementNode: ExpressionStatementNode): CodeNode
        {
            expressionStatementNode.expression = this.process(expressionStatementNode.expression);
            return expressionStatementNode;
        }

        protected processIndexer(indexerNode: IndexerNode): CodeNode
        {
            indexerNode.target = this.process(indexerNode.target);
            indexerNode.index = this.process(indexerNode.index);
            return indexerNode;
        }

        protected processLambdaExpression(lambdaExpressionNode: LambdaExpressionNode): CodeNode
        {
            lambdaExpressionNode.expression = this.process(lambdaExpressionNode.expression);
            return lambdaExpressionNode;
        }

        protected processLiteralValue(literalValueNode: LiteralValueNode): CodeNode
        {
            return literalValueNode;
        }

        protected processMemberReference(memberReferenceNode: MemberReferenceNode): CodeNode
        {
            memberReferenceNode.target = (memberReferenceNode.target) ? this.process(memberReferenceNode.target) : null;
            return memberReferenceNode;
        }

        protected processMethodInvocation(methodInvocationNode: MethodInvocationNode): CodeNode
        {
            methodInvocationNode.method = this.process(methodInvocationNode.method);
            methodInvocationNode.arguments = (methodInvocationNode.arguments) ? methodInvocationNode.arguments.map(argument => this.process(argument)) : null;
            return methodInvocationNode;
        }
        
        protected processObjectInstantiation(objectInstantiationNode: ObjectInstantiationNode): CodeNode
        {
            objectInstantiationNode.type = this.process(objectInstantiationNode.type);
            objectInstantiationNode.arguments = (objectInstantiationNode.arguments) ? objectInstantiationNode.arguments.map(argument => this.process(argument)) : null;
            objectInstantiationNode.initializedProperties =
                (objectInstantiationNode.initializedProperties) ?
                    Map.remap(
                        objectInstantiationNode.initializedProperties,
                        (name, value) => this.process(value)) :
                    null;
            return objectInstantiationNode;
        }

        protected processParameterReference(parameterReferenceNode: ParameterReferenceNode): CodeNode
        {
            return parameterReferenceNode;
        }
        
        protected processStatementBlock(statementBlockNode: StatementBlockNode): CodeNode
        {
            statementBlockNode.statements = statementBlockNode.statements.map(statement => this.process(statement));
            return statementBlockNode;
        }

        protected processThisReference(thisReferenceNode: ThisReferenceNode): CodeNode
        {
            return thisReferenceNode;
        }

        protected processTypeReference(typeReferenceNode: TypeReferenceNode): TypeReferenceNode
        {
            return typeReferenceNode;
        }

        protected processUnaryOperation(unaryOperationNode: UnaryOperationNode): CodeNode
        {
            unaryOperationNode.operand = this.process(unaryOperationNode.operand);
            return unaryOperationNode;
        }

        protected processVariableReference(variableReferenceNode: VariableReferenceNode): CodeNode
        {
            return variableReferenceNode;
        }

        //#endregion
    }
}
