
declare module _Native
{
    type _Attr = Attr;
    type _Document = Document;
    type _Element = Element;
    type _Node = Node;
    type _Text = Text;
}

module Native
{
    export type Attr = _Native._Attr;
    export var Attr: {
        prototype: Attr;
        new (): Attr;
    };
    
    export type Document = _Native._Document;
    export var Document: {
        prototype: Document;
        new(): Document;
    };
    
    export type Element = _Native._Element;
    export var Element: {
        prototype: Element;
        new (): Element;
    };
    
    export type Node = _Native._Node;
    export type NodeConstructor =
    {
        prototype: Node;
        new (): Node;
        readonly ATTRIBUTE_NODE: number;
        readonly CDATA_SECTION_NODE: number;
        readonly COMMENT_NODE: number;
        readonly DOCUMENT_FRAGMENT_NODE: number;
        readonly DOCUMENT_NODE: number;
        readonly DOCUMENT_POSITION_CONTAINED_BY: number;
        readonly DOCUMENT_POSITION_CONTAINS: number;
        readonly DOCUMENT_POSITION_DISCONNECTED: number;
        readonly DOCUMENT_POSITION_FOLLOWING: number;
        readonly DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC: number;
        readonly DOCUMENT_POSITION_PRECEDING: number;
        readonly DOCUMENT_TYPE_NODE: number;
        readonly ELEMENT_NODE: number;
        readonly ENTITY_NODE: number;
        readonly ENTITY_REFERENCE_NODE: number;
        readonly NOTATION_NODE: number;
        readonly PROCESSING_INSTRUCTION_NODE: number;
        readonly TEXT_NODE: number;
    };
    export var Node: NodeConstructor;
    
    export type Text = _Native._Text;
    export var Text: {
        prototype: Text;
        new (): Text;
    };
}

Native.Attr = Attr;
Native.Document = Document;
Native.Element = Element;
Native.Node = Node;
Native.Text = Text;
