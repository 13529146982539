///<reference path="../Core/Interop.ts" />
///<reference path="../Native/DOM.ts" />
///<reference path="../Xml/Node.ts" />
///<reference path="../Xml/Element.ts" />
///<reference path="Node.ts" />
///<reference path="HTMLElement.ts" />

namespace Sparks.DOM
{
    export interface IHTMLScriptElementExtension extends IHTMLElementExtension
    {
        //#region Methods
        
        //#endregion
    }
    
    export class HTMLScriptElementExtension
    {
        //#region Public Methods

        //#endregion
    }
    
    export interface HTMLScriptElementConstructor extends IHTMLScriptElementExtension
    {
        prototype: HTMLScriptElement;
        new (): HTMLScriptElement;
    }

    export type HTMLScriptElement = Native.HTMLScriptElement;

    export var HTMLScriptElement: HTMLScriptElementConstructor =
        Interop.extend(
            Native.HTMLScriptElement,
            [
                Sparks.Xml.NodeExtension,
                Sparks.Xml.ElementExtension,
                Sparks.DOM.NodeExtension,
                Sparks.DOM.HTMLElementExtension,
                Sparks.DOM.HTMLScriptElementExtension
            ]);
}
