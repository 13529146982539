///<reference path="../Core/Interop.ts" />
///<reference path="../Native/DOM.ts" />
///<reference path="../Xml/Document.ts" />
///<reference path="../Xml/Node.ts" />
///<reference path="Node.ts" />

namespace Sparks.DOM
{
    export interface IHTMLDocumentExtension extends Sparks.DOM.INodeExtension
    {
        //#region Methods

        convertHtmlToText(html: string): string;
        executeWhenReady($document: Document, eventHandler: Function, delay?: number): void;
        loadHtml($document: HTMLDocument, html: string): Node[];
        getOwnerWindow($document: HTMLDocument): Window;
        getDocumentBodyOffset(): Point;
        getNodes(start: Position, end: Position): Node[];
        getNodes(start: Position, end: Position, predicate: (node: Node) => boolean): Node[];
        walk(start: Position, end: Position, handler: (node: Node, enter?: boolean) => boolean): void;

        //#endregion
    }

    /// [NoReflection]
    export class HTMLDocumentExtension
    {
        //#region Public Methods

        public static convertHtmlToText(html: string): string
        {
            var element = document.createElement("div");
            element.innerHTML = html;
            return element.innerText;
        }

        public static executeWhenReady($document: Document, handler: Function, delay?: number): void
        {
            var deferredHandler =
                (arguments.length > 2) ?
                    () => setTimeout(handler, delay || 0) :
                    handler;

            if ($document.readyState !== "loading")
                deferredHandler();
            else
                $document.addEventListener("DOMContentLoaded", () => deferredHandler(), { once: true });
        }

        public static loadHtml($document: HTMLDocument, html: string): Node[]
        {
            var fragment: Node[] = [];
            
            var fragmentNode = $document.createElement("div");
            fragmentNode.innerHTML = html;

            while (fragmentNode.childNodes.length > 0)
            {
                var node = Node.remove(fragmentNode.firstChild);
                var clone = Node.clone(node, true); // allows executable script nodes, which are skipped otherwise when inserted in DOM
                fragment.push(clone);
            }
            
            return fragment;
        }

        public static getOwnerWindow($document: HTMLDocument): Window
        {
            return $document.defaultView;
        }

        public static getDocumentBodyOffset(): Point
        {
            var offset = document.body.getBoundingClientRect();
            var documentElement = document.documentElement;
            var body = document.body;

            var result = { left: offset.left, top: offset.top };
            result.left += (documentElement && documentElement.scrollLeft || body && body.scrollLeft || 0);
            result.top += (documentElement && documentElement.scrollTop || body && body.scrollTop || 0);

            return result;
        }

        public static getNodes = Sparks.Xml.DocumentExtension.getNodes;

        public static walk = Sparks.Xml.DocumentExtension.walk;

        //#endregion
    }

    /// [NoReflection]
    export interface HTMLDocumentConstructor extends IHTMLDocumentExtension
    {
        prototype: HTMLDocument;
        new (): HTMLDocument;
    }

    export type HTMLDocument = Native.HTMLDocument;

    export var HTMLDocument: HTMLDocumentConstructor =
        Interop.extend(
            Native.HTMLDocument,
            [
                Sparks.Xml.NodeExtension,
                Sparks.DOM.NodeExtension,
                Sparks.DOM.HTMLDocumentExtension
            ]);
}
