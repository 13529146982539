
namespace Sparks.Code
{
    export interface ObjectInstantiationNode extends ExpressionNode
    {
        //#region Properties

        type: ExpressionNode;
        arguments: ExpressionNode[];
        initializedProperties: Map<ExpressionNode>;

        //#endregion
    }
}
