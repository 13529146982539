
namespace Sparks.Code
{
    export interface MethodInvocationNode extends ExpressionNode
    {
        //#region Properties

        method: ExpressionNode;
        arguments: ExpressionNode[];

        //#endregion
    }
}
