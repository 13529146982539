
namespace Simpleo.CustomerPortal
{
    export class InvoiceManagementService extends Sparks.Net.WebService
    {
        //#region Constructor

        public constructor(serviceUrl: string)
        {
            super(serviceUrl);
        }

        //#endregion


        //#region Public Methods

        public async getInvoices(): Promise<InvoiceInfo[]>
        {
            var responseData = await this.invoke<any[]>("GET", "/");
            var invoices = responseData.map(item => InvoiceInfo.serializer.deserialize(item));
            return invoices;
        }

        public payInvoice(invoiceNumber: string, cardHolder: string, cardNumber: string, expiryMonth: number, expiryYear: number, chargedAmount: number): Promise<void>
        {
            return this.invoke("POST", "/" + invoiceNumber + "/payment", { cardHolder: cardHolder, cardNumber: cardNumber, expiryMonth: expiryMonth, expiryYear: expiryYear, chargedAmount: chargedAmount });
        }
                
        //#endregion
    }
}
