/// <reference path="CodeGenerator.ts" />
/// <reference path="ExpressionGenerator.ts" />

namespace Sparks.Code
{
    export class Compiler
    {
        //#region Public Methods
        
        public static compileExpression<TValue>(epxressionCode: string): CompileResult<Expression<TValue>>;
        public static compileExpression<TValue>(expressionNode: ExpressionNode): CompileResult<Expression<TValue>>;
        public static compileExpression<TValue>(expressionCodeOrNode: string | ExpressionNode): CompileResult<Expression<TValue>>
        {
            var expressionCode = (String.isString(expressionCodeOrNode)) ? expressionCodeOrNode : Compiler.codeGenerator.process(expressionCodeOrNode);
            var expressionNode = (String.isString(expressionCodeOrNode)) ? this.parseExpression(expressionCodeOrNode).result : expressionCodeOrNode;
            
            var evaluationExpressionNode = Compiler.expressionGenerator.process(expressionNode);
            var evaluationExpressionCode = Compiler.codeGenerator.process(evaluationExpressionNode);
            var evaluationFunctionCode = "(function($context) { return " + evaluationExpressionCode + "; })";
            var evaluate = eval(evaluationFunctionCode);

            if (!CodeNode.isAssignable(expressionNode))
                return { result: new Expression(evaluate, null, expressionCode), sourceMap: null };

            var expressionAssignmentNode = CodeNode.createAssignment(expressionNode, CodeNode.createParameterReference("$value"));

            var assignmentNode = Compiler.expressionGenerator.process(expressionAssignmentNode);
            var assignmentCode = Compiler.codeGenerator.process(assignmentNode);
            var assignmentFunctionCode = "(function($value, $context) { " + assignmentCode + "; })";
            var assign = eval(assignmentFunctionCode);

            return { result: new Expression(evaluate, assign, expressionCode), sourceMap: null };
        }

        public static parse(code: string): ParseResult<StatementBlockNode>
        {
            var program = esprima.parse(code, { loc: true });
            var context: Compiler.Context = { source: null, parseInfo: new Native.Map<CodeNode, ParseInfo>(), enclosingDeclarations: [] };
            var result = Compiler.convert(program, context);
            return { result: result, parseInfo: context.parseInfo };
        }

        public static parseExpression(expressionCode: string): ParseResult<ExpressionNode>
        {
            var parseResult = Compiler.parse("(" + expressionCode + ")");
            var statement = (parseResult.result.statements.length == 1) ? parseResult.result.statements[0] : null;
            var expression = CodeNode.isExpressionStatement(statement) ? statement.expression : null;
            if (!expression)
                throw new Error("Can't parse expression '" + expressionCode + "'");
            return { result: expression, parseInfo: null };
        }

        //#endregion


        //#region Private Properties

        private static readonly expressionGenerator = new Sparks.Code.Compilation.ExpressionGenerator();
        private static readonly codeGenerator = new Sparks.Code.Compilation.CodeGenerator();
        //private static readonly functionBuilder = new FunctionBuilder();

        //#endregion
        

        //#region Private Methods

        private static convert(program: esprima.Syntax.Program, context: Compiler.Context): StatementBlockNode;
        private static convert(expression: esprima.Syntax.Expression, context: Compiler.Context): ExpressionNode;
        private static convert(statement: esprima.Syntax.Statement, context: Compiler.Context): StatementNode;
        private static convert(syntaxNode: esprima.Syntax.Node, context: Compiler.Context): CodeNode;
        private static convert(syntaxNode: esprima.Syntax.Node, context: Compiler.Context): CodeNode
        {
            // Resolver converter
            var nodeConverter = Compiler.nodeConverters[syntaxNode.type];
            if (!nodeConverter)
                throw new Error("Unsupported syntax node type: " + syntaxNode.type);

            // Create code node
            var codeNode = nodeConverter.apply(this, [syntaxNode, context]);

            // Assign parse info
            context.parseInfo.set(codeNode, syntaxNode.loc.start);

            // Return codeNode
            return codeNode;
        }

        private static convertArrayExpression(syntaxNode: esprima.Syntax.ArrayExpression, context: Compiler.Context): ArrayInstantiationNode
        {
            return CodeNode.createArrayInstantiation(syntaxNode.elements.map(element => this.convert(element, context)));
        }

        private static convertArrowFunctionExpression(syntaxNode: esprima.Syntax.ArrowFunctionExpression, context: Compiler.Context): LambdaExpressionNode
        {
            if (!syntaxNode.expression)
                throw new Error("Not implemented");

            var parameters = syntaxNode.params.map(param => param.name);
            context.enclosingDeclarations.push({ parameters: parameters });
            var lambdaExpression = CodeNode.createLambdaExpression(this.convert(syntaxNode.body, context), parameters);
            context.enclosingDeclarations.pop();

            return lambdaExpression;
        }

        private static convertAssignmentExpression(syntaxNode: esprima.Syntax.AssignmentExpression, context: Compiler.Context): AssignmentExpressionNode
        {
            return CodeNode.createAssignment(this.convert(syntaxNode.left, context), this.convert(syntaxNode.right, context));
        }

        private static convertBinaryExpression(syntaxNode: esprima.Syntax.BinaryExpression, context: Compiler.Context): BinaryOperationNode
        {
            return CodeNode.createBinaryOperation(
                this.convert(syntaxNode.left, context),
                Compiler.binaryOperators[syntaxNode.operator],
                this.convert(syntaxNode.right, context));
        }

        private static convertBlockStatement(syntaxNode: esprima.Syntax.BlockStatement, context: Compiler.Context): StatementBlockNode
        {
            return CodeNode.createStatementBlock(syntaxNode.body.map(statement => <StatementNode>this.convert(statement, context)));
        }

        private static convertCallExpression(syntaxNode: esprima.Syntax.CallExpression, context: Compiler.Context): MethodInvocationNode
        {
            return CodeNode.createMethodInvocation(
                this.convert(syntaxNode.callee, context),
                syntaxNode.arguments.map(argument => this.convert(argument, context)));
        }

        private static convertConditionalExpression(syntaxNode: esprima.Syntax.ConditionalExpression, context: Compiler.Context): ConditionExpressionNode
        {
            return CodeNode.createConditionExpression(
                this.convert(syntaxNode.test, context),
                this.convert(syntaxNode.consequent, context),
                this.convert(syntaxNode.alternate, context));
        }

        private static convertExpressionStatement(syntaxNode: esprima.Syntax.ExpressionStatement, context: Compiler.Context): ExpressionStatementNode
        {
            return CodeNode.createExpressionStatement(this.convert(syntaxNode.expression, context));
        }

        private static convertFunctionExpression(syntaxNode: esprima.Syntax.FunctionExpression, context: Compiler.Context): CodeNode
        {
            throw new Error("Not implemented");
        }

        private static convertIdentifier(syntaxNode: esprima.Syntax.Identifier, context: Compiler.Context): VariableReferenceNode | ParameterReferenceNode
        {
            if (context.enclosingDeclarations.some(declaration => declaration.parameters.some(parameter => syntaxNode.name == parameter)))
                return CodeNode.createParameterReference(syntaxNode.name);
            else
                return CodeNode.createVariableReference(syntaxNode.name);
        }

        private static convertIndexer(syntaxNode: esprima.Syntax.MemberExpression, context: Compiler.Context): IndexerNode
        {
            return CodeNode.createIndexer(this.convert(syntaxNode.object, context), this.convert(syntaxNode.property, context));
        }

        private static convertLiteral(syntaxNode: esprima.Syntax.Literal, context: Compiler.Context): LiteralValueNode
        {
            return CodeNode.createLiteral(syntaxNode.value);
        }

        private static convertLogicalExpression(syntaxNode: esprima.Syntax.LogicalExpression, context: Compiler.Context): BinaryOperationNode
        {
            return CodeNode.createBinaryOperation(this.convert(syntaxNode.left, context), Compiler.binaryOperators[syntaxNode.operator], this.convert(syntaxNode.right, context));
        }

        private static convertMemberExpression(syntaxNode: esprima.Syntax.MemberExpression, context: Compiler.Context): MemberReferenceNode | TypeReferenceNode | IndexerNode
        {
            // Indexer
            if (syntaxNode.computed)
                return this.convertIndexer(syntaxNode, context);

            // Type Reference
            var path = this.getMemberPath(syntaxNode);
            if (path)
            {
                var typeName = path.join('.');
                var type = AppDomain.currentDomain.getType(typeName);
                if (type)
                    return CodeNode.createTypeReference(typeName);
            }

            // Instance member reference
            return CodeNode.createMemberReference(this.convert(syntaxNode.object, context), syntaxNode.property.name);
        }
        
        private static convertNewExpression(syntaxNode: esprima.Syntax.NewExpression, context: Compiler.Context): ObjectInstantiationNode
        {
            var path = this.getMemberPath(syntaxNode.callee);
            if (!path)
                throw new Error("Unsupported expression for new operator");

            var typeName = path.join('.');

            return CodeNode.createObjectInstantiation(
                CodeNode.createTypeReference(typeName),
                syntaxNode.arguments.map(argument => this.convert(argument, context)),
                null);
        }

        private static convertObjectExpression(syntaxNode: esprima.Syntax.ObjectExpression, context: Compiler.Context): ObjectInstantiationNode
        {
            return CodeNode.createObjectInstantiation(
                CodeNode.createTypeReference(Compiler.ObjectTypeName),
                null,
                syntaxNode.properties ?
                    Array.toMap(
                        syntaxNode.properties,
                        property => property.key.name || property.key.value,
                        property => this.convert(property.value, context)) :
                    null);
        }

        private static convertProgram(syntaxNode: esprima.Syntax.Program, context: Compiler.Context): StatementBlockNode
        {
            var codeNodes = syntaxNode.body.map(statement => this.convert(statement, context));
            var statements = codeNodes.map(node => (!CodeNode.isStatement(node)) ? CodeNode.createExpressionStatement(node) : node);
            return CodeNode.createStatementBlock(statements);
        }

        private static convertSequenceExpression(syntaxNode: esprima.Syntax.SequenceExpression, context: Compiler.Context): ExpressionSequenceNode
        {
            var expressions = syntaxNode.expressions.map(expression => this.convert(expression, context));
            return CodeNode.createExpressionSequence(expressions);
        }

        private static convertThisExpression(syntaxNode: esprima.Syntax.ThisExpression, context: Compiler.Context): ThisReferenceNode
        {
            return CodeNode.createThisReference();
        }

        private static convertUnaryExpression(syntaxNode: esprima.Syntax.UnaryExpression, context: Compiler.Context): UnaryOperationNode
        {
            return CodeNode.createUnaryOperation(this.convert(syntaxNode.argument, context), Compiler.unaryOperators[syntaxNode.operator]);
        }

        private static getMemberPath(syntaxNode: esprima.Syntax.Expression): string[]
        {
            if (syntaxNode.type == "Identifier")
                return [(<esprima.Syntax.Identifier>syntaxNode).name];

            if (syntaxNode.type == "MemberExpression" && !(<esprima.Syntax.MemberExpression>syntaxNode).computed)
            {
                var path = this.getMemberPath((<esprima.Syntax.MemberExpression>syntaxNode).object);
                if (!path)
                    return null;
                path.push((<esprima.Syntax.MemberExpression>syntaxNode).property.name);
                return path;
            }

            return null;
        }

        //#endregion


        //#region Private Constants

        private static nodeConverters: Map<(syntaxNode: esprima.Syntax.Node, context: Compiler.Context) => CodeNode> =
        {
            "ArrayExpression": Compiler.convertArrayExpression,
            "ArrowFunctionExpression": Compiler.convertArrowFunctionExpression,
            "AssignmentExpression": Compiler.convertAssignmentExpression,
            "BinaryExpression": Compiler.convertBinaryExpression,
            "BlockStatement": Compiler.convertBlockStatement,
            "CallExpression": Compiler.convertCallExpression,
            "ConditionalExpression": Compiler.convertConditionalExpression,
            "ExpressionStatement": Compiler.convertExpressionStatement,
            "FunctionExpression": Compiler.convertFunctionExpression,
            "Identifier": Compiler.convertIdentifier,
            "Literal": Compiler.convertLiteral,
            "LogicalExpression": Compiler.convertLogicalExpression,
            "MemberExpression": Compiler.convertMemberExpression,
            "NewExpression": Compiler.convertNewExpression,
            "ObjectExpression": Compiler.convertObjectExpression,
            "Program": Compiler.convertProgram,
            "SequenceExpression": Compiler.convertSequenceExpression,
            "ThisExpression": Compiler.convertThisExpression,
            "UnaryExpression": Compiler.convertUnaryExpression
        };

        private static binaryOperators: Map<BinaryOperator> =
        {
            "+": BinaryOperator.Add,
            "+=": BinaryOperator.AddAssign,
            "&": BinaryOperator.BitwiseAnd,
            "|": BinaryOperator.BitwiseOr,
            "^": BinaryOperator.BitwiseXor,
            "/": BinaryOperator.Divide,
            "/=": BinaryOperator.DivideAssign,
            "==": BinaryOperator.Equal,
            ">=": BinaryOperator.GreaterOrEqual,
            ">": BinaryOperator.Greater,
            "<": BinaryOperator.Less,
            "<=": BinaryOperator.LessOrEqual,
            "&&": BinaryOperator.LogicalAnd,
            "||": BinaryOperator.LogicalOr,
            "^^": BinaryOperator.LogicalXor,
            "%": BinaryOperator.Modulo,
            "*": BinaryOperator.Multiply,
            "*=": BinaryOperator.MultiplyAssign,
            "!=": BinaryOperator.NotEqual,
            "===": BinaryOperator.StrictEqual,
            "!==": BinaryOperator.StrictNotEqual,
            "-": BinaryOperator.Substract,
            "-=": BinaryOperator.SubstractAssign
        };

        private static unaryOperators: Map<UnaryOperator> =
        {
            "--": UnaryOperator.Decrement,
            "++": UnaryOperator.Increment,
            "-": UnaryOperator.Negate,
            "!": UnaryOperator.Not
        };

        private static ObjectTypeName = "Object";

        //#endregion
    }

    module Compiler
    {
        export interface Context
        {
            //#region Properties

            source: CompilationSource;
            parseInfo: Native.Map<CodeNode, ParseInfo>;
            enclosingDeclarations: EnclosingDeclaration[];

            //#endregion
        }

        export interface EnclosingDeclaration
        {
            //#region Properties

            name?: string;
            parameters: string[];

            //#endregion
        }
    }
}
