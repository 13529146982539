
namespace Sparks.Apps.UI
{
    export class Scroller
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        public initialize(viewSize: () => number, totalSize: () => number): void
        {
            this._viewSize = viewSize;
            this._totalSize = totalSize;
        }

        public scrollTo(position: number, silent?: boolean): void
        {
            var min = this.viewableSize / 2;

            this.position = Math.max(position, min);
            this.position = Math.min(this.position, 1 - min);

            this.viewPosition = this.position - (this.viewableSize / 2);

            if (!silent)
                this.updated.invoke(this);
        }

        public update(silent?: boolean): void
        {
            var position = this.position;

            var viewSize = (this._viewSize) ? this._viewSize() : 1;
            var totalSize = Math.max((this._totalSize) ? this._totalSize() : 1, viewSize);
            this.viewableSize = Math.min(viewSize / totalSize, 1)

            this.scrollTo(position, true);

            if (!silent)
                this.updated.invoke(this);
        }

        //#endregion


        //#region Public Events

        public updated = new Sparks.Event();

        //#endregion


        //#region Public Properties

        public position: number = 0.5;
        public viewPosition: number = 0; 
        public viewableSize: number = 1;

        //#endregion


        //#region Private Fields

        private _viewSize: () => number = null;
        private _totalSize: () => number = null;

        //#endregion
    }
}