
namespace Simpleo.CustomerPortal
{
    export interface IService
    {
        //#region Public Methods

        initialize?: (services: Application.ServiceProvider) => void;

        //#endregion
    }
}
