
namespace Sparks.Code
{
    export class FunctionBuilder
    {
        //#region Public Methods
        
        public static build(code: string): Function
        {
            throw new Error("Not implemented");
        }
        
        //#endregion
    }
}
