
namespace Sparks.Xml
{
    export interface Attribute
    {
        //#region Properties

        name: string;
        value: string;

        //#endregion
    }
}