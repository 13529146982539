
declare module _Native
{
    type _Error = Error;
    type _ErrorConstructor = ErrorConstructor;
}

module Native
{
    export type Error = _Native._Error;
    export var Error: _Native._ErrorConstructor;
    export type ErrorConstructor = _Native._ErrorConstructor;
}

Native.Error = Error;
