
namespace Sparks
{
    export interface Position
    {
        //#region Properties

        node: Node;
        offset: number;

        //#endregion
    }
}