
declare module _Native
{
    type _Object = Object;
    type _ObjectConstructor = ObjectConstructor;
}

module Native
{
    export type Object = _Native._Object;
    export var Object: _Native._ObjectConstructor;
    export type ObjectConstructor = _Native._ObjectConstructor;
}

Native.Object = Object;
