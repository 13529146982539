///<reference path="Viewer.ts" />

namespace Sparks.Apps.Components
{
    export abstract class Editor<TObject> extends Viewer<TObject>
    {
        //#region Constructor

        public constructor()
        {
            super();
        }

        //#endregion
        

        //#region Protected Methods

        protected async save(): Promise<TObject>
        {
            this.item = await this.runTask(this.store(this.item));
            this.updated.invoke(this);
            return this.item;
        }
                
        protected abstract store(item: TObject): Promise<TObject>;

        //#endregion
    }
}
