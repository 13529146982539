
namespace Sparks.UI
{
    export class InitDirective extends Directive
    {
        //#region Constructor

        public constructor(initialization: Expression<any>)
        {
            super();
            this.initialization = initialization;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: ActivationContext): void
        {
            activationContext.activated.add(
                () =>
                {
                    var locals = { "$node": activationContext.target, "$scope": activationContext.scope };
                    var initialization = ExpressionCompiler.bindLocals(this.initialization, locals);
                    initialization.evaluate(activationContext.scope.locals);
                });
        }

        //#endregion


        //#region Public Properties

        public initialization: Expression<any>;

        //#endregion
    }

    export namespace InitDirective
    {
        export class InitDirectiveCompiler extends BaseAttributeDirectiveCompiler
        {
            public constructor()
            {
                super("sx-init");
            }
            
            public compile(node: HTMLElement, compilationContext: CompilationContext): Directive
            {
                var initialization = this.compileArguments<any>(node);
                return new InitDirective(initialization);
            }
        }
    }
}