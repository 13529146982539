
namespace Sparks
{
    export interface Point
    {
        //#region Properties

        left: number;
        top: number;

        //#endregion
    }
}
