
namespace Sparks.Apps
{
    export interface IService
    {
        //#region Methods

        initialize?: (services: IServiceProvider) => Promise<void> | void;

        //#endregion
    }
}
