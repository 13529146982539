
namespace Sparks
{
    export interface EventArgs
    {
        //#region Properties
        

        //#endregion
    }
}