
namespace Sparks.Apps.Forms
{
    export class FormSubmitDirective extends Sparks.UI.Directive
    {
        //#region Constructors

        public constructor(onSubmit: Expression<any>)
        {
            super();
            this.onSubmit = onSubmit;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: Sparks.UI.ActivationContext): void
        {
            var formDirective = Sparks.Array.ofType(activationContext.directives, FormDirective)[0];
            if (!formDirective)
                throw new Error("Can't locate " + FormDirective.name);

            var formController = Object.as(FormController, activationContext.scope.get(formDirective.formName));
            if (!formController)
                throw new Error("Can't locate " + FormController.name);

            var handler =
                eventArgs =>
                {
                    var ret;

                    if (this.onSubmit)
                    {
                        var locals = new Sparks.UI.LocalContext({ "$node": activationContext.target, "$data": formController.getData() }, [activationContext.scope.locals], activationContext.scope.locals);
                        ret = this.onSubmit.evaluate(locals);
                    }

                    activationContext.scope.update();

                    return ret;
                };

            formController.submitting.add(handler);
        }

        //#endregion


        //#region Public Properties

        public onSubmit: Expression<any>;

        //#endregion
    }

    export namespace FormSubmitDirective
    {
        export class FormSubmitDirectiveCompiler extends Sparks.UI.BaseAttributeDirectiveCompiler
        {
            //#region Constructor

            public constructor()
            {
                super("sx-form-submit");
            }

            //#endregion


            //#region Public Methods

            public compile(node: HTMLElement, compilationContext: Sparks.UI.CompilationContext): Sparks.UI.Directive
            {
                var onSubmit = this.compileArguments<any>(node);
                return new FormSubmitDirective(onSubmit);
            }

            //#endregion
        }
    }
}