
namespace Simpleo.CustomerPortal
{
    // TODO: consider ServiceManager
    export class ServiceViewer implements IService
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        public initialize(services: Application.ServiceProvider): void
        {
            this.serviceManagementService = services.serviceManagementService;
            this.serviceManager = services.serviceManager;
            this.paymentProcessor = services.paymentProcessor;
            this.uiManager = services.uiManager;
            this.routingManager = services.routingManager;
        }

        public async load(service: ServiceInfo): Promise<void>;
        public async load(number: string): Promise<void>;
        public async load(serviceOrNumber: ServiceInfo | string): Promise<void>
        {
            if (Sparks.String.isString(serviceOrNumber))
            {
                var retrieval = this._serviceTask = this.serviceManager.getService(serviceOrNumber);

                this.updated.invoke(this);

                try
                {
                    this.service = await retrieval;
                }
                finally
                {
                    this._serviceTask = null;
                    this.updated.invoke(this);
                }
            }
            else
            {
                this.service = serviceOrNumber;
                this.updated.invoke(this);
            }
        }

        public async renewService(renewalLength: number | string): Promise<RenewalInfo>
        {
            if (!this.service)
                throw new Error("No service loaded");

            if (this._serviceTask)
                throw new Error("Request already under progress");

            try
            {
                var renewalTask = this._serviceTask = this.serviceManagementService.renewService(this.service.number, Sparks.Number.parse(renewalLength));

                this.updated.invoke(this);

                this.service.renewal = await renewalTask;
                this.serviceManager.updateService(this.service);
            }
            finally
            {
                this._serviceTask = null;
                this.updated.invoke(this);
            }

            return this.service.renewal ;
        }

        public async cancelRenewal(): Promise<void>
        {
            if (!this.service)
                throw new Error("No service loaded");

            if (this._serviceTask)
                throw new Error("Request already under progress");

            var confirmed = await this.uiManager.confirm(
                "Renewal cancellation",
                "Are you sure you want to cancel your service renewal?",
                "Yes",
                "No");

            if (confirmed)
            {
                try
                {
                    var cancellationTask = this._serviceTask = this.serviceManagementService.cancelRenewal(this.service.number);

                    this.updated.invoke(this);

                    await cancellationTask;
                    this.service.renewal = null;
                    this.serviceManager.updateService(this.service);
                }
                finally
                {
                    this._serviceTask = null;
                    this.updated.invoke(this);
                }
            }
        }

        public async payRenewal(): Promise<void>
        {
            if (!this.service)
                throw new Error("No service loaded");

            try
            {
                var paymentProcessing = this._serviceTask = this.paymentProcessor.processRenewalPayment(this.service.renewal);

                this.updated.invoke(this);

                var completed = await paymentProcessing;
                if (completed)
                {
                    this.service.expiry = this.service.renewal.expiry;
                    this.service.renewal = null;
                    this.serviceManager.updateService(this.service);
                }
            }
            finally
            {
                this._serviceTask = null;
                this.updated.invoke(this);
            }
        }

        public isRenewing(): boolean
        {
            return !!this.service && !!this.service.renewal;
        }
        
        public isWaiting(): boolean
        {
            return !!this._serviceTask;
        }

        public exit(): void
        {
            this.routingManager.update({ path: "" });
        }

        //#endregion


        //#region Public Events

        public updated = new Sparks.Event();
        
        //#endregion


        //#region Public Properties

        public service: ServiceInfo = null;
        
        //#endregion
        

        //#region Protected Properties

        protected serviceManagementService: ServiceManagementService;
        protected serviceManager: ServiceManager;
        protected paymentProcessor: PaymentProcessor;
        protected uiManager: UIManager;
        protected routingManager: RoutingManager;

        //#endregion


        //#region Private Fields

        private _serviceTask: Promise<any> = null;
                
        //#endregion
    }
}
