
namespace Sparks.Net
{
    export class InvocationError extends Error
    {
        //#region Constructor

        public constructor(message: string, request: Http.Request, response: Http.Response, innerError?: Error)
        {
            super(message, innerError);
            this.request = request;
            this.response = response;
        }

        //#endregion


        //#region Public Methods

        public request: Http.Request;
        public response: Http.Response;
        
        //#endregion
    }
}
