
declare interface Array<T>
{
    find(predicate: (value: T, index: number, obj: Array<T>) => boolean, thisArg?: any): T | undefined;
}

declare module _Native
{
    type _Array<T> = Array<T>;
    type _ArrayConstructor = ArrayConstructor;
}

module Native
{
    export type Array<T> = _Native._Array<T>;
    export var Array: _Native._ArrayConstructor;
    export type ArrayConstructor = _Native._ArrayConstructor;
}

Native.Array = Array;
