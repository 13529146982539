
declare module _Native
{
    type _Date = Date;
    type _DateConstructor = DateConstructor;
}

module Native
{
    export type Date = _Native._Date;
    export var Date: _Native._DateConstructor;
    export type DateConstructor = _Native._DateConstructor;
}

Native.Date = Date;
