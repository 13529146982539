
namespace Sparks.UI
{
    export class Template
    {
        //#region Constructor

        public constructor(directives: Directive[])
        {
            this.directives = directives;
        }

        //#endregion
        

        //#region Private Fields

        public directives: Directive[];

        //#endregion
    }
}