///<reference path="../Core/Directive.ts" />
///<reference path="../Directives/AttrDirective.ts" />
///<reference path="../Directives/ClassDirective.ts" />
///<reference path="../Directives/ClickDirective.ts" />
///<reference path="../Directives/DebugDirective.ts" />
///<reference path="../Directives/EditableDirective.ts" />
///<reference path="../Directives/ElementDirective.ts" />
///<reference path="../Directives/EvalDirective.ts" />
///<reference path="../Directives/EventDirective.ts" />
///<reference path="../Directives/ForeachDirective.ts" />
///<reference path="../Directives/IfDirective.ts" />
///<reference path="../Directives/InitDirective.ts" />
///<reference path="../Directives/InstanceDirective.ts" />
///<reference path="../Directives/ListenDirective.ts" />
///<reference path="../Directives/ModelDirective.ts" />
///<reference path="../Directives/MutationDirective.ts" />
///<reference path="../Directives/ScopeDirective.ts" />
///<reference path="../Directives/StyleDirective.ts" />
///<reference path="../Directives/TemplateDirective.ts" />
///<reference path="../Directives/TextDirective.ts" />
///<reference path="../Directives/TextNodeDirective.ts" />

namespace Sparks.UI
{
    export class Compiler
    {
        //#region Constructor

        public constructor(directiveCompilers: DirectiveCompiler[])
        {
            this.load(directiveCompilers);
        }

        //#endregion


        //#region Public Methods

        public load(directiveCompilers: DirectiveCompiler[])
        {
            this.directiveCompilers = this.directiveCompilers.concat(directiveCompilers);
        }

        public compile(nodes: Node[]): Template
        {
            nodes = nodes.slice();

            var directives: Directive[] = [];

            for (var node = nodes.shift(); node; node = nodes.shift())
            {
                var nodeDirectives = this.compileNode(node, true);
                directives = directives.concat(nodeDirectives);
            }

            return new Template(directives);
        }

        public compileNode(node: Node): Directive[];
        public compileNode(node: Node, instantiates: boolean): Directive[];
        public compileNode(node: Node, compilationContext: CompilationContext): Directive[];
        public compileNode(node: Node, instantiatesOrContext?: boolean | CompilationContext): Directive[]
        {
            var compilationContext =
                (instantiatesOrContext instanceof CompilationContext) ?
                    instantiatesOrContext :
                    new CompilationContext(this);
            if (Boolean.isBoolean(instantiatesOrContext))
                compilationContext.instantiates = instantiatesOrContext;
            compilationContext.queue = compilationContext.queue || this.directiveCompilers.filter(directive => directive.canCompile(node));

            for (var next = compilationContext.queue.shift(); next; next = compilationContext.queue.shift())
            {
                var directive = next.compile(node, compilationContext);
                if (!compilationContext.directives.includes(directive))
                    compilationContext.directives.push(directive);
            }

            return compilationContext.directives;
        }

        //#endregion


        //#region Public Properties

        public directiveCompilers: DirectiveCompiler[] = [];            

        public static defaultCompiler = new Compiler([
            new DebugDirective.DebugDirectiveCompiler(),
            new TemplateDirective.TemplateDirectiveCompiler(),
            new IfDirective.IfDirectiveCompiler(),
            new ForeachDirective.ForeachDirectiveCompiler(),
            new InstanceDirective.InstanceDirectiveCompiler(),
            new MutationDirective.MutationDirectiveCompiler(),
            new ElementDirective.ElementDirectiveCompiler(),
            new TextNodeDirective.TextNodeDirectiveCompiler(),
            new ScopeDirective.ScopeDirectiveCompiler(),
            new EvalDirective.EvalDirectiveCompiler(),
            new InitDirective.InitDirectiveCompiler(),
            new AttrDirective.AttrDirectiveCompiler(),
            new TextDirective.TextDirectiveCompiler(),
            new ClassDirective.ClassDirectiveCompiler(),
            new StyleDirective.StyleDirectiveCompiler(),
            new EditableDirective.EditableDirectiveCompiler(),
            new ModelDirective.ModelDirectiveCompiler(),
            new ClickDirective.ClickDirectiveCompiler(),
            new EventDirective.EventDirectiveCompiler(),
            new ListenDirective.ListenDirectiveCompiler()
        ]);

        //#endregion
    }

    export class CompilationContext
    {
        //#region Constructor

        public constructor(compiler: Compiler, directiveCompilers?: DirectiveCompiler[], source?: Sparks.Code.ParseInfo)
        {
            this.compiler = compiler;
            this.queue = directiveCompilers || this.queue || null;
            this.source = source || this.source || null;
        }

        //#endregion


        //#region Public Properties
        
        public compiler: Compiler;
        public queue: DirectiveCompiler[];
        public directives: Directive[] = [];
        public source: Sparks.Code.ParseInfo;
        public instantiates: boolean = false;

        //#endregion
    }
}