
namespace Sparks.Code
{
    export enum BinaryOperator
    {
        Undefined = 0,

        Add,
        AddAssign,
        BitwiseAnd,
        BitwiseOr,
        BitwiseXor,
        Divide,
        DivideAssign,
        Equal,
        GreaterOrEqual,
        Greater,
        Less,
        LessOrEqual,
        LogicalAnd,
        LogicalOr,
        LogicalXor,
        Modulo,
        Multiply,
        MultiplyAssign,
        NotEqual,
        StrictEqual,
        StrictNotEqual,
        Substract,
        SubstractAssign,
    }
}
