
namespace Sparks.Apps.Routing
{
    export class Route
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        public getArguments(context: IContext): Sparks.Map<string>
        {
            return (this.pathFormat) ? this.pathFormat.getValues(context.path) : null;
        }

        //#endregion


        //#region Public Properties

        public predicate: (context: IContext) => boolean = null;

        public path: string = null;

        public pathFormat: Sparks.StringFormat = null;

        public handlers: ((args: Sparks.Map<string>) => void)[] = null;

        public routes: Route[] = null;

        //#endregion
    }
}
