
module Sparks.Code
{
    export interface ParseInfoProvider
    {
        //#region Public Properties

        source?: string;

        nodes?: Map<ParseInfo>;

        //#endregion
    }
}
