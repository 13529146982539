
namespace Sparks.Apps.UI
{
    export class ViewBuilder
    {
        //#region Constructor

        public constructor(name?: string)
        {
            this.name = name || null;
        }

        //#endregion


        //#region Public Methods

        public useName(name: string): ViewBuilder
        {
            this.name = name;
            return this;
        }

        public addView(name: string, viewConfig?: (viewBuilder: ViewBuilder) => void): ViewBuilder
        {
            var viewBuilder = ViewBuilder.create(name);
            this._builders.push(viewBuilder);
            if (viewConfig)
                viewConfig(viewBuilder);
            return this;
        }

        public build(): View
        {
            var view = new View(this.name);
            this._builders.forEach(
                viewBuilder =>
                {
                    var childView = viewBuilder.build();
                    view.addView(childView);
                });
            return view;
        }

        public static create(name?: string): ViewBuilder
        {
            return new ViewBuilder(name);
        }

        //#endregion


        //#region Public Properties

        public name: string;
        
        //#endregion


        //#region Private Fields

        private _builders: ViewBuilder[] = [];

        //#endregion
    }
}
