///<reference path="../Xml/Iterator.ts" />

namespace Sparks.DOM
{
    export class Iterator extends Sparks.Xml.Iterator
    {
        //#region Constructor

        //public constructor(root: Node)
        //{
        //    this.node = root.parentElement;
        //    this.offset = System.Xml.Node.indexOf(root);
        //}
        
        //#endregion
    }    
}
