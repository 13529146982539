
namespace Sparks
{
    export interface Uri
    {
        scheme?: string;
        user?: string;
        password?: string;
        hostname?: string;
        port?: number;
        path?: string;
        query?: string;
        anchor?: string;
    }

    export interface UriMethods
    {
        parseUrl(url: string): Uri;
        buildUrl(uri: Uri): string;
    }

    export var Uri: UriMethods =
    {
        parseUrl:
            function (url: string): Uri
            {
                var expr = url.match(/^(?:(?:(?:([^:]*)\:)?(?:\/\/)(?:([^@:]*)(?:\:([^@]*))?@)?(([^:\/\?#]*)(?:\:([^\/\?#]*))?))?((?:\/)?[^\?#]*))?(\?[^#]*)?(#.*)?$/i);

                if (!expr)
                    return null;

                var result =
                    {
                        protocol: expr[1],
                        user: expr[2],
                        password: expr[3],
                        host: expr[4],
                        hostname: expr[5],
                        port: expr[6] ? parseInt(expr[6]) : undefined,
                        path: expr[7],
                        query: expr[8],
                        anchor: expr[9]
                    };
                return result;
            },

        buildUrl:
            function (uri: Uri): string
            {
                var url = "";

                if (uri.hostname)
                {
                    if (uri.scheme)
                        url += uri.scheme + ":";
                    url += "//";
                    url += (uri.user || uri.password) ? (uri.user || "") + ":" + (uri.password || "") + "@" : "";
                    url += uri.hostname;
                }
                url += (uri.port && uri.port != 80) ? ":" + uri.port : "";
                url += ((uri.path && !uri.path.startsWith("/")) ? "/" + uri.path : uri.path) || "";
                url += (uri.query) ? "?" + uri.query : "";
                url += (uri.anchor) ? uri.anchor : "";
 
                return url;
            }
    };
}
