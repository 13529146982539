
namespace Sparks
{
    export interface IContext
    {
        //#region Methods
        
        get(name: string): any;

        set(name: string, value: any): void;

        has(name: string): boolean;

        enumerate(): string[];
                
        //#endregion


        //#region Properties

        //parent: IContext;

        //#endregion
    }
}
