
//namespace Simpleo.CustomerPortal
//{
//    export class ConfirmationBox
//    {
//        //#region Constructor

//        public constructor()
//        {
//        }

//        //#endregion


//        //#region Public Methods

//        public confirm(title: string, message: string): Promise<boolean>;
//        public confirm(title: string, message: string, confirm: string, cancel: string): Promise<boolean>;
//        public confirm(title: string, message: string, confirm?: string, cancel?: string): Promise<boolean>
//        {
//            if (this._confirmation)
//                throw new Error("Already started");

//            var confirmation = this._confirmation = new Promise<boolean>((resolve, reject) => this._resolve = resolve);
//            confirmation.finally(
//                () =>
//                {
//                    this._confirmation = null;
//                    this._resolve = null;
//                    this.updated.invoke(this);
//                })
//            this.content =
//                {
//                    title: title,
//                    message: message,
//                    confirm: confirm || "Yes",
//                    cancel: cancel || "No"
//                };
//            this.updated.invoke(this);
            
//            return confirmation;
//        }

//        //#endregion


//        //#region Public Events

//        public updated = new Sparks.Event();

//        //#endregion


//        //#region Public Methods

//        public submit(): void
//        {
//            if (!this._confirmation)
//                throw new Error("Not started");

//            this._resolve(true);
//        }

//        public cancel(): void
//        {
//            if (!this._confirmation)
//                throw new Error("Not started");

//            this._resolve(false);
//        }

//        public isActive(): boolean
//        {
//            return !!this._confirmation;
//        }

//        //#endregion
        

//        //#region Protected Properties

//        protected content: ConfirmationBox.IContent = {};

//        //#endregion


//        //#region Private Fields

//        private _confirmation: Promise<boolean> = null;
//        private _resolve: (confirmed: boolean) => void;

//        //#endregion
//    }

//    export namespace ConfirmationBox
//    {
//        export interface IContent
//        {
//            title?: string;
//            message?: string;
//            confirm?: string;
//            cancel?: string;
//        }
//    }
//}
