
namespace Sparks.Code
{
    export interface LiteralValueNode extends ExpressionNode
    {
        //#region Properties

        value: any;
                
        //#endregion
    }
}
