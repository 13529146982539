
namespace Sparks.Code
{
    export interface ExpressionStatementNode extends StatementNode
    {
        //#region Properties
        
        expression: ExpressionNode;

        //#endregion
    }
}
