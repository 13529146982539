
namespace Simpleo.CustomerPortal
{
    export class PaymentProcessor implements IService
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods
        
        public initialize(services: Application.ServiceProvider): void
        {
            this.invoiceManagementService = services.invoiceManagementService;
            this.serviceManager = services.serviceManager;
            this.uiManager = services.uiManager;
        }

        public async processRenewalPayment(serviceRenewal: RenewalInfo): Promise<boolean>
        {
            if (this._paymentProcessing)
                throw new Error("Already started");

            try
            {
                var paymentProcessing = this._paymentProcessing = new Promise<boolean>(resolve => this._resolvePayment = resolve);

                try
                {
                    this.serviceRenewal = serviceRenewal;
                    var serviceRetrieval = this._task = this.serviceManager.getService(serviceRenewal.serviceNumber);
                    this.service = await serviceRetrieval;
                    this.loaded.invoke(this);
                    this.updated.invoke(this);
                }
                finally
                {
                    this._task = null;
                    this.updated.invoke(this);
                }

                var processed = await paymentProcessing;
                return processed;
            }
            finally
            {
                this._paymentProcessing = null;
                this._resolvePayment = null;
                this._paymentResult = false;
                this.updated.invoke(this);
            }
        }

        public async submit(cardHolder: string, cardNumber: string, expiryMonth: string, expiryYear: string): Promise<void>
        {
            if (!this._paymentProcessing)
                throw new Error("Not started");

            var now = Date.now();

            try
            {
                var payment = this._task =
                    this.invoiceManagementService
                        .payInvoice(
                            this.serviceRenewal.invoiceNumber,
                            cardHolder,
                            cardNumber,
                            parseInt(expiryMonth),
                            parseInt(expiryYear),
                            this.serviceRenewal.total);

                this.updated.invoke(this);

                await payment;

                this._paymentResult = true;
            }
            catch (error)
            {
                await Sparks.Timer.wait(250, now);
                throw error;
            }
            finally
            {
                this._task = null;
                this.updated.invoke(this);
            }
        }

        public close(): void
        {
            if (!this._paymentProcessing)
                throw new Error("Not started");

            this._resolvePayment(this._paymentResult);
        }

        public isVisible(): boolean
        {
            return !!this._paymentProcessing;
        }

        public isWaiting(): boolean
        {
            return !!this._task;
        }

        //#endregion


        //#region Public Events

        public loaded = new Sparks.Event()
        public updated = new Sparks.Event();

        //#endregion


        //#region Public Properties

        public serviceRenewal: RenewalInfo = null;
        public service: ServiceInfo = null;

        //#endregion


        //#region Protected Properties

        protected invoiceManagementService: InvoiceManagementService;
        protected serviceManager: ServiceManager;
        protected uiManager: UIManager;

        //#endregion
        

        //#region Private Fields

        private _paymentProcessing: Promise<boolean> = null;
        private _resolvePayment: (completed: boolean) => void;
        private _task: Promise<any> = null;
        private _paymentResult: boolean = false;

        //#endregion
    }
}
