
namespace Sparks.Code
{
    export enum UnaryOperator
    {
        Undefined = 0,

        Not,
        Negate,
        Increment,
        Decrement
    }
}
