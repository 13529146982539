
namespace Sparks.Code
{
    export interface ArrayInstantiationNode extends ExpressionNode
    {
        //#region Properties

        itemType: TypeReferenceNode;
        size: ExpressionNode;
        initialValues: ExpressionNode[];

        //#endregion
    }
}
