///<reference path="../Native/Xml.ts" />

namespace Sparks.Xml
{
    export class Iterator
    {
        //#region Constructor

        public constructor(node: Node, offset: number)
        {
            this.node = node;
            this.offset = offset;
        }

        //#endregion


        //#region Public Methods

        public previous<TResult>(handler: (node: Node, enter?: boolean) => TResult): TResult
        {
            if (Sparks.DOM.Node.isElement(this.node) && this.offset > 0)
            {
                var previous = this.node.childNodes[this.offset - 1];
                if (Sparks.Xml.Node.isElement(previous))
                {
                    // Enter element
                    this.node = previous;
                    this.offset = previous.childNodes.length;
                    return handler(previous, true);
                }
                else
                {
                    // Sibling
                    this.offset--;
                    return handler(previous, false);
                }
            }

            // Leave node
            var node = this.node;
            this.node = this.node.parentElement;
            this.offset = Sparks.Xml.Node.indexOf(node);
            return handler(node, false);
        }

        public next<TResult>(handler: (node: Node, enter?: boolean) => TResult): TResult
        {
            if (Sparks.DOM.Node.isElement(this.node) && this.offset < this.node.childNodes.length)
            {
                var next = this.node.childNodes[this.offset];
                if (Sparks.Xml.Node.isElement(next))
                {
                    // Enter element
                    this.node = next;
                    this.offset = 0;
                    return handler(next, true);
                }
                else
                {
                    // Sibling
                    this.offset++;
                    return handler(next, false);
                }
            }

            // Leave node
            var node = this.node;
            this.node = this.node.parentElement;
            this.offset = Sparks.Xml.Node.indexOf(node) + 1;
            return handler(node, false);
        }
        
        //#endregion


        //#region Public Properties
        
        public node: Node;
        public offset: number;
        
        //#endregion
    }    
}