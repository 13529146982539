
namespace Sparks.UI
{
    export class Scope implements IContext
    {
        //#region Constructor

        public constructor(parent?: Scope, locals?: Sparks.Map<any>)
        {
            this.processor = Processor.defaultProcessor;

            this.parent = parent || null;
            if (this.parent)
                this.parent.attachScope(this);

            this.locals = new LocalContext(locals || null, parent && [parent.locals]);
        }

        //#endregion


        //#region Public Methods

        public dispose(): void
        {
            this.disposing.invoke(this);

            this.watches.clear();
            this.parent = null;
        }

        public addScope(locals?: Sparks.Map<any>): Scope
        {
            return new Scope(this, locals);
        }

        public get(name: string): any
        {
            return this.locals.get(name);
        }

        public set(name: string, value: any): void
        {
            this.locals.set(name, value);
        }

        public has(name: string, local?: boolean): boolean
        {
            return this.locals.has(name, local);
        }

        public enumerate(): string[]
        {
            return this.locals.enumerate();
        }

        public watch<TValue>(expression: Expression<TValue>, handler: Handler<TValue>): Watch<TValue>
        {
            var watch = new ValueWatch<TValue>(this, expression, handler);
            this.watches.add(watch);
            this.changed.invoke(this, { watchAdded: watch });
            return watch;
        }

        public watchCollection<TItem>(expression: Expression<TItem[]>, handler: Handler<TItem[]>): Watch<TItem[]>
        {
            var watch = new ArrayWatch(this, expression, handler);
            this.watches.add(watch);
            this.changed.invoke(this, { watchAdded: watch });
            return watch;
        }

        public update(processingContext?: ProcessingContext): void
        {
            if (processingContext)
            {
                if (this._processingContext)
                    throw new Error("Already updating");

                // Use processor with processing context
                this._processingContext = processingContext;
                this.processor.process(this._processingContext);
                this._processingContext = null;
            }
            else
            {
                // Leave if already updating
                if (this._processingContext)
                    return;

                // Perform update on isolated scope
                var isolatedScope = Scope.getIsolatedScope(this);
                if (isolatedScope == this)
                {
                    this._processingContext = new ProcessingContext(this);    
                    this.processor.process(this._processingContext);
                    this._processingContext = null;
                }
                else
                {
                    isolatedScope.update();    
                }
            }
        }

        public static getIsolatedScope(scope: Scope): Scope
        {
            for (;;)
            {
                if (scope.isIsolated || !scope.parent)
                    return scope;
                scope = scope.parent;
            }
        }

        //#endregion


        //#region Public Events

        public disposing = new Sparks.Event();
        public updating = new Sparks.Event();
        public changed = new Sparks.Event<Scope.ChangedEventArgs>();

        //#endregion


        //#region Public Properties

        public processor: Processor;

        public parent: Scope;

        public children = new Set<Scope>();

        public isIsolated: boolean = true;
               
        public locals: LocalContext;

        public watches = new Set<Watch<any>>();

        //#endregion


        //#region Private Methods

        private attachScope(scope: Scope): Scope
        {
            this.children.add(scope);
            scope.disposing.add(this.scope_disposing);
            this.changed.invoke(this, { scopeAdded: scope });
            return scope;
        }

        private detachScope(scope: Scope): void
        {
            scope.disposing.remove(this.scope_disposing);
            this.children.delete(scope);
            //this.changed.invoke(this, { scopeRemoved: scope });
        }

        private scope_disposing =
            scope => this.detachScope(scope);

        //#endregion


        //#region Private Fields

        private _processingContext: ProcessingContext = null;

        //#endregion
    }

    export namespace Scope
    {
        export interface ChangedEventArgs
        {
            //#region Properties

            watchAdded?: Watch<any>;
            watchRemoved?: Watch<any>;
            scopeAdded?: Scope;
            scopeRemoved?: Scope;

            //#endregion
        }
    }
}