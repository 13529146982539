
namespace Sparks.UI
{
    export class IfDirective extends Directive
    {
        //#region Constructor

        public constructor(condition: Expression<boolean>, template: Template)
        {
            super();
            this.condition = condition;
            this.template = template;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: ActivationContext): void
        {
            if (activationContext.instantiates)
            {
                // Create range
                var range = activationContext.range.addRange("sx-if=\"" + this.condition.source + "\"");

                // Initialize
                this.initialize(activationContext.activator, activationContext.scope, range);
            }
            else
            {
                // Create range
                var reference = activationContext.target.ownerDocument.createComment("sx-if=\"" + this.condition.source + "\"");
                activationContext.target.parentElement.insertBefore(reference, activationContext.target);
                var range = new Range(activationContext.target.parentElement, reference);

                // Remove node
                activationContext.target.parentElement.removeChild(activationContext.target);

                // Initialize
                this.initialize(activationContext.activator, activationContext.scope, range);
            }
        }


        //#endregion


        //#region Public Properties

        public condition: Expression<boolean>;
        public template: Template;

        //#endregion


        //#region Private Methods

        private initialize(activator: Activator, scope: Scope, range: Range): void
        {
            var instanceScope: Scope = null;

            scope.watch(
                this.condition,
                condition =>
                {
                    if (condition)
                    {
                        if (!instanceScope)
                        {
                            instanceScope = new Scope(scope);
                            instanceScope.isIsolated = false;

                            activator.instantiate(range, this.template.directives, instanceScope);
                        }
                    }
                    else if (instanceScope)
                    {
                        instanceScope.dispose();
                        instanceScope = null;

                        range.clear();
                    }
                });
        }

        //#endregion
    }

    export namespace IfDirective
    {
        export class IfDirectiveCompiler extends BaseAttributeDirectiveCompiler
        {
            public constructor()
            {
                super("sx-if");
            }
            
            public compile(node: HTMLElement, compilationContext: CompilationContext): Directive
            {
                // Retrieve conditino
                var condition = this.compileArguments<boolean>(node);

                // Interrupt node compilation
                compilationContext.queue = [];

                // Build template with remaining node directives
                var template = compilationContext.compiler.compile([node]);
                return new IfDirective(condition, template);
            }
        }
    }
}