
namespace Sparks.Code
{
    export interface BinaryOperationNode extends ExpressionNode
    {
        //#region Properties

        leftOperand: ExpressionNode;
        rightOperand: ExpressionNode;
        operator: BinaryOperator;
        
        //#endregion
    }
}
