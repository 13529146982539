///<reference path="../Core/Interop.ts" />
///<reference path="../Native/DOM.ts" />
///<reference path="../Xml/Node.ts" />
///<reference path="../Xml/Element.ts" />
///<reference path="Node.ts" />
///<reference path="HTMLElement.ts" />

namespace Sparks.DOM
{
    export interface IHTMLImageElementExtension extends IHTMLElementExtension
    {
        //#region Methods
        
        //#endregion
    }
    
    export class HTMLImageElementExtension
    {
        //#region Public Methods

        //#endregion
    }
    
    export interface HTMLImageElementConstructor extends IHTMLImageElementExtension
    {
        prototype: HTMLImageElement;
        new (): HTMLImageElement;
    }

    export type HTMLImageElement = Native.HTMLImageElement;

    export var HTMLImageElement: HTMLImageElementConstructor =
        Interop.extend(
            Native.HTMLImageElement,
            [
                Sparks.Xml.NodeExtension,
                Sparks.Xml.ElementExtension,
                Sparks.DOM.NodeExtension,
                Sparks.DOM.HTMLElementExtension,
                Sparks.DOM.HTMLImageElementExtension
            ]);
}
