
namespace Sparks.Apps.Routing
{
    export class RouteBuilder
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        public useHandler(handler: (args: Sparks.Map<string>) => void): RouteBuilder
        {
            this._handlers = this._handlers || [];
            this._handlers.push(handler);
            return this;
        }

        public useRoute(route: Route): RouteBuilder;
        public useRoute(routing: (routeBuilder: RouteBuilder) => void): RouteBuilder;
        public useRoute(routeOrRouting: Route | ((routeBuilder: RouteBuilder) => void)): RouteBuilder
        {
            if (Function.isFunction(routeOrRouting))
            {
                var routeBuilder = new RouteBuilder();
                routeOrRouting(routeBuilder);
                var route = routeBuilder.build();
                this._routes.push(route);
            }
            else
            {
                this._routes.push(routeOrRouting);
            }

            return this;
        }

        public when(path: string): RouteBuilder;
        public when(predicate: (context: IContext) => boolean): RouteBuilder;
        public when(pathOrPredicate: string | ((context: IContext) => boolean)): RouteBuilder
        {
            if (String.isString(pathOrPredicate))
            {
                this._path = pathOrPredicate;
                this._predicate = null;
            }
            else
            {
                this._path = null;
                this._predicate = pathOrPredicate;
            }

            return this;
        }

        public build(): Route
        {
            var route = new Route();

            if (this._path != null)
            {
                route.path = this._path;
                route.pathFormat = new StringFormat(route.path);
                route.predicate = context => route.pathFormat.isMatch(context.path);
            }
            else if (this._predicate)
            {
                route.predicate = this._predicate;
            }   

            route.handlers = this._handlers;
            route.routes = (this._routes.length > 0) ? this._routes : null;

            return route;
        }

        public static create(): RouteBuilder
        {
            return new RouteBuilder();
        }
        
        //#endregion


        //#region Private Fields

        private _path: string = null;
        private _predicate: (context: IContext) => boolean = null;
        private _handlers: ((args: Sparks.Map<string>) => void)[] = null;
        private _routes: Route[] = [];


        //#endregion
    }
}
