
namespace Sparks.Code.SourceMaps
{
    export interface SourceMap
    {
        //#region Properties

        version?: number;
        file?: string;
        sources?: string[];
        sourceRoot?: string;
        names?: string[];
        mappings?: string;

        //#endregion
    }
}
