
namespace Sparks.Apps.Forms
{
    export class Field
    {
        //#region Constructor

        public constructor(value?: string)
        {
            this._value = (value != null) ? value : null;
        }

        //#endregion


        //#region Public Methods

        public async edit(value?: string): Promise<string>
        {
            if (this._edition)
                throw new Error("Already started");

            value = (value != null) ? value : this._value;

            this._edition = new Promise<string>(
                (resolve, reject) =>
                {
                    this._originalValue = this._value = value;
                    this._finish = resolve;
                });
            var newValue = await this._edition;
            this._value = (newValue) ? newValue : this._originalValue;

            this._edition = null;
            this._finish = null;

            return newValue;
        }

        public submit(): void
        {
            if (this._edition)
                this._finish(this._value);
        }

        public cancel(): void
        {
            if (this._edition)
                this._finish(null);
        }

        public isEditing(): boolean
        {
            return !!this._edition;
        }

        public getValue(): string
        {
            return this._value;
        }

        public setValue(value: string): void
        {
            this._value = value;
        }

        //#endregion


        //#region Public Events


        //#endregion


        //#region Public Properties


        //#endregion


        //#region Private Fields

        private _edition: Promise<string> = null;
        private _finish: (value: string) => void;
        private _originalValue: string = null;
        private _value: string;

        //#endregion
    }
}