
namespace Sparks
{
    export class Serializer<TValue>
    {
        //#region Public Methods

        public constructor();
        public constructor(fieldSerializers: Map<(value: any) => any>);
        public constructor(fieldSerializers: Map<(value: any) => any>, fieldDeserializers: Map<(value: any) => any>);
        public constructor(fieldSerializers?: Map<(value: any) => any>, fieldDeserializers?: Map<(value: any) => any>)
        {
            this.fieldSerializers = (arguments.length > 0) ? fieldSerializers : { "$id$": null };
            this.fieldDeserializers = (arguments.length > 1) ? fieldDeserializers : { };
        }

        //#endregion


        //#region Public Methods

        public serialize(value: TValue): any
        {
            if (value && Object.isObject(value))
            {
                var result: Sparks.Map<any> = {};

                for (var property in value)
                {
                    var fieldSerializer = this.fieldSerializers[property];

                    if (fieldSerializer === null)
                        continue;

                    var propertyValue = value[property];
                    if (fieldSerializer)
                        result[property] = fieldSerializer(propertyValue);
                    else if (Array.isArray(propertyValue))
                        result[property] = propertyValue.slice();
                    else
                        result[property] = Serializer.defaultSerializer.serialize(propertyValue);
                }

                return result;
            }

            return value;
        }

        public deserialize(value: any): TValue
        {
            if (value && Object.isObject(value))
            {
                var result: Sparks.Map<any> = {};

                for (var property in value)
                {
                    var fieldDeserializer = this.fieldDeserializers[property];

                    if (fieldDeserializer === null)
                        continue;

                    var propertyValue = value[property];
                    if (fieldDeserializer)
                        result[property] = fieldDeserializer(propertyValue);
                    else if (Array.isArray(propertyValue))
                        result[property] = propertyValue.slice();
                    else
                        result[property] = Serializer.defaultSerializer.deserialize(propertyValue);
                }

                return <TValue>result;
            }

            return value;
        }

        //#endregion


        //#region Public Properties

        public fieldSerializers: Map<(value: any) => any> = {};
        public fieldDeserializers: Map<(value: any) => any> = {};

        public static defaultSerializer = new Serializer<any>();

        //#endregion
    }
}
