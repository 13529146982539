///<reference path="../Core/BinaryOperator.ts" />
///<reference path="../Core/Processor.ts" />
///<reference path="../Core/UnaryOperator.ts" />

namespace Sparks.Code.Compilation
{
    export class CodeGenerator extends Processor<string>
    {
        //#region Constructor

        public constructor()
        {
            super();
        }

        //#endregion


        //#region Public Methods

        public static generateCode(codeNode: CodeNode): string
        {
            var codeGenerator = new CodeGenerator();
            return codeGenerator.process(codeNode);
        }

        public static getBinaryOperatorString(operator: BinaryOperator): string
        {
            return CodeGenerator._binaryOperations[operator];
        }

        public static getUnaryOperatorString(operator: UnaryOperator): string
        {
            return CodeGenerator._unaryOperations[operator];
        }

        //#endregion


        //#region Protected Methods

        protected processArrayInstantiation(codeNode: ArrayInstantiationNode): string
        {
            var items = (codeNode.initialValues) ? codeNode.initialValues.map(item => this.process(item)) : null;
            return "[" + ((items) ? items.join(", ") : "") + "]";
        }

        protected processAssignmentExpression(codeNode: AssignmentExpressionNode): string
        {
            return this.process(codeNode.target) + " = " + this.process(codeNode.expression);
        }
                
        protected processBinaryOperation(codeNode: BinaryOperationNode): string
        {
            var operation = CodeGenerator.getBinaryOperatorString(codeNode.operator);
            var priority = CodeGenerator._operatorsPriority.indexOf(codeNode.operator);
            
            var leftOperand = this.process(codeNode.leftOperand);
            if (CodeNode.isBinaryOperation(codeNode.leftOperand))
            {
                if (CodeGenerator._operatorsPriority.indexOf(codeNode.leftOperand.operator) <= priority)
                    leftOperand = "(" + leftOperand + ")";
            }

            var rightOperand = this.process(codeNode.rightOperand);
            if (CodeNode.isBinaryOperation(codeNode.rightOperand))
            {
                if (CodeGenerator._operatorsPriority.indexOf(codeNode.rightOperand.operator) <= priority)
                    rightOperand = "(" + rightOperand + ")";
            }

            return leftOperand + " " + operation + " " + rightOperand;
        }

        protected processConditionExpression(codeNode: ConditionExpressionNode): string
        {
            return "(" + this.process(codeNode.condition) + " ? " + this.process(codeNode.trueExpression) + " : " + this.process(codeNode.falseExpression) + ")";
        }

        protected processExpressionSequence(codeNode: ExpressionSequenceNode): string
        {
            var expressions = codeNode.expressions.map(expression => this.process(expression));
            return expressions.join(", ");
        }

        protected processExpressionStatement(codeNode: ExpressionStatementNode): string
        {
            return this.process(codeNode.expression) + ";";
        }

        protected processIndexer(codeNode: IndexerNode): string
        {
            return this.process(codeNode.target) + "[" + this.process(codeNode.index) + "]";
        }

        protected processLambdaExpression(codeNode: LambdaExpressionNode): string
        {
            return "(" + codeNode.parameters.join(", ") + ") => " + this.process(codeNode.expression);
        }

        protected processLiteralValue(codeNode: LiteralValueNode): string
        {
            return this.getLiteralCode(codeNode.value);
        }

        protected processMemberReference(codeNode: MemberReferenceNode): string
        {
            return this.process(codeNode.target) + "." + codeNode.memberName;
        }

        protected processMethodInvocation(codeNode: MethodInvocationNode): string
        {
            return this.process(codeNode.method) + "(" + codeNode.arguments.map(argument => this.process(argument)).join(", ") + ")";
        }
        
        protected processObjectInstantiation(codeNode: ObjectInstantiationNode): string
        {
            var type = CodeNode.isTypeReference(codeNode.type) ? AppDomain.currentDomain.getType(codeNode.type.typeName) : null;
            if (type == Native.Object)
            {
                var properties = (codeNode.initializedProperties) ? Map.getEntries(codeNode.initializedProperties).map(entry => entry.key + ": " + this.process(entry.value)) : null;
                return (properties) ? ("{ " + properties.join(", ") + " }") : "{}";
            }
            else
            {
                var $arguments = (codeNode.arguments) ? codeNode.arguments.map(argument => this.process(argument)) : null;
                return "new (" + this.process(codeNode.type) + ")(" + (($arguments) ? $arguments.join(", ") : "") + ")";
            }
        }

        protected processParameterReference(codeNode: ParameterReferenceNode): string
        {
            return codeNode.parameterName;
        }

        protected processStatementBlock(codeNode: StatementBlockNode): string
        {
            var statements = codeNode.statements.map(statement => this.process(statement));
            return statements.join("; ") + ((statements.length > 0) ? ";" : "");
        }

        protected processThisReference(codeNode: ThisReferenceNode): string
        {
            return "this";
        }

        protected processTypeReference(codeNode: TypeReferenceNode): string
        {
            return codeNode.typeName;
        }

        protected processUnaryOperation(codeNode: UnaryOperationNode): string
        {
            var operation = CodeGenerator.getUnaryOperatorString(codeNode.operator);
            return operation + this.process(codeNode.operand);
        }

        protected processVariableReference(codeNode: VariableReferenceNode): string
        {
            return codeNode.variableName;
        }

        //#endregion


        //#region Private Methods
        
        private getLiteralCode(literalValue: any): string
        {
            if (literalValue == null)
                return "null";

            if (Boolean.isBoolean(literalValue))
                return (literalValue) ? "true" : "false";

            if (String.isString(literalValue))
            {
                return "\"" + literalValue
                    .replace(/\\/g, "\\\\")
                    .replace(/\"/g, "\\\"")
                    .replace(/\n/g, "\\n")
                    .replace(/\r/g, "\\r")
                    .replace(/\t/g, "\\t") + "\"";
            }

            return literalValue.toString();
        }

        private static loadBinaryOperations(): Map<string>
        {
            var operations: Map<string> = {};

            operations[Sparks.Code.BinaryOperator.Add] = "+";
            //operations[Noesis.Code.BinaryOperator.AddAssign]
            operations[Sparks.Code.BinaryOperator.BitwiseAnd] = "&";
            operations[Sparks.Code.BinaryOperator.BitwiseOr] = "|";
            operations[Sparks.Code.BinaryOperator.BitwiseXor] = "^";
            operations[Sparks.Code.BinaryOperator.Divide] = "/";
            //operations[Noesis.Code.BinaryOperator.DivideAssign]
            operations[Sparks.Code.BinaryOperator.Equal] = "==";
            operations[Sparks.Code.BinaryOperator.GreaterOrEqual] = ">=";
            operations[Sparks.Code.BinaryOperator.Greater] = ">";
            operations[Sparks.Code.BinaryOperator.Less] = "<";
            operations[Sparks.Code.BinaryOperator.LessOrEqual] = "<=";
            operations[Sparks.Code.BinaryOperator.LogicalAnd] = "&&";
            operations[Sparks.Code.BinaryOperator.LogicalOr] = "||";
            operations[Sparks.Code.BinaryOperator.LogicalXor] = "^";
            operations[Sparks.Code.BinaryOperator.Modulo] = "%";
            operations[Sparks.Code.BinaryOperator.Multiply] = "*";
            //operations[Noesis.Code.BinaryOperator.MultiplyAssign]
            operations[Sparks.Code.BinaryOperator.NotEqual] = "!=";
            operations[Sparks.Code.BinaryOperator.StrictEqual] = "===";
            operations[Sparks.Code.BinaryOperator.StrictNotEqual] = "!==";
            operations[Sparks.Code.BinaryOperator.Substract] = "-";
            //operations[Noesis.Code.BinaryOperator.SubstractAssign]

            return operations;
        }

        private static loadUnaryOperations(): Map<string>
        {
            var operations: Map<string> = {};

            operations[Sparks.Code.UnaryOperator.Not] = "!";
            operations[Sparks.Code.UnaryOperator.Negate] = "-";
            operations[Sparks.Code.UnaryOperator.Increment] = "++";
            operations[Sparks.Code.UnaryOperator.Decrement] = "--";

            return operations;
        }

        //#endregion


        //#region Private Fields

        private static _binaryOperations: Map<string> = CodeGenerator.loadBinaryOperations();
        private static _unaryOperations: Map<string> = CodeGenerator.loadUnaryOperations();
        private static _operatorsPriority: Sparks.Code.BinaryOperator[] =
            [
                Sparks.Code.BinaryOperator.LogicalOr,
                Sparks.Code.BinaryOperator.LogicalAnd,
                Sparks.Code.BinaryOperator.BitwiseOr,
                Sparks.Code.BinaryOperator.BitwiseXor,
                Sparks.Code.BinaryOperator.BitwiseAnd,
                Sparks.Code.BinaryOperator.Equal,
                Sparks.Code.BinaryOperator.StrictEqual,
                Sparks.Code.BinaryOperator.NotEqual,
                Sparks.Code.BinaryOperator.StrictNotEqual,
                Sparks.Code.BinaryOperator.Less,
                Sparks.Code.BinaryOperator.LessOrEqual,
                Sparks.Code.BinaryOperator.Greater,
                Sparks.Code.BinaryOperator.GreaterOrEqual,
                Sparks.Code.BinaryOperator.Add,
                Sparks.Code.BinaryOperator.Substract,
                Sparks.Code.BinaryOperator.Multiply,
                Sparks.Code.BinaryOperator.Divide,
                Sparks.Code.BinaryOperator.Modulo                
            ];

        //#endregion
    }
}
