///<reference path="../Core/Interop.ts" />
///<reference path="../Native/DOM.ts" />
///<reference path="../Xml/Node.ts" />

namespace Sparks.DOM
{
    export interface INodeExtension extends Sparks.Xml.INodeExtension
    {
        //#region NodeExtension

        getCommonAncestor(first: Node, second: Node): HTMLElement;
        getCommonAncestor(first: Node, second: Node, includeSelf: boolean): Node;
        getHtml(node: Node): string;
        getHtml(nodes: Node[]): string;
        getValue(node: Node): any;
        insert(container: Node, position: number, node: Node): void;
        insert(container: Node, position: number, nodes: Node[]): void;
        insertAfter(referenceNode: Node, node: Node): Node;
        insertAfter(referenceNode: Node, nodes: Node[]): Node;
        insertBefore(referenceNode: Node, node: Node): Node;
        insertBefore(referenceNode: Node, nodes: Node[]): Node;
        isElement(node: Node): node is HTMLElement;
        isElement(node: Node, tagName: string): node is HTMLElement;
        isElement(node: Node, tagName: "img"): node is HTMLImageElement;
        isElement(node: Node, tagName: "input"): node is HTMLInputElement;
        isElement(node: Node, tagName: "link"): node is HTMLLinkElement;
        isElement(node: Node, tagName: "script"): node is HTMLScriptElement;
        isElement(node: Node, tagName: "select"): node is HTMLSelectElement;
        isElement(node: Node, tagName: "textarea"): node is HTMLTextAreaElement;
        replace(node: Node, newNode: Node): Node;
        replace(node: Node, newNodes: Node[]): Node;
        setValue(node: Node, value: any): void;

        //#endregion
    }
    
    export class NodeExtension
    {
        //#region Public Methods
        
        public static getHtml(node: Node): string;
        public static getHtml(nodes: Node[]): string;
        public static getHtml(nodeOrNodes: Node | Node[]): string
        {
            var nodes = Sparks.Array.isArray(nodeOrNodes) ? nodeOrNodes : [nodeOrNodes];
            var html = nodes.map(
                node =>
                {
                    if (Sparks.DOM.Node.isTextNode(node))
                        return node.textContent;
                    if (Sparks.DOM.Node.isElement(node))
                        return node.outerHTML;
                    throw new Error("Not implemented");
                });
            return html.join();
        }

        public static getValue(node: Node): any
        {
            if (node.nodeType == Sparks.DOM.NodeType.Element)
            {
                if (Sparks.DOM.Node.isElement(node, "input"))
                {
                    if (node.type == "checkbox")
                        return node.checked;
                    else if (node.type == "date")
                        return node.value ? new Date(node.value) : null;
                    else
                        return node.value;
                }
                else if (Sparks.DOM.Node.isElement(node, "textarea"))
                {
                    return node.value;
                }
                else if (Sparks.DOM.Node.isElement(node, "select"))
                {
                    return node.value;
                }
                else if (Sparks.DOM.Node.isElement(node))
                {
                    return node.innerHTML;
                }
                else
                {
                    throw new Error("Not implemented");
                }
            }
            else if (node.nodeType == Sparks.DOM.NodeType.Text)
            {
                return node.nodeValue;
            }
            else
            {
                throw new Error("Not implemented");
            }
        }
        
        public static insert(container: Node, position: number, node: Node): void;
        public static insert(container: Node, position: number, nodes: Node[]): void;
        public static insert(container: Node, position: number, nodeOrNodes: Node | Node[]): void
        {
            if (Sparks.DOM.Node.isElement(container))
            {
                if (position < container.childNodes.length)
                    NodeExtension.insertBefore(container.childNodes[position], nodeOrNodes);
                else
                    HTMLElementExtension.append(container, nodeOrNodes);
            }
            else if (Sparks.DOM.Node.isTextNode(container))
            {
                throw new Error("Not implemented");
            }
            else
            {
                throw new Error("Not supported");
            }
        }

        public static insertAfter(referenceNode: Node, node: Node): Node;
        public static insertAfter(referenceNode: Node, nodes: Node[]): Node;
        public static insertAfter(referenceNode: Node, nodeOrNodes: Node | Node[]): Node;
        public static insertAfter(referenceNode: Node, nodeOrNodes: Node | Node[]): Node
        {
            if (referenceNode.nextSibling)
                NodeExtension.insertBefore(referenceNode.nextSibling, nodeOrNodes);
            else
                HTMLElementExtension.append(<HTMLElement>referenceNode.parentNode, nodeOrNodes);

            return referenceNode;
        }

        public static insertBefore(referenceNode: Node, node: Node): Node;
        public static insertBefore(referenceNode: Node, nodes: Node[]): Node;
        public static insertBefore(referenceNode: Node, nodeOrNodes: Node | Node[]): Node;
        public static insertBefore(referenceNode: Node, nodeOrNodes: Node | Node[]): Node
        {
            var nodes: Node[] = Array.isArray(nodeOrNodes) ? nodeOrNodes : [nodeOrNodes];

            nodes.forEach(
                node =>
                {
                    if (Sparks.DOM.Node.isElement(node))
                    {
                        if ((node).tagName == "SCRIPT")
                        {
                            //HTMLScriptElementExtension.setCurrent(<HTMLScriptElement>node);
                            referenceNode.parentNode.insertBefore(node, referenceNode);
                        }
                        else if (node.getElementsByTagName("SCRIPT").length > 0)
                        {
                            var children = HTMLElement.removeChildren(node);
                            referenceNode.parentNode.insertBefore(node, referenceNode);
                            children.forEach(child => HTMLElementExtension.append(node, child));
                        }
                        else
                        {
                            referenceNode.parentNode.insertBefore(node, referenceNode);
                        }
                    }
                    else
                    {
                        referenceNode.parentNode.insertBefore(node, referenceNode);
                    }
                });
            
            return referenceNode;
        }

        public static isElement(node: Node): boolean;
        public static isElement(node: Node, tagName: string): boolean;
        public static isElement(node: Node, tagName?: string): boolean
        {
            if (node.nodeType != NodeType.Element)
                return false;

            return !tagName || (tagName.toUpperCase() == (<Element>node).tagName.toUpperCase());
        }

        public static replace(node: Node, newNode: Node): Node;
        public static replace(node: Node, newNodes: Node[]): Node;
        public static replace(node: Node, newNodeOrNodes: Node | Node[]): Node
        {
            var nextSibling = node.nextSibling;
            var parent = <HTMLElement>node.parentNode;
            
            Node.remove(node);

            if (nextSibling)
                NodeExtension.insertBefore(nextSibling, newNodeOrNodes);
            else
                HTMLElementExtension.append(parent, newNodeOrNodes);

            return node;
        }

        public static setValue(node: Node, value: any): void
        {
            if (node.nodeType == Sparks.DOM.NodeType.Element)
            {
                if (Sparks.DOM.Node.isElement(node, "input"))
                {
                    if (node.type == "checkbox" || node.type == "radio")
                    {
                        node.checked = !!value;
                    }
                    else if (node.type == "date")
                    {
                        if (value instanceof Date)
                            node.value = value.toISOString().substr(0, 10);
                        else if (Sparks.String.isString(value))
                            node.value = value;
                        else
                            node.value = "";
                    }
                    else
                    {
                        node.value = value ? value.toString() : "";
                    }
                }
                else if (Sparks.DOM.Node.isElement(node, "textarea"))
                {
                    node.value = value;
                }
                else if (Sparks.DOM.Node.isElement(node, "select"))
                {
                    node.value = value;
                }
                else if (Sparks.DOM.Node.isElement(node))
                {
                    node.innerHTML = value;
                }
                else
                {
                    throw new Error("Not implemented");
                }
            }
            else if (Sparks.DOM.Node.isTextNode(node))
            {
                node.nodeValue = value;
            }
            else
            {
                throw new Error("Not implemented");
            }
        }

        //#endregion


        //#region Public Constants
        
        public static EventsExtensionName: string = "Events";

        //#endregion
    }
    
    export interface NodeConstructor extends INodeExtension
    {
        prototype: Node;
        new (): Node;
    }

    export type Node = Native.Node;
    
    export var Node: NodeConstructor =
        Interop.extend(
            Native.Node,
            [
                Sparks.Xml.NodeExtension,
                Sparks.DOM.NodeExtension
            ]);
}
