
namespace Sparks.UI
{
    export class ClassDirective extends Directive
    {
        //#region Constructor

        public constructor(classes: Sparks.Map<Expression<boolean>>)
        {
            super();
            this.classes = classes;
            
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: ActivationContext): void
        {
            // Bind expressions
            var target = <HTMLElement>activationContext.target;
            var locals = { "$node": target, "$scope": activationContext.scope };
            var expressions = Map.remap(this.classes, (className, expression) => ExpressionCompiler.bindLocals(expression, locals));
            
            // Create watches
            var watches =
                Map.remap(
                    expressions,
                    (name, expression) =>
                    {
                        var current: string = null;
                        return activationContext.scope.watch(
                            expression,
                            value =>
                            {
                                var className =
                                    (String.isString(value)) ?
                                        name + value :
                                        (value) ? name : null;

                                if (className != current)
                                {
                                    if (current)
                                        target.classList.remove(current);

                                    current = className;

                                    if (current)
                                        target.classList.add(current);
                                }
                            });
                    });

            // Initialize on render frame
            window.requestAnimationFrame(() => Map.forEach(watches, (className, watch) => watch.update()));
        }

        //#endregion


        //#region Public Properties

        public classes: Sparks.Map<Expression<any>>;

        //#endregion
    }

    export namespace ClassDirective
    {
        export class ClassDirectiveCompiler extends BaseAttributeDirectiveCompiler
        {
            public constructor()
            {
                super("sx-class");
            }
            
            public compile(node: HTMLElement, compilationContext: CompilationContext): Directive
            {
                var classes = this.compileNamedArguments(node);
                return new ClassDirective(classes);
            }
        }
    }
}