
declare module _Native
{
    type _Number = Number;
    type _NumberConstructor = NumberConstructor;
}

module Native
{
    export type Number = _Native._Number;
    export var Number: _Native._NumberConstructor;
    export type NumberConstructor = _Native._NumberConstructor;
}

Native.Number = Number;
