
namespace Sparks.Net
{
    export class SocketService
    {
        //#region Constructor

        public constructor(socketServiceUrl?: string)
        {
            this.serviceUrl = (socketServiceUrl) ? this.getAbsoluteUrl(socketServiceUrl) : null;
        }

        //#endregion


        //#region Public Methods

        public dispose(): void
        {
            this.disconnect();
        }

        public connect(serviceUrl?: string): Promise<void>
        {
            this.serviceUrl = (serviceUrl) ? this.getAbsoluteUrl(serviceUrl) : this.serviceUrl;

            if (this._connection == null)
            {
                if (!this.serviceUrl)
                    throw new Error("Service url is required for socket connection");

                this._connection = new Promise<void>(
                    (resolve, reject) =>
                    {
                        this._webSocket = new WebSocket(this.serviceUrl);
                        this._webSocket.addEventListener("close", eventArgs => this.onDisconnected());
                        this._webSocket.addEventListener("message", eventArgs => this.onMessage(eventArgs.data));
                        this._webSocket.addEventListener(
                            "open",
                            eventArgs =>
                            {
                                this.onConnected();
                                resolve();
                            });
                        this._webSocket.addEventListener(
                            "error",
                            eventArgs =>
                            {
                                var isConnected = this.isConnected;
                                this.onError();
                                if (!isConnected)
                                    reject(new Error("Socket connection failed"));
                            });
                    });
            }

            return this._connection;
        }

        public disconnect(): void
        {
            if (this._webSocket)
                this._webSocket.close();
        }

        //#endregion


        //#region Public Events

        public disconnected = new Event();
        //public eventInvoked = new Event();

        //#endregion


        //#region Public Properties

        public serviceUrl: string;
        public isConnected: boolean = false;

        //#endregion


        //#region Protected Methods
        
        protected invoke(method: string, $arguments: any[]): Promise<any>
        {
            if (!this.isConnected)
                throw new Error("Not connected");

            var id = this._nextId++;
            var message: SocketService.Message = { id: id, member: method, arguments: $arguments };
            var request: SocketService.Request;
            var promise = new Promise<any>(
                (resolve, reject) =>
                {
                    this._requests[id] = request = { message: message, resolve: resolve, reject: reject };
                    var data = JSON.stringify(message);
                    this._webSocket.send(data);
                });
            promise.finally(() => delete this._requests[id]);

            return promise;
        }

        protected bindEvent(eventName: string, event: Event<any>): void
        {
            this._events[eventName] = event;
        }

        protected getAbsoluteUrl(serviceUrl: string): string
        {
            var serviceUri = Uri.parseUrl(serviceUrl);
            serviceUri.scheme = "ws";
            serviceUri.hostname = serviceUri.hostname || window.location.hostname;
            return Uri.buildUrl(serviceUri);
        }

        protected onConnected(): void
        {
            this.isConnected = true;
        }

        protected onDisconnected(): void
        {
            this.isConnected = false;
            
            // TODO: reject pending requests

            if (this._webSocket)
            {
                this._webSocket = null;
                this.disconnected.invoke(this);
            }
        }

        protected onMessage(messageData: string): void
        {
            var message: SocketService.Message = JSON.parse(messageData);
            
            if (message.member)
            {
                var event = this._events[message.member];
                if (event)
                    event.invoke(this, message.arguments[0] || null);
            }
            else if (message.id)
            {
                var request = this._requests[message.id];
                
                if (!message.error)
                    request.resolve(message.result);
                else
                    request.reject(SocketService.Error.create(message.error));
            }
            else
            {
                throw new Error("Can't process socket message: " + messageData);
            }
        }

        protected onError(): void
        {
            // TODO: reject pending requests
            if (this._webSocket)
                this._webSocket.close();
        }

        //#endregion


        //#region Private Fields

        private _webSocket: WebSocket;
        private _eventHandlers: Map<(eventArgs: Native.Event) => void> = {};
        private _connection: Promise<void>;
        private _requests: Map<SocketService.Request> = {};
        private _events: Map<Event<any>> = {};
        private _nextId: number = 1;

        //#endregion
    }

    export namespace SocketService
    {
        export interface Message
        {
            //#region Properties

            id: number;
            member?: string;
            arguments?: any[];
            result?: any;
            error?: Error;

            //#endregion
        }

        export interface Error
        {
            //#region Properties

            message: string;
            innerError: Error;
            data: any;

            //#endregion
        }

        export var Error =
        {
            create: function (error: Error): Sparks.Error
            {
                var innerError = (error.innerError) ? Error.create(error.innerError) : null;
                return new Sparks.Error(error.message, innerError);
            }
        };

        export interface Request
        {
            //#region Properties

            message: Message;
            resolve: (value?: any) => void;
            reject: (error?: any) => void;

            //#endregion
        }
    }
}
