///<reference path="Component.ts" />

namespace Sparks.Apps.Components
{
    export abstract class Browser<TObject> extends Component
    {
        //#region Constructor

        public constructor()
        {
            super();
        }

        //#endregion


        //#region Public Methods

        public update(force?: boolean): Promise<void>;
        public update(force?: boolean): Promise<any>
        {
            if (this.isWaiting() && !force)
                return this._retrieval;

            var retrieval = this._retrieval = this.runTask(this.fetch());
            retrieval.then(
                items =>
                {
                    if (this._retrieval == retrieval)
                    {
                        this.items = items;
                        this.updated.invoke(this);
                    }
                });

            this.updated.invoke(this);

            return retrieval;
        }

        //#endregion


        //#region Public Events

        public updated = new Sparks.Event();

        //#endregion


        //#region Public Properties

        public items: TObject[] = [];

        //#endregion


        //#region Protected Methods

        protected abstract fetch(): Promise<TObject[]>;

        //#endregion


        //#region Private Fields
        
        private _retrieval: Promise<TObject[]>;

        //#endregion
    }
}
