
namespace Sparks.UI
{
    export class EventDirective extends Directive
    {
        //#region Constructor

        public constructor(eventHandlers: [string, Expression<any>][])
        {
            super();
            this.eventHandlers = eventHandlers;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: ActivationContext): void
        {
            var target = <HTMLElement>activationContext.target;
            var eventListeners: [string, EventListener][] =
                this.eventHandlers.map(
                    ([event, expression]) =>
                        [
                            event,
                            function (eventArgs)
                            {
                                if (expression)
                                {
                                    var locals = { "$node": target, "$scope": activationContext.scope, "$event": eventArgs };
                                    var boundExpression = ExpressionCompiler.bindLocals(expression, locals);
                                    boundExpression.evaluate(activationContext.scope);
                                }

                                activationContext.scope.update();
                            }
                        ]);

            eventListeners.forEach(([event, handler]) => target.addEventListener(event, handler));

            activationContext.scope.disposing.add(
                () => eventListeners.forEach(([event, handler]) => target.removeEventListener(event, handler)));
        }

        //#endregion


        //#region Public Properties

        public eventHandlers: [string, Expression<any>][];

        //#endregion
    }

    export namespace EventDirective
    {
        export class EventDirectiveCompiler extends BaseAttributeDirectiveCompiler
        {
            public constructor()
            {
                super("sx-on-");
            }

            public canCompile(node: Node): boolean
            {
                return Sparks.DOM.Node.isElement(node) && node.getAttributeNames().some(name => name.startsWith(this.attribute));
            }
            
            public compile(node: HTMLElement, compilationContext: CompilationContext): Directive
            {
                var eventDirectiveAttributes = node.getAttributeNames().filter(name => name.startsWith(this.attribute));
                var eventHandlers: [string, Expression<any>][] =
                    eventDirectiveAttributes.map(
                        attribute =>
                            [
                                attribute.substring(this.attribute.length),
                                ExpressionCompiler.compileExpression(this.getArguments(node, attribute))
                            ]);
                return new EventDirective(eventHandlers);
            }
        }
    }
}