/// <reference path="Xml.ts" />

declare module _Native
{
    type _Event = Event;
    type _EventTarget = EventTarget;
    type _HTMLDocument = HTMLDocument;
    type _HTMLElement = HTMLElement;
    type _HTMLImageElement = HTMLImageElement;
    type _HTMLInputElement = HTMLInputElement;
    type _HTMLScriptElement = HTMLScriptElement;
    type _HTMLSelectElement = HTMLSelectElement;
    type _HTMLTextAreaElement = HTMLTextAreaElement;
    type _MouseEvent = MouseEvent;
    type _Range = Range;
    type _Selection = Selection;
    type _Window = Window;
}

module Native
{
    export type Event = _Native._Event;
    export var Event: {
        prototype: Event;
        new (type: string, eventInitDict?: EventInit): Event;
        AT_TARGET: number;
        BUBBLING_PHASE: number;
        CAPTURING_PHASE: number;
    };
    
    export type EventTarget = _Native._EventTarget;
    export var EventTarget: {
        prototype: EventTarget;
        new (): EventTarget;
    };
    
    export type HTMLDocument = _Native._HTMLDocument;
    export var HTMLDocument: {
        prototype: HTMLDocument;
        new (): HTMLDocument;
    };
    
    export type HTMLElement = _Native._HTMLElement;
    export var HTMLElement: {
        prototype: HTMLElement;
        new (): HTMLElement;
    };
    
    export type HTMLImageElement = _Native._HTMLImageElement;
    export var HTMLImageElement: {
        prototype: HTMLImageElement;
        new (): HTMLImageElement;
    };
    export var Image: {
        new (width?: number, height?: number): HTMLImageElement;
    };
    
    export type HTMLInputElement = _Native._HTMLInputElement;
    export var HTMLInputElement: {
        prototype: HTMLInputElement;
        new (): HTMLInputElement;
    };
    
    export type HTMLScriptElement = _Native._HTMLScriptElement;
    export var HTMLScriptElement: {
        prototype: HTMLScriptElement;
        new (): HTMLScriptElement;
    };
    
    export type HTMLSelectElement = _Native._HTMLSelectElement;
    export var HTMLSelectElement: {
        prototype: HTMLSelectElement;
        new (): HTMLSelectElement;
    };
    
    export type HTMLTextAreaElement = _Native._HTMLTextAreaElement;
    export var HTMLTextAreaElement: {
        prototype: HTMLTextAreaElement;
        new (): HTMLTextAreaElement;
    };
    
    export type MouseEvent = _Native._MouseEvent;
    export var MouseEvent: {
        prototype: MouseEvent;
        new (typeArg: string, eventInitDict?: MouseEventInit): MouseEvent;
    };
    
    export type Selection = _Native._Selection;
    export var Selection: {
        prototype: Selection;
        new (): Selection;
    };
    
    export type Range = _Native._Range;
    export var Range: {
        prototype: Range;
        new (): Range;
        END_TO_END: number;
        END_TO_START: number;
        START_TO_END: number;
        START_TO_START: number;
    };
    
    export type Window = _Native._Window;
    export var Window: {
        prototype: Window;
        new (): Window;
    };
}

var EventTarget: {
    prototype: EventTarget;
    new (): EventTarget;
};

Native.Event = Event;
Native.EventTarget = EventTarget || Node;
Native.HTMLDocument = HTMLDocument;
Native.HTMLElement = HTMLElement;
Native.HTMLImageElement = HTMLImageElement;
Native.Image = Image;
Native.HTMLInputElement = HTMLInputElement;
Native.HTMLScriptElement = HTMLScriptElement;
Native.HTMLTextAreaElement = HTMLTextAreaElement;
Native.MouseEvent = MouseEvent;
Native.Selection = Selection;
Native.Range = Range;
Native.Window = Window;
