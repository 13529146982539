
namespace Simpleo.CustomerPortal
{
    export class AccessRecovery
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        public initialize(services: Application.ServiceProvider): void
        {
            this.authenticationService = services.authenticationService;
            this.uiManager = services.uiManager;
        }

        public async recoverAccess(key: string): Promise<void>
        {
            this.key = key;
            if (this.key)
            {
                try
                {
                    var validation = this._task = this.authenticationService.isResetKeyValid(this.key);

                    this.isAllowed = false;
                    this.isDenied = false;
                    this.isCompleted = false;

                    this.updated.invoke(this);

                    var valid = await validation;
                    if (valid)
                        this.isAllowed = true;
                    else
                        this.isDenied = true;
                }
                finally
                {

                    this._task = null;
                    this.updated.invoke(this);
                }
            }
            else
            {
                this.isDenied = true;
                this.updated.invoke(this);
            }
        }

        public isWaiting(): boolean
        {
            return !!this._task;
        }

        public async submit(password: string): Promise<void>
        {
            try
            {
                var task = this._task = this.authenticationService.updatePassword(this.key, password);
                this.updated.invoke(this);
                await task;
                this.isCompleted = true;
            }
            finally
            {
                this._task = null;
                this.updated.invoke(this);
            }
        }

        //#endregion


        //#region Public Events

        public updated = new Sparks.Event();

        //#endregion


        //#region Public Properties
        
        public key: string = null;
        public isAllowed: boolean = false;
        public isDenied: boolean = false;
        public isCompleted: boolean = false;

        //#endregion


        //#region Protected Properties

        protected authenticationService: AuthenticationService;
        protected uiManager: UIManager;

        //#endregion


        //#region Private Fields

        private _task: Promise<any> = null;

        //#endregion
    }
}
