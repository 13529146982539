
namespace Sparks.UI
{
    export class DebugDirective extends Directive
    {
        //#region Constructor

        public constructor(expression: Expression<any>)
        {
            super();
            this.expression = expression;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: ActivationContext): void
        {
            activationContext.scope.watch(this.expression,
                value =>
                {
                    (<any>console).debugger;
                    console.log(this.expression.source + ": " + value);
                });
        }

        //#endregion


        //#region Public Properties

        public expression: Expression<any>;

        //#endregion
    }

    export namespace DebugDirective
    {
        export class DebugDirectiveCompiler extends BaseAttributeDirectiveCompiler
        {
            //#region Constructor

            public constructor()
            {
                super("sx-debug");
            }

            //#endregion


            //#region Public Methods

            public compile(node: HTMLElement, compilationContext: CompilationContext): Directive
            {
                var expression = this.compileArguments<any>(node);
                return new DebugDirective(expression);
            }

            //#endregion
        }
    }
}