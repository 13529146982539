///<reference path="../Native/Error.ts" />
///<reference path="../Native/Object.ts" />

namespace Sparks
{
    export class Interop
    {
        //#region Public Methods

        //public static createPrototype<TType, TBaseType>(type: TypeReference<TType>): any;
        //public static createPrototype<TType, TBaseType>(type: TypeReference<TType>, baseType: TypeReference<TBaseType>): any;
        //public static createPrototype<TType, TBaseType>(type: TypeReference<TType>, baseType?: TypeReference<TBaseType>): any
        //{
        //    function ctor(this: TType) { this.constructor = type; };
        //    if (baseType)
        //        ctor.prototype = baseType.prototype;
        //    return new ctor();
        //}

        public static define<TType>(typeName: string, type: TypeReference<TType>, members?: string[]): any
        {
            var typePath = typeName.split(".");

            var alias = function () { };
            alias.prototype = type.prototype;
            
            if (members)
            {
                for (var i = 0; i < members.length; i++)
                    alias[members[i]] = type[members[i]];
            }

            var scope = window;
            for (var i = 0; i < typePath.length - 1; i++)
            {
                var namespace = typePath[i];
                scope[namespace] = scope[namespace] || {};
                scope = scope[namespace];
            }

            scope[typePath.slice(-1)[0]] = alias;

            return alias;
        }

        public static extend<TType, TExtensionType>(type: TypeReference<TType>, extensionType: TypeReference<TExtensionType>): any;
        public static extend<TType, TExtensionType>(type: TypeReference<TType>, extensionType: TypeReference<TExtensionType>, members: string[]): any;
        public static extend<TType, TExtensionType>(type: TypeReference<TType>, extensionTypes: TypeReference<TExtensionType>[]): any;
        public static extend<TType, TExtensionType>(type: TypeReference<TType>, extensionTypes: TypeReference<TExtensionType>[], members: string[]): any;
        public static extend<TType, TExtensionType>(type: TypeReference<TType>, extensionTypes: any, members?: string[]): any
        {
            var name = Interop.getTypeName(type);
            var extendedType = <TypeReference<TType>>eval("(function " + name + "() {})");
            extendedType.prototype = type.prototype;
            //var extendedType = type.bind(null);
            //extendedType.prototype = type.prototype;

            members = members || Native.Object.getOwnPropertyNames(type);
            members.forEach(
                member =>
                {
                    if (Interop.ExtendedTypeSkippedMembers[member])
                        return;
                    extendedType[member] = type[member];
                });
            
            var types: TypeReference<TExtensionType>[] = ("function" == typeof (extensionTypes)) ? [extensionTypes] : extensionTypes
            types.forEach(
                extensionType =>
                {
                    var extensionMembers = Native.Object.getOwnPropertyNames(extensionType);
                    extensionMembers.forEach(
                        member =>
                        {
                            if (Interop.ExtendedTypeSkippedMembers[member] || member.charAt(0) == '$')
                                return;
                            extendedType[member] = extensionType[member];
                        });
                });
            
            return extendedType;
        }

        public static getStack(): string[]
        {
            var error = new Native.Error();
            var stack = (<any>error).stack;
            if (!stack)
            {
                try
                {
                    throw error;
                }
                catch (error)
                {
                    stack = error.stack;
                }
            }
            return stack.split('\n').slice(2).map(entry => entry.trim())
        }

        public static getTypeName(type: Function): string;
        public static getTypeName<TType>(type: TypeReference<TType>): string;
        public static getTypeName(type: Function | TypeReference<any>): string
        {
            if (type["name"])
                return type["name"];
            var parseResult = /^\s*function\s+(\w+)\s*\(/.exec(type.toString());
            if (parseResult)
                return parseResult[1];
            parseResult = /^\[object\s+(\w+)\]$/.exec(type.toString());
            return parseResult ? parseResult[1] : null;
        }

        //#endregion


        //#region Private Constants

        private static InheritedTypeSkippedProperties: Map<boolean> =
        {
            "$super": true,
            "$typeinfo": true
        };

        private static ExtendedTypeSkippedMembers: Map<boolean> =
        {
            "arguments": true,
            "caller": true,
            "length": true,
            "name": true,
            "prototype": true,
            "$super": true,
            "$typeinfo": true
        };

        //#endregion
    }
}
