
namespace Sparks.Code
{
    export interface StatementBlockNode extends StatementNode
    {
        //#region Properties

        statements: StatementNode[];

        //#endregion
    }
}
