///<reference path="../Xml/Text.ts" />

namespace Sparks.DOM
{
    export interface ITextExtension
    {
    }
    
    export class TextExtension extends Sparks.Xml.TextExtension
    {
    }
    
    export interface TextConstructor extends ITextExtension
    {
        prototype: Text;
        new (): Text;
    }

    export type Text = Native.Text;

    export var Text: TextConstructor = <any>TextExtension;
}
