///<reference path="../Core/Watch.ts" />

namespace Sparks.UI
{
    export class ValueWatch<TValue> extends Watch<TValue>
    {
        //#region Constructor

        public constructor(scope: Scope, expression: Expression<TValue>, handler: Handler<TValue>)
        {
            super(scope, expression, handler);
        }

        //#endregion


        //#region Public Methods

        public update(): boolean
        {
            var previousValue = this._value

            try
            {
                this._value = this.expression.evaluate(this.scope);
            }
            catch (error)
            {
                throw new Error("Evaluation failed for '" + this.expression.source + "'", error);
            }

            if (this._value !== previousValue)
            {
                this.handler(this._value, previousValue);
                return true;
            }

            return false;
        }

        //#endregion


        //#region Private Fields

        private _value: TValue;

        //#endregion
    }
}