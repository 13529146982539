/// <reference path="../Core/Map.ts" />

namespace Sparks
{
    export class Base64Encoder
    {
        //#region Public Methods

        public static encode(value: number): string
        {
            var data = "";

            data += Base64Encoder.Base64Digits[value & Base64Encoder.Base64DigitBitMask];

            for (value = value >> 6; (value & Base64Encoder.Base64DigitBitMask) > 0; value = value >> 6)
                data += Base64Encoder.Base64Digits[value & Base64Encoder.Base64DigitBitMask];

            return data;

        }

        public static decode(data: string): number
        {
            var value = 0;

            var digits = data.split("");
            for (var i = digits.length - 1; i >= 0; i--)
                value = (value << 6) | Base64Encoder.Base64DigitValues[digits[i]];

            return value;

        }

        //#endregion


        //#region Private Constants

        private static readonly Base64Digits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

        private static readonly Base64DigitValues = Map.fromArrays(
            Base64Encoder.Base64Digits.split(""), 
            Base64Encoder.Base64Digits.split("").map(char => char.charCodeAt(0)));

        //#endregion


        //#region Private Constants

        private static Base64DigitBitMask = (1 << 6) - 1;
        
        //#endregion
    }
}