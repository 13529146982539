
namespace Sparks.Code
{
    export interface MemberReferenceNode extends ExpressionNode
    {
        //#region Properties

        memberName: string;
        target: ExpressionNode;

        //#endregion
    }
}
