
namespace Simpleo.CustomerPortal
{
    export class RenewalManager implements IService
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        public initialize(services: Sparks.Map<any>): void
        {
            this.serviceManagementService = services["serviceManagementService"];
        }

        //public renewService(service: ServiceInfo): Promise<RenewalInfo>
        //{
        //}

        //public cancelServiceRenewal(service: ServiceInfo): Promise<void>
        //{
        //    service.renewal = null;
        //    return Promise.getResolved();
        //    //return this.serviceManagementService.cancelRenewal(service.number);
        //}

        //public submit(): void
        //{
        //    return this.serviceManagementService.renewService(service.number, renewalLength);
        //}

        //public cancel(): void
        //{

        //}

        public isWaiting(): boolean
        {
            throw new Error();
        }

        //#endregion


        //#region Public Events

        public updated = new Sparks.Event();

        //#endregion


        //#region Public Properties


        //#endregion
        

        //#region Protected Properties

        protected serviceManagementService: ServiceManagementService;

        //#endregion
        

        //#region Private Fields

        private _serviceRenewal: Promise<void>

        //#endregion
    }
}
