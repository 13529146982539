
namespace Sparks
{
    export interface Rect
    {
        //#region Methods

        left: number;
        top: number;
        right?: number;
        bottom?: number;
        width?: number;
        height?: number;

        //#endregion
    }
}
