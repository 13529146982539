
namespace Sparks.DOM
{
    export enum EventType
    {
        Unknown = 0,

        AnimationEnd,
        AnimationStart,
        BeforeUnload,
        Blur,
        Change,
        Click,
        Command,
        ContextMenu,
        Copy,
        Cut,
        Drag,
        DragEnd,
        DragEnter,
        DragLeave,
        DragOver,
        DragStart,
        Drop,
        Error,
        Focus,
        HashChange,
        Input,
        KeyDown,
        KeyPress,
        KeyUp,
        Load,
        Message,
        MouseDown,
        MouseEnter,
        MouseLeave,
        MouseMove,
        MouseOut,
        MouseOver,
        MouseUp,
        MouseWheel,
        Paste,
        PopState,
        Resize,
        Scroll,
        Submit,
        TouchEnd,
        TouchMove,
        TouchStart,
        TransitionEnd,
        Unload
    }
}
