
namespace Sparks.Apps.Forms
{
    export class FieldController implements IDisposable
    {
        //#region Constructor

        public constructor(node: HTMLElement, fieldName: string)
        {
            this.node = node;
            this.fieldName = fieldName;
            this.validators = FieldController.loadDefaultValidators(this.node);
        }

        //#endregion


        //#region Public Methods

        public initialize(): void
        {
            this.node.addEventListener("focus", this.node_focus);
            this.node.addEventListener("blur", this.node_blur);

            this.initialValue = this.getValue();

            this.reset();

            this._listeners = this.getListeners();
            Map.forEach(this._listeners, (event, listener) => this.node.addEventListener(event, listener));
        }

        public dispose(): void
        {
            this.node.removeEventListener("focus", this.node_focus);
            this.node.removeEventListener("blur", this.node_blur);

            Map.forEach(this._listeners, (event, listener) => this.node.removeEventListener(event, listener));
            this._listeners = null;
            this.validators = null;
            this.node = null;
        }

        public reset(): void
        {
            this.hasFocus = (this.node.ownerDocument.activeElement == this.node);
            this.errors = Map.remap(this.validators, (key, validator) => null);
            this.isTouched = false;
            this.isValid = null;
            this.hasErrors = null;

            this.setValue(this.initialValue);
        }

        public getValue(): any
        {
            if (Sparks.DOM.Node.isElement(this.node, "input"))
                return (this.node.type == "checkbox" || this.node.type == "radio") ? this.node.checked : this.node.value;
            
            if (Sparks.DOM.Node.isElement(this.node, "textarea"))
                return this.node.value;

            if (Sparks.DOM.Node.isElement(this.node, "select"))
                return this.node.value;

            return this.node.innerHTML;
        }

        public setValue(value: any): void
        {
            if (Sparks.DOM.Node.isElement(this.node, "input"))
            {
                if (this.node.type == "checkbox" || this.node.type == "radio")
                    this.node.checked = !!value;
                else
                    this.node.value = value || "";
            }
            else if (Sparks.DOM.Node.isElement(this.node, "textarea") || Sparks.DOM.Node.isElement(this.node, "select"))
            {
                this.node.value = value || "";
            }
            else
            {
                throw new Error("Unable to set value for unknown input element");
            }

            if (this.isTouched)
                this.validate();
        }

        public validate(): Promise<boolean>
        {
            if (this._validation)
                return this._validation;
            
            this.isTouched = true;
            this.update();

            var fieldValidation = this._validation = new Promise<boolean>(
                (resolve, reject) =>
                {
                    var value = this.getValue();
                    var validations = Map.remap(
                        this.validators,
                        (key, validator) =>
                        {
                            this.errors[key] = null;
                            var validation = validator(value);
                            validation.then(result => this.errors[key] = result);
                            return validation;
                        });
                    
                    Promise.all(Map.getValues(validations))
                        .finally(() => this.update())
                        .then(results => resolve(results.every(result => !result)), reject);
                });

            fieldValidation.finally(() => this._validation = null);
            
            return fieldValidation;          
        }

        public static loadDefaultValidators(node: Node): Map<(value: any) => Promise<boolean>>
        {
            var validators: Map<(value: any) => Promise<boolean>> = {};

            if (Sparks.DOM.Node.isElement(node, "input") && node.required)
            {
                if (node.type == "checkbox")
                    validators["unchecked"] = value => Promise.resolve(!value);
                else
                    validators["empty"] = value => Promise.resolve(value.trim() === "");
            }

            if (Sparks.DOM.Node.isElement(node, "textarea") && node.required)
                validators["empty"] = value => Promise.resolve(value.trim() === "");

            if (Sparks.DOM.Node.isElement(node, "select") && node.required)
                validators["noSelection"] = value => Promise.resolve(value.trim() === "");
            
            if (Sparks.DOM.Node.isElement(node, "input") && node.type == "email")
                validators["invalid"] = value => Promise.resolve(!FieldController.FieldValidator.isValidEmail(value));

            if (Sparks.DOM.Node.isElement(node, "input") && (node.type == "date" || node.type == "datetime-local"))
                validators["invalid"] = value => Promise.resolve(!FieldController.FieldValidator.isValidDate(value));

            return validators;
        }

        public isValidDate(value: any): boolean
        {
            return FieldController.FieldValidator.isValidDate(value);
        }

        public isValidEmail(value: any): boolean
        {
            return FieldController.FieldValidator.isValidEmail(value);
        }

        //#endregion


        //#region Public Events

        //public focused = new System.Event();
        public updated = new Event();

        //#endregion


        //#region Public Properties
        
        public node: HTMLElement;
        public fieldName: string;
        public validators: Map<(value: any) => Promise<boolean>> = null;
        public errors: Map<boolean> = null;
        public initialValue: any = null;
        public value: any = null;
        public hasFocus: boolean = false;
        public isTouched: boolean = false;
        public isValid: boolean = null;
        public hasErrors: boolean = null;

        //#endregion


        //#region Private Methods

        private update(): void
        {
            var errors = Map.getValues(this.errors);
            
            this.value = this.getValue();
            this.isValid = errors.every(error => error === false);
            this.hasErrors = errors.some(error => error === true);
            
            this.updated.invoke(this);
        }

        private getListeners(): Map<() => void>
        {
            var listeners: Map<() => void> = {};

            listeners["blur"] =
                () =>
                {
                    this.isTouched = true;
                    this.validate();
                };

            if (Sparks.DOM.Node.isElement(this.node, "input") ||
                Sparks.DOM.Node.isElement(this.node, "textarea"))
            {
                listeners["input"] =
                    () =>
                    {
                        if (this.isTouched)
                            this.validate();
                    };
            }

            if (Sparks.DOM.Node.isElement(this.node, "select"))
            {
                listeners["change"] =
                    () =>
                    {
                        this.isTouched = true;
                        this.validate();
                    };
            }

            return listeners;
        }

        private node_focus =
            (): void =>
            {
                this.hasFocus = true;
                this.updated.invoke(this);
            }

        private node_blur =
            (): void =>
            {
                this.hasFocus = false;
                this.updated.invoke(this);
            }

        //#endregion


        //#region Private Fields

        private _validation: Promise<boolean> = null;
        private _listeners: Map<() => void> = null;

        //#endregion
    }

    export namespace FieldController
    {
        export class FieldValidator
        {
            //#region Public Methods

            public static isValidDate(value: any): boolean
            {
                if (Date.isValid(value))
                    return true;

                if (String.isString(value))
                    throw new Error("Not implemented");
                //    return Spark.Date.tryParse(value) || Spark.Date.tryParseISOString(value);

                return false;
            }

            public static isValidEmail(value: any): boolean
            {
                var regex = /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}?$/i;
                return regex.test(value);
            }

            //#endregion
        }
    }
}