
namespace Sparks.UI
{
    export class EditableDirective extends Directive
    {
        //#region Constructor

        public constructor(editable: Expression<boolean>)
        {
            super();
            this.editable = editable;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: ActivationContext): void
        {
            var locals = { "$node": activationContext.target, "$scope": activationContext.scope };
            var editable = ExpressionCompiler.bindLocals(this.editable, locals);

            activationContext.scope.watch(
                editable,
                editable =>
                {
                    if (editable)
                        (<HTMLElement>activationContext.target).setAttribute("contenteditable", "true");
                    else
                        (<HTMLElement>activationContext.target).removeAttribute("contenteditable");
                });
        }

        //#endregion


        //#region Public Properties

        public editable: Expression<boolean>;

        //#endregion
    }

    export namespace EditableDirective
    {
        export class EditableDirectiveCompiler extends BaseAttributeDirectiveCompiler
        {
            //#region Constructor

            public constructor()
            {
                super("sx-editable");
            }

            //#endregion


            //#region Public Methods

            public compile(node: HTMLElement, compilationContext: CompilationContext): Directive
            {
                var editable = this.compileArguments<boolean>(node);
                return new EditableDirective(editable);
            }

            //#endregion
        }
    }
}