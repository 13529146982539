
declare module _Native
{
    type _Function = Function;
    type _FunctionConstructor = FunctionConstructor;
}

module Native
{
    export type Function = _Native._Function;
    export var Function: _Native._FunctionConstructor;
    export type FunctionConstructor = _Native._FunctionConstructor;
}

Native.Function = Function;
