
namespace Sparks.Code
{
    export interface ExpressionSequenceNode extends ExpressionNode
    {
        //#region Properties
        
        expressions: ExpressionNode[];

        //#endregion
    }
}
