///<reference path="../Core/Watch.ts" />

namespace Sparks.UI
{
    export class ArrayWatch<TItem> extends Watch<TItem[]>
    {
        //#region Constructor

        public constructor(scope: Scope, expression: Expression<TItem[]>, handler: Handler<TItem[]>)
        {
            super(scope, expression, handler);
        }

        //#endregion


        //#region Public Methods

        public update(): boolean
        {
            var value = this.expression.evaluate(this.scope);
            var previousValue = this._value;

            if (value && this._value && Array.areEqual(value, this._value))
                return false;

            if (value == this._value)
                return false;

            this._value = (value) ? value.slice() : null;

            this.handler(value, previousValue);

            return true;
        }
        
        //#endregion


        //#region Private Fields

        private _value: TItem[];

        //#endregion
    }
}