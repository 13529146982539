
namespace Sparks.Apps.Routing
{
    export class Router
    {
       //#region Public Methods

        public static resolveRoute(routes: Route[], context: IContext): Route[]
        {
            for (var i = 0; i < routes.length; i++)
            {
                var path = this.findPath(routes[i], context);
                if (path)
                    return path;
            }
            return null;
        }

        //#endregion


        //#region Private Methods

        private static findPath(route: Route, context: IContext): Route[]
        {
            if (route.predicate && !route.predicate(context))
                return null;

            if (route.routes)
            {
                var subPath = this.resolveRoute(route.routes, context);
                if (subPath)
                {
                    subPath.unshift(route);
                    return subPath;
                }
            }

            if (route.handlers)
                return [route];

            return null;
        }

        //#endregion
    }
}