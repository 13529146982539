
namespace Sparks.Apps.Forms
{
    export class FieldValidateDirective extends Sparks.UI.Directive
    {
        //#region Constructors

        public constructor(validations: Sparks.Map<Expression<boolean>>)
        {
            super();
            this.validations = validations;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: Sparks.UI.ActivationContext): void
        {
            var fieldDirective = Sparks.Array.ofType(activationContext.directives, FieldDirective)[0];
            if (!fieldDirective)
                throw new Error("Can't locate " + FieldDirective.name);
            
            var fieldController = Object.as(FieldController, activationContext.scope.get(fieldDirective.fieldName));
            if (!fieldController)
                throw new Error("Can't locate " + FieldController.name);

            Map.forEach(
                this.validations,
                (name, validator) =>
                {
                    fieldController.validators[name] =
                        value =>
                        {
                            var locals = new Sparks.UI.LocalContext({ $value: value }, [activationContext.scope.locals]);
                            var validation = validator.evaluate(locals);
                            return (validation instanceof Promise) ? validation : Promise.resolve(validation);
                        };
                });

        }

        //#endregion


        //#region Public Properties

        public validations: Sparks.Map<Expression<boolean | Promise<boolean>>>;

        //#endregion
    }

    export namespace FieldValidateDirective
    {
        export class FieldValidateDirectiveCompiler extends Sparks.UI.BaseAttributeDirectiveCompiler
        {
            //#region Constructor

            public constructor()
            {
                super("sx-field-validate");
            }

            //#endregion


            //#region Public Methods

            public compile(node: HTMLElement, compilationContext: Sparks.UI.CompilationContext): Sparks.UI.Directive
            {
                var validations = this.compileNamedArguments<Sparks.Map<Expression<boolean>>>(node);
                return new FieldValidateDirective(validations);
            }

            //#endregion
        }
    }
}