
namespace Sparks
{
    export class Path
    {
        //#region Public Methods

        public static build(segments: string[]): string
        {
            return segments.join(Path.Separator);
        }

        public static combine(path: string, ...subPaths: string[]): string;
        public static combine(path: string): string
        {
            var fullPath = String.trimEnd(path || "", Path.Separator);
            for (var i = 1; i < arguments.length; i++)
                fullPath += Path.Separator + String.trim(arguments[i], "/");
            return  fullPath;
        }

        public static getName(path: string): string
        {
            path = String.trimEnd(path, Path.Separator);
            var last = path.lastIndexOf(Path.Separator);
            if (last < 0)
                return path;
            return path.substring(last + 1);
        }

        public static getParent(path: string): string
        {
            var last = String.trimEnd(path, Path.Separator).lastIndexOf(Path.Separator);
            if (last < 0)
                return null;
            return (last > 0) ? path.substring(0, last) : Path.Separator;
        }

        public static getSegments(path: string): string[]
        {
            path = String.trim(path, Path.Separator);
            return (path.length > 0) ? path.split(Path.Separator) : [];
        }

        //#endregion


        //#region Public Constants

        public static Separator: string = "/";

        //#endregion
    }
}
