
namespace Sparks
{
    export class Timer
    {
        //#region Public Methods

        public static wait(delay: number): Promise<void>;
        public static wait(delay: number, sinceTime: number): Promise<void>;
        public static wait(delay: number, sinceTime?: number): Promise<void>
        {
            if (arguments.length > 1)
                delay = delay - (Date.now() - sinceTime);

            return (delay > 0) ?
                new Promise<void>((resolve, reject) => setTimeout(resolve, delay)) :
                Promise.resolve();
        }

        //#endregion
    }
}
