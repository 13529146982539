
namespace Sparks
{
    export class StringFormat
    {
        //#region Constructor

        public constructor(format: string)
        {
            var parseResult = StringFormat.Parser.parse(format);
            this._generationFormat = parseResult.generationFormat;
            this._tokens = parseResult.tokens.reverse();
            this._parser = parseResult.parser;

            this.source = format;
            this.tokens = Sparks.Array.toMap(parseResult.tokens, token => token.name);
        }

        //#endregion


        //#region Public Methods

        public isMatch($string: string): boolean
        {
            return this._parser.test($string);
        }

        public getString(values: Sparks.Map<string>): string
        {
            var result = this._generationFormat;
            var tokens = this._tokens.reverse();
            tokens.forEach(
                token =>
                {
                    var value = values[token.name] || token.default || "";
                    result = String.insert(result, token.position, value);
                });
            return result;
        }

        public getValues($string: string): Sparks.Map<string>
        {
            if (!this._parser.test($string))
                return null;
            
            var matches = this._parser.exec($string);
            var values = Sparks.Array.toMap(this._tokens, token => token.name, (token, index) => matches[index + 1]);

            return values;
        }

        //#endregion


        //#region Public Properties

        public source: string;
        public tokens: Sparks.Map<StringFormat.Token>;

        //#endregion


        //#region Private Fields

        private _parser: RegExp;
        private _generationFormat: string;
        private _tokens: StringFormat.Token[];

        //#endregion
    }

    export namespace StringFormat
    {
        export class Parser
        {
            //#region Public Methods

            public static parse(format: string): ParseResult
            {
                var literals: string[] = [];
                var tokens: Token[] = [];
                var sourcePosition = 0;
                var generationPosition = 0;

                // Process tokens
                var formatParser = new RegExp(Parser.FormatParser);
                for (var formatParseResult = formatParser.exec(format); formatParseResult; formatParseResult = formatParser.exec(format))
                {
                    var literal = format.substring(sourcePosition, formatParseResult.index).replace("{{", "{").replace("}}", "}");
                    literals.push(literal);
                    generationPosition += literal.length;

                    var tokenSource = formatParseResult[1];
                    sourcePosition = formatParseResult.index + formatParseResult[0].length;

                    var token = this.parseToken(tokenSource);
                    token.pattern =
                        token.pattern ||
                        ((sourcePosition < format.length) ? "[^\\" + format.charAt(sourcePosition) + "]*" : ".*");
                    token.position = generationPosition;

                    tokens.push(token);
                }

                if (format.length > sourcePosition)
                {
                    var literal = format.substring(sourcePosition).replace("{{", "{").replace("}}", "}");
                    literals.push(literal);
                }

                var parserPattern = this.buildParserPattern(literals, tokens);

                return {
                    parser: new RegExp(parserPattern),
                    generationFormat: literals.join(),
                    tokens: tokens
                };
            }

            //#endregion
            

            //#region Private Methods

            private static parseToken(tokenSource: string): Token
            {
                var equals = tokenSource.lastIndexOf('=');
                var colon = tokenSource.indexOf(':');

                var nameEnd = (colon > -1) ? colon : (equals > -1) ? equals : tokenSource.length;
                var name = tokenSource.substring(0, nameEnd);

                var patternStart = (colon > -1) ? colon + 1 : tokenSource.length;
                var patternEnd = (equals > -1) ? equals : tokenSource.length;
                var pattern = tokenSource.substring(patternStart, patternEnd);

                var valueStart = (equals > -1) ? equals + 1 : tokenSource.length;
                var value = tokenSource.substring(valueStart);

                // Return Token Parse Result
                return {
                    name: name,
                    pattern: pattern || null,
                    default: value || null
                };
            }

            private static buildParserPattern(literals: string[], tokens: Token[]): string
            {
                var pattern = "^";

                for (var i = 0; i < tokens.length; i++)
                {
                    pattern += literals[i].replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                    pattern += "(" + tokens[i].pattern + ")";
                }

                if (i < literals.length)
                    pattern += literals[i].replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

                pattern += "$";

                return pattern;
            }

            //#endregion


            //#region Private Fields

            //private static FormatParser = /(?:(?<!\{)(?:\{\{)*)(?:\{([^\{\}]*)\}(?:\}\})*(?!\}))/g;
            private static FormatParser = /(?:\{([^\}]+)\})/g;

            //#endregion
        }

        export interface ParseResult
        {
            //#region Properties

            generationFormat: string;
            parser: RegExp;
            tokens: Token[];

            //#endregion
        }

        export interface Token
        {
            //#region Properties

            name: string;
            position?: number;
            pattern: string;
            default: string;

            //#endregion
        }
    }
}
