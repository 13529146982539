
namespace Sparks.Code
{
    export interface ParseInfo
    {
        //#region Public Properties

        source?: string;
        line?: number;
        column?: number;

        //#endregion
    }
}
