
namespace Sparks.UI
{
    export class MutationDirective extends Directive
    {
        //#region Constructor

        public constructor()
        {
            super();
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: ActivationContext): void
        {
            var target = <HTMLElement>activationContext.target;

            // Activate children if not instantiating
            if (!activationContext.instantiates)
            {
                Array.from(target.children).forEach(
                    child =>
                    {
                        var scope = new Scope(activationContext.scope);
                        this._childrenScopes.set(child, scope);

                        var directives = activationContext.compiler.compileNode(child);
                        activationContext.activator.activateNode(child, directives, scope);
                    });
            }

            // Observe changes
            var mutationObserver = new MutationObserver(
                mutations =>
                {
                    mutations.forEach(
                        mutation =>
                        {
                            // Dispose scopes for removed nodes
                            Array.from(mutation.removedNodes).forEach(
                                node =>
                                {
                                    var scope = this._childrenScopes.get(node);
                                    if (scope)
                                    {
                                        scope.dispose();
                                        this._childrenScopes.delete(node);
                                    }
                                });

                            // Activate new nodes
                            Array.from(mutation.addedNodes).forEach(
                                node =>
                                {
                                    var scope = new Scope(activationContext.scope);
                                    this._childrenScopes.set(node, scope);

                                    var directives = activationContext.compiler.compileNode(node);
                                    activationContext.activator.activateNode(node, directives, scope);
                                });
                        });
           
                    activationContext.scope.update();
                });
            mutationObserver.observe(target, { childList: true });

            // Disconnect observer on disposal
            activationContext.scope.disposing.add(() => mutationObserver.disconnect());
        }

        //#endregion


        //#region Private Fields

        private _childrenScopes = new Native.Map<Node, Scope>();

        //#endregion
    }

    export namespace MutationDirective
    {
        export class MutationDirectiveCompiler extends BaseAttributeDirectiveCompiler
        {
            public constructor()
            {
                super("sx-mutation");
            }
                        
            public compile(node: HTMLElement, compilationContext: CompilationContext): Directive
            {
                // Compile for instantiation
                if (compilationContext.instantiates)
                {
                    throw new Error("Not implemented");
                }
                else
                {
                    // Interrupt node compilation
                    compilationContext.queue = [];
                }

                // Return directive
                return new MutationDirective();
            }
        }
    }
}