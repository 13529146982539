///<reference path="../Native/Xml.ts" />

namespace Sparks.Xml
{
    export interface INodeExtension
    {
        //#region Methods

        clone(node: Node, deep: boolean): Node;
        clone(node: Node, deep: boolean, $document: Document): Node;
        findAncestor(node: Node, tagName: string): Element;
        findAncestor(node: Node, tagName: string, includeSelf: boolean): Element;
        findAncestor(node: Node, predicate: (node: Node) => boolean): Element;
        findAncestor(node: Node, predicate: (node: Node) => boolean, includeSelf: boolean): Node;
        getAncestors(node: Node): Element[];
        getAncestors(node: Node, includeSelf: boolean): Node[];
        getCommonAncestor(first: Node, second: Node): Element;
        getCommonAncestor(first: Node, second: Node, includeSelf: boolean): Node;
        getRootNodes(nodes: Node[]): Node[];
        indexOf(node: Node): number;
        insert(container: Node, position: number, node: Node): void;
        insert(container: Node, position: number, nodes: Node[]): void;
        insertAfter(referenceNode: Node, node: Node): Node;
        insertAfter(referenceNode: Node, nodes: Node[]): Node;
        insertBefore(referenceNode: Node, node: Node): Node;
        insertBefore(referenceNode: Node, nodes: Node[]): Node;
        isAfter(node: Node, referenceNode: Node): boolean;
        isAfter(node: Node, offset: number, referenceNode: Node, referenceOffset: number): boolean;
        isBefore(node: Node, referenceNode: Node): boolean;
        isBefore(node: Node, offset: number, referenceNode: Node, referenceOffset: number): boolean;
        isElement(node: Node): node is Element;
        isElement(node: Node, tagName: string): node is Element;
        isNode(object: any): object is Node;
        isTextNode(node: Node): node is Native.Text;
        remove(node: Node): Node;
        remove(element: Element, offset: number): Node;

        //#endregion
    }    
    
    export class NodeExtension
    {
        //#region Public Methods

        public static clone(node: Node, deep: boolean, $document?: Document): Node
        {
            var cloner = NodeExtension.Cloners[node.nodeType];
            if (!cloner)
                throw new Error("Not implemented");
            return cloner(node, deep, $document || node.ownerDocument);
        }

        public static findAncestor(node: Node, tagNameOrPredicate: string | ((node: Node) => boolean), includeSelf?: boolean): any
        {
            var predicate =
                Function.isFunction(tagNameOrPredicate) ?
                    tagNameOrPredicate :
                    node => Sparks.DOM.Node.isElement(node, tagNameOrPredicate);

            node = (includeSelf) ? node : node.parentNode;
            for (; node && node.ownerDocument; node = node.parentNode)
            {
                if (predicate(node))
                    return node;
            }

            return null;
        }

        public static getAncestors(node: Node, includeSelf?: boolean): any[]
        {
            var ancestors: Node[] = [];

            if (includeSelf)
                ancestors.push(node);

            for (var ancestor = node.parentNode; ancestor && ancestor.ownerDocument; ancestor = ancestor.parentNode)
                ancestors.unshift(ancestor);

            return ancestors;
        }

        public static getCommonAncestor(first: Node, second: Node, includeSelf?: boolean): any
        {
            var firstAncestors = Node.getAncestors(first, includeSelf);
            var secondAncestors = Node.getAncestors(second, includeSelf);

            if (firstAncestors[0] != secondAncestors[0])
                throw new Error("Invalid operation");

            while (firstAncestors.length > 0)
            {
                var ancestor = firstAncestors.shift();
                secondAncestors.shift();
                if (firstAncestors[0] != secondAncestors[0])
                    return ancestor;
            }

            return <Element>(includeSelf ? first : first.parentNode);
        }

        public static getRootNodes(nodes: Node[]): Node[]
        {
            var rootNodes: Node[] = [];

            var nodesAncestors = nodes.map(node => Node.getAncestors(node, true));
            nodesAncestors.sort((first, second) => first.length - second.length);

            while (nodesAncestors.length > 0)
            {
                var ancestors = nodesAncestors.shift();
                var rootNode = ancestors.pop();

                rootNodes.push(rootNode);

                nodesAncestors = nodesAncestors.filter(ancestors => ancestors.indexOf(rootNode) < 0);
            }

            return rootNodes;
        }

        public static indexOf(node: Node): number
        {
            return Array.from<Node>(node.parentElement.childNodes).indexOf(node);
        }

        public static insert(container: Node, position: number, node: Node): void;
        public static insert(container: Node, position: number, nodes: Node[]): void;
        public static insert(container: Node, position: number, nodeOrNodes: Node | Node[]): void
        {
            if (Sparks.DOM.Node.isElement(container))
            {
                if (position < container.childNodes.length)
                    NodeExtension.insertBefore(container.childNodes[position], nodeOrNodes);
                else
                    ElementExtension.append(<Native.HTMLElement>container, nodeOrNodes);
            }
            else if (Sparks.DOM.Node.isTextNode(container))
            {
                throw new Error("Not implemented");
            }
            else
            {
                throw new Error("Not supported");
            }
        }

        public static insertAfter(referenceNode: Node, node: Node): Node;
        public static insertAfter(referenceNode: Node, nodes: Node[]): Node;
        public static insertAfter(referenceNode: Node, nodeOrNodes: Node | Node[]): Node;
        public static insertAfter(referenceNode: Node, nodeOrNodes: Node | Node[]): Node
        {
            if (referenceNode.nextSibling)
                NodeExtension.insertBefore(referenceNode.nextSibling, nodeOrNodes);
            else
                ElementExtension.append(<Element>referenceNode.parentNode, nodeOrNodes);

            return referenceNode;
        }

        public static insertBefore(referenceNode: Node, node: Node): Node;
        public static insertBefore(referenceNode: Node, nodes: Node[]): Node;
        public static insertBefore(referenceNode: Node, nodeOrNodes: Node | Node[]): Node;
        public static insertBefore(referenceNode: Node, nodeOrNodes: Node | Node[]): Node
        {
            if (Array.isArray(nodeOrNodes))
                nodeOrNodes.forEach(node => referenceNode.parentNode.insertBefore(node, referenceNode));
            else
                referenceNode.parentNode.insertBefore(nodeOrNodes, referenceNode);

            return referenceNode;
        }

        public static isAfter(node: Node, offset: number, referenceNode: Node, referenceOffset: number): boolean;
        public static isAfter(): boolean
        {
            if (arguments.length > 2)
            {
                var node: Node = arguments[0];
                var offset: number = arguments[1];
                var referenceNode: Node = arguments[2];
                var referenceOffset: number = arguments[3];

                if (node == referenceNode)
                {
                    return offset > referenceOffset;
                }
                else if (referenceNode.contains(node))
                {
                    var child = Sparks.DOM.Node.findAncestor(node, ancestor => ancestor.parentNode == referenceNode, true);
                    var childOffset = Sparks.DOM.Node.indexOf(child);
                    return childOffset >= referenceOffset;
                }
                else if (node.contains(referenceNode))
                {
                    var child = Sparks.DOM.Node.findAncestor(referenceNode, ancestor => ancestor.parentNode == node, true);
                    var childOffset = Sparks.DOM.Node.indexOf(child);
                    return childOffset < offset;
                }
                else
                {
                    return !!(referenceNode.compareDocumentPosition(node) & Node.DOCUMENT_POSITION_FOLLOWING);
                }
            }
            else
            {
                return !!(arguments[1].compareDocumentPosition(arguments[0]) & Node.DOCUMENT_POSITION_FOLLOWING);
            }
        }

        public static isBefore(node: Node, referenceNode: Node): boolean;
        public static isBefore(node: Node, offset: number, referenceNode: Node, referenceOffset: number): boolean;
        public static isBefore(): boolean
        {
            if (arguments.length > 2)
            {
                var node: Node = arguments[0];
                var offset: number = arguments[1];
                var referenceNode: Node = arguments[2];
                var referenceOffset: number = arguments[3];

                if (node == referenceNode)
                {
                    return offset < referenceOffset;
                }
                else if (referenceNode.contains(node))
                {
                    var child = Sparks.DOM.Node.findAncestor(node, ancestor => ancestor.parentNode == referenceNode, true);
                    var childOffset = Sparks.DOM.Node.indexOf(child);
                    return childOffset < referenceOffset;
                }
                else if (node.contains(referenceNode))
                {
                    var child = Sparks.DOM.Node.findAncestor(referenceNode, ancestor => ancestor.parentNode == node, true);
                    var childOffset = Sparks.DOM.Node.indexOf(child);
                    return childOffset >= offset;
                }
                else
                {
                    return !!(referenceNode.compareDocumentPosition(node) & Node.DOCUMENT_POSITION_PRECEDING);
                }
            }
            else
            {
                return !!(arguments[1].compareDocumentPosition(arguments[0]) & Node.DOCUMENT_POSITION_PRECEDING);
            }
        }

        public static isChildOf(node: Node, parent: Node): boolean
        {
            return (node != parent) && parent.contains(node);
        }

        public static isElement(node: Node): node is Element;
        public static isElement(node: Node, tagName: string): node is Element;
        public static isElement(node: Node, tagName?: string): node is Element
        {
            if (node.nodeType != NodeType.Element)
                return false;

            return !tagName || (tagName == (<Element>node).tagName);
        }
        
        public static isNode(object: any): object is Node
        {
            if (!object)
                return false;

            if (!Sparks.Object.isObject(object))
                return false;

            if (!object.ownerDocument)
                return false;

            if ((typeof object.nodeType) != "number" || (typeof object.nodeName) != "string")
                return false;

            return true;
        }

        public static isPartOf(node: Node, container: Node): boolean
        {
            return container.contains(node);
        }

        public static isTextNode(node: Node): node is Text
        {
            return node.nodeType == NodeType.Text;
        }
        
        public static remove(node: Node): Node;
        public static remove(element: Element, offset: number): Node;
        public static remove(node: Node, offset?: number): Node
        {
            if (arguments.length == 2)
            {
                var child = node.childNodes[offset];
                node.removeChild(child);
                return child;
            }
            else
            {
                if (node.parentNode)
                    node.parentNode.removeChild(node);
                return node;
            }            
        }

        public static replace(node: Node, newNode: Node): Node;
        public static replace(node: Node, newNodes: Node[]): Node;
        public static replace(node: Node, newNodeOrNodes: Node | Node[]): Node
        {
            var next = node.nextSibling;
            var parent = node.parentNode;
            var add: (newNode: Node) => void;

            if (next)
                add = (newNode) => { parent.insertBefore(newNode, next); };
            else
                add = (newNode) => { parent.appendChild(newNode); };

            Node.remove(node);

            if (Array.isArray(newNodeOrNodes))
                (<Node[]>newNodeOrNodes).forEach(add);
            else
                add(newNodeOrNodes);

            return node;
        }

        //#endregion


        //#region Private Methods

        private static cloneComment(node: Node, deep: boolean, $document: Document): Node
        {
            return $document.createComment((<Comment>node).data);
        }

        private static cloneElement(node: Node, deep: boolean, $document: Document): Node
        {
            var element = $document.createElement((<Element>node).tagName);

            for (var i = 0; i < (<Element>node).attributes.length; i++)
            {
                var attribute = (<Element>node).attributes[i];
                element.setAttribute(attribute.name, attribute.value);  
            }

            if (deep)
            {
                for (var i = 0; i < (<Element>node).childNodes.length; i++)
                {
                    var child = (<Element>node).childNodes[i];
                    var childClone = Node.clone(child, true, $document);
                    element.appendChild(childClone);
                }
            }

            return element;
        }

        private static cloneText(node: Node, deep: boolean, $document: Document): Node
        {
            return $document.createTextNode((<Text>node).data);
        }
        
        private static getNextNode(node: Node): Node
        {
            if (node.firstChild != null)
                return node.firstChild;

            while (node != null)
            {
                if (node.nextSibling != null)
                    return node.nextSibling;

                node = node.parentNode;
            }

            return null;
        }

        //#endregion


        //#region Private Constants

        private static Cloners: ((node: Node, deep: boolean, document: Document) => Node)[] = [
            null, // Unknown
            NodeExtension.cloneElement,
            null, // Attribute
            NodeExtension.cloneText,
            null, // CData
            null, // EntityReference
            null, // Entity
            null, // ProcessingInsruction
            NodeExtension.cloneComment,
            null, // Document
            null, // DocumentType
            null, // DocumentFragment
            null  // Notation 
        ];
        
        //#endregion
    }
    
    export interface NodeConstructor extends Native.NodeConstructor, INodeExtension
    {
        prototype: Node;
        new (): Node;
    }

    export type Node = Native.Node;

    export var Node: NodeConstructor = <any>NodeExtension;
}
