///<reference path="../Core/Interop.ts" />

declare namespace Sparks.DOM
{
    interface MouseEventArgs extends Native.MouseEvent
    {
    }

    var MouseEventArgs: MouseEventArgs;
}

Sparks.Interop.define("Sparks.DOM.MouseEventArgs", Event);
