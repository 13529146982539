
namespace Sparks.Apps.Components
{
    export abstract class Component implements IService
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        public initialize(services: Common.ServiceProvider): void
        {
            this._errorHandler = services.errorHandler;
            this._taskManager = new Sparks.Apps.Tasks.TaskManager((this._errorHandler) ? this._errorHandler.handle : null);
        }

        public isWaiting(): boolean
        {
            return this._taskManager.isWaiting();
        }
        
        //#endregion


        //#region Public Events

        public updated = new Sparks.Event();

        //#endregion


        //#region Protected Methods

        protected fail(error: Error): void
        {
            if (this._errorHandler)
            {
                try
                {
                    throw error;
                }
                catch (error)
                {
                    this._errorHandler.handle(error);
                    throw error;
                }
            }
            else
            {
                throw error;
            }
        }

        protected runTask<TResult>(task: Promise<TResult>): Promise<TResult>;
        protected runTask<TResult>(task: () => Promise<TResult>): Promise<TResult>;
        protected async runTask<TResult>(task: Promise<TResult> | (() => Promise<TResult>)): Promise<TResult>
        {
            try
            {
                var result = await this._taskManager.run(<Promise<TResult>>task);
            }
            finally
            {
                this.updated.invoke(this);
            }

            return result;
        }

        //#endregion


        //#region Private Fields
        
        private _taskManager: Sparks.Apps.Tasks.TaskManager;
        private _errorHandler: Diagnostics.IErrorHandler;

        //#endregion
    }
}
