
namespace Sparks.Code
{
    export interface CompilationSource
    {
        //#region Constructors


        //#endregion
    }
}
