
namespace Simpleo.CustomerPortal
{
    export class ServiceManagementService extends Sparks.Net.WebService
    {
        //#region Constructor

        public constructor(serviceUrl: string)
        {
            super(serviceUrl);
        }

        //#endregion


        //#region Public Methods

        public async getServices(): Promise<ServiceInfo[]>
        {
            var responseData = await this.invoke<any[]>("GET", "/");
            var services = responseData.map(service => ServiceInfo.serializer.deserialize(service));
            return services;
        }

        public async renewService(serviceNumber: string, renewalLength: number): Promise<RenewalInfo>
        {
            var responseData = await this.invoke<any>("POST", "/" + serviceNumber + "/renewal", { renewalLength: renewalLength });
            var renewal = RenewalInfo.serializer.deserialize(responseData);
            return renewal;
        }

        public cancelRenewal(serviceNumber: string): Promise<void>
        {
            return this.invoke("DELETE", "/" + serviceNumber + "/renewal");
        }

        //#endregion
    }
}
