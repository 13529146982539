
namespace Sparks.Code
{
    export interface CompilationContext
    {
        //#region Public Properties

        source: CompilationSource;

        parseInfo: Map<ParseInfo>;
        
        //#endregion
    }
}
