/// <reference path="../Core/CodeNodeType.ts" />

namespace Sparks.Code
{
    export interface CodeNode
    {
        //#region Properties

        codeNodeType: CodeNodeType;
        
        //#endregion
    }

    export interface CodeNodeMethods
    {
        //#region Methods

        createArrayInstantiation(initialValues: ExpressionNode[]): ArrayInstantiationNode;
        createAssignment(target: ExpressionNode, expression: ExpressionNode): AssignmentExpressionNode;
        createBinaryOperation(leftOperand: ExpressionNode, operator: BinaryOperator, rightOperand: ExpressionNode): BinaryOperationNode;
        createConditionExpression(condition: ExpressionNode, trueExpression: ExpressionNode, falseExpression: ExpressionNode): ConditionExpressionNode;
        createExpressionSequence(expressions: ExpressionNode[]): ExpressionSequenceNode;
        createExpressionStatement(expression: ExpressionNode): ExpressionStatementNode;
        createIndexer(target: ExpressionNode, index: ExpressionNode): IndexerNode;
        createLambdaExpression(expression: ExpressionNode, parameters: string[]): LambdaExpressionNode;
        createLiteral(value: any): LiteralValueNode;
        createMemberReference(target: ExpressionNode, memberName: string): MemberReferenceNode;
        createMethodInvocation(method: ExpressionNode, $arguments: ExpressionNode[]): MethodInvocationNode;
        createObjectInstantiation(type: ExpressionNode, $arguments: ExpressionNode[], properties: Map<ExpressionNode>): ObjectInstantiationNode;
        createParameterReference(parameterName: string): ParameterReferenceNode;
        createStatementBlock(statements: StatementNode[]): StatementBlockNode;
        createThisReference(): ThisReferenceNode;
        createTypeReference(typeName: string): TypeReferenceNode;
        createUnaryOperation(operand: ExpressionNode, operator: UnaryOperator);
        createVariableReference(variableName: string): VariableReferenceNode;

        isArrayInstantiation(codeNode: CodeNode): codeNode is ArrayInstantiationNode;
        isAssignment(codeNode: CodeNode): codeNode is AssignmentExpressionNode;
        isBinaryOperation(codeNode: CodeNode): codeNode is BinaryOperationNode;
        isConditionExpression(codeNode: CodeNode): codeNode is ConditionExpressionNode;
        isExpressionSequence(codeNode: CodeNode): codeNode is ExpressionSequenceNode;
        isExpressionStatement(codeNode: CodeNode): codeNode is ExpressionStatementNode;
        isIndexer(codeNode: CodeNode): codeNode is IndexerNode;
        isLambdaExpression(codeNode: CodeNode): codeNode is LambdaExpressionNode;
        isLiteral(codeNode: CodeNode): codeNode is LiteralValueNode;
        isMemberReference(codeNode: CodeNode): codeNode is MemberReferenceNode;
        isMethodInvocation(codeNode: CodeNode): codeNode is MethodInvocationNode;
        isObjectInstantiation(codeNode: CodeNode): codeNode is ObjectInstantiationNode;
        isParameterReference(codeNode: CodeNode): codeNode is ParameterReferenceNode;
        isStatementBlock(codeNode: CodeNode): codeNode is StatementBlockNode;
        isThisReference(codeNode: CodeNode): codeNode is ThisReferenceNode;
        isTypeReference(codeNode: CodeNode): codeNode is TypeReferenceNode;
        isVariableReference(codeNode: CodeNode): codeNode is VariableReferenceNode;

        isAssignable(codeNode: CodeNode): boolean;
        isExpression(codeNode: CodeNode): codeNode is ExpressionNode;
        isStatement(codeNode: CodeNode): codeNode is StatementNode;

        //#endregion
    }

    export var CodeNode: CodeNodeMethods =
        {
            createArrayInstantiation(initialValues: ExpressionNode[]): ArrayInstantiationNode
            {
                return {
                    codeNodeType: CodeNodeType.ArrayInstantiation,
                    initialValues: initialValues,
                    itemType: null,
                    size: null
                };
            },            
            createAssignment(target: ExpressionNode, expression: ExpressionNode): AssignmentExpressionNode
            {
                return { codeNodeType: CodeNodeType.AssignmentExpression, target: target, expression: expression };
            },
            createBinaryOperation(leftOperand: ExpressionNode, operator: BinaryOperator, rightOperand: ExpressionNode): BinaryOperationNode
            {
                return { codeNodeType: CodeNodeType.BinaryOperation, leftOperand: leftOperand, operator: operator, rightOperand: rightOperand };
            },
            createConditionExpression(condition: ExpressionNode, trueExpression: ExpressionNode, falseExpression: ExpressionNode): ConditionExpressionNode
            {
                return { codeNodeType: CodeNodeType.ConditionExpression, condition: condition, falseExpression: falseExpression, trueExpression: trueExpression };
            },
            createExpressionSequence(expressions: ExpressionNode[]): ExpressionSequenceNode
            {
                return { codeNodeType: CodeNodeType.ExpressionSequence, expressions: expressions };
            },
            createExpressionStatement(expression: ExpressionNode): ExpressionStatementNode
            {
                return { codeNodeType: CodeNodeType.ExpressionStatement, expression: expression };
            },
            createIndexer(target: ExpressionNode, index: ExpressionNode): IndexerNode
            {
                return { codeNodeType: CodeNodeType.Indexer, index: index, target: target };
            },
            createLambdaExpression(expression: ExpressionNode, parameters: string[]): LambdaExpressionNode
            {
                return { codeNodeType: CodeNodeType.LambdaExpression, expression: expression, parameters: parameters };
            },
            createLiteral(value: any): LiteralValueNode
            {
                return { codeNodeType: CodeNodeType.LiteralValue, value: value };
            },
            createMemberReference(target: ExpressionNode, memberName: string): MemberReferenceNode
            {
                return { codeNodeType: CodeNodeType.MemberReference, memberName: memberName, target: target };
            },
            createMethodInvocation(method: MemberReferenceNode, $arguments: ExpressionNode[]): MethodInvocationNode
            {
                return { codeNodeType: CodeNodeType.MethodInvocation, method: method, arguments: $arguments };
            },
            createObjectInstantiation(type: ExpressionNode, $arguments: ExpressionNode[], properties: Map<ExpressionNode>): ObjectInstantiationNode
            {
                return { codeNodeType: CodeNodeType.ObjectInstantiation, type: type, arguments: $arguments, initializedProperties: properties };
            },
            createParameterReference(parameterName: string): ParameterReferenceNode
            {
                return { codeNodeType: CodeNodeType.ParameterReference, parameterName: parameterName };
            },
            createStatementBlock(statements: StatementNode[]): StatementBlockNode
            {
                return { codeNodeType: CodeNodeType.StatementBlock, statements: statements };
            },
            createThisReference(): ThisReferenceNode
            {
                return { codeNodeType: CodeNodeType.ThisReference };
            },
            createUnaryOperation(operand: ExpressionNode, operator: UnaryOperator): UnaryOperationNode
            {
                return { codeNodeType: CodeNodeType.UnaryOperation, operand: operand, operator: operator };
            },
            createTypeReference(typeName: string): TypeReferenceNode
            {
                return { codeNodeType: CodeNodeType.TypeReference, typeName: typeName};
            },
            createVariableReference(variableName: string): VariableReferenceNode
            {
                return { codeNodeType: CodeNodeType.VariableReference, variableName: variableName };
            },

            isArrayInstantiation(codeNode: CodeNode): codeNode is ArrayInstantiationNode { return codeNode.codeNodeType == CodeNodeType.ArrayInstantiation; },
            isAssignment(codeNode: CodeNode): codeNode is AssignmentExpressionNode { return codeNode.codeNodeType == CodeNodeType.AssignmentExpression; },
            isBinaryOperation(codeNode: CodeNode): codeNode is BinaryOperationNode { return codeNode.codeNodeType == CodeNodeType.BinaryOperation; },
            isConditionExpression(codeNode: CodeNode): codeNode is ConditionExpressionNode { return codeNode.codeNodeType == CodeNodeType.ConditionExpression; },
            isExpressionSequence(codeNode: CodeNode): codeNode is ExpressionSequenceNode { return codeNode.codeNodeType == CodeNodeType.ExpressionSequence; },
            isExpressionStatement(codeNode: CodeNode): codeNode is ExpressionStatementNode { return codeNode.codeNodeType == CodeNodeType.ExpressionStatement; },
            isIndexer(codeNode: CodeNode): codeNode is IndexerNode { return codeNode.codeNodeType == CodeNodeType.LambdaExpression; },
            isLambdaExpression(codeNode: CodeNode): codeNode is LambdaExpressionNode { return codeNode.codeNodeType == CodeNodeType.LambdaExpression; },
            isLiteral(codeNode: CodeNode): codeNode is LiteralValueNode { return codeNode.codeNodeType == CodeNodeType.LiteralValue; },
            isMemberReference(codeNode: CodeNode): codeNode is MemberReferenceNode { return codeNode.codeNodeType == CodeNodeType.MemberReference; },
            isMethodInvocation(codeNode: CodeNode): codeNode is MethodInvocationNode { return codeNode.codeNodeType == CodeNodeType.MethodInvocation; },
            isObjectInstantiation(codeNode: CodeNode): codeNode is ObjectInstantiationNode { return codeNode.codeNodeType == CodeNodeType.ObjectInstantiation; },
            isParameterReference(codeNode: CodeNode): codeNode is ParameterReferenceNode { return codeNode.codeNodeType == CodeNodeType.ParameterReference; },
            isStatementBlock(codeNode: CodeNode): codeNode is StatementBlockNode { return codeNode.codeNodeType == CodeNodeType.StatementBlock; },
            isThisReference(codeNode: CodeNode): codeNode is ThisReferenceNode { return codeNode.codeNodeType == CodeNodeType.ThisReference; },
            isTypeReference(codeNode: CodeNode): codeNode is TypeReferenceNode { return codeNode.codeNodeType == CodeNodeType.TypeReference; },
            isVariableReference(codeNode: CodeNode): codeNode is VariableReferenceNode { return codeNode.codeNodeType == CodeNodeType.VariableReference; },

            isAssignable(codeNode: CodeNode): boolean { return assignableExpressionTypes[codeNode.codeNodeType]; },
            isExpression(codeNode: CodeNode): codeNode is ExpressionNode { return !!expressionTypes[codeNode.codeNodeType]; },
            isStatement(codeNode: CodeNode): codeNode is StatementNode { return !!statementTypes[codeNode.codeNodeType]; },
        };

    var assignableExpressionTypes =
        Array.toMap(
            [
                CodeNodeType.MemberReference,
                CodeNodeType.Indexer,
                CodeNodeType.ParameterReference,
                CodeNodeType.VariableReference
            ],
            codenodeType => codenodeType,
            codeNodeType => true);

    var expressionTypes =
        Array.toMap(
            [
                CodeNodeType.ArrayInstantiation,
                CodeNodeType.AssignmentExpression,
                CodeNodeType.BinaryOperation,
                CodeNodeType.ConditionExpression,
                CodeNodeType.Indexer,
                CodeNodeType.LambdaExpression,
                CodeNodeType.LiteralValue,
                CodeNodeType.MemberReference,
                CodeNodeType.MethodInvocation,
                CodeNodeType.ObjectInstantiation,
                CodeNodeType.ParameterReference,
                CodeNodeType.ThisReference,
                CodeNodeType.UnaryOperation,
                CodeNodeType.VariableReference
            ],
            codenodeType => codenodeType,
            codeNodeType => true);

    var statementTypes =
        Array.toMap(
            [
                CodeNodeType.ExpressionStatement,
                CodeNodeType.StatementBlock
            ],
            codenodeType => codenodeType,
            codeNodeType => true);
}
