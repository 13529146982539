
namespace Sparks
{
    export class Event<TEventArgs>
    {
        //#region Constructor

        public constructor();
        public constructor(
            add: (delegates: Delegate[], target: Object, eventHandler: EventHandler<TEventArgs>) => Delegate[],
            remove: (delegates: Delegate[], target: Object, eventHandler: EventHandler<TEventArgs>) => Delegate[]);
        public constructor(
            add?: (delegates: Delegate[], target: Object, eventHandler: EventHandler<TEventArgs>) => Delegate[],
            remove?: (delegates: Delegate[], target: Object, eventHandler: EventHandler<TEventArgs>) => Delegate[])
        {
            this._add = add || Event.add;
            this._remove = remove || Event.remove;
        }

        //#endregion


        //#region Public Methods

        public add(eventHandler: EventHandler<TEventArgs>): void;
        public add(target: Object, eventHandler: EventHandler<TEventArgs>): void;
        public add(): void
        {
            var target = (Function.isFunction(arguments[1])) ? arguments[0] : null;
            var eventHandler = (Function.isFunction(arguments[1])) ? arguments[1] : arguments[0];
            this.delegates = this._add(this.delegates, target, eventHandler);
        }

        public remove(eventHandler: EventHandler<TEventArgs>): void;
        public remove(target: Object, eventHandler: EventHandler<TEventArgs>): void;
        public remove(): void
        {
            var target = (Function.isFunction(arguments[1])) ? arguments[0] : null;
            var eventHandler = (Function.isFunction(arguments[1])) ? arguments[1] : arguments[0];
            this.delegates = this._remove(this.delegates, target, eventHandler);
        }
        
        public invoke(sender: any, eventArgs?: TEventArgs): any
        {
            var delegates = this.delegates.slice(0);
            var $arguments = arguments;

            var ret;
            delegates.forEach(delegate => ret = delegate.invoke(sender, ($arguments.length > 1) ? eventArgs : null));
            return ret;
        }

        public static add<TEventArgs>(delegates: Delegate[], target: Object, eventHandler: EventHandler<TEventArgs>): Delegate[]
        {
            delegates.push(new Delegate(target, eventHandler));
            return delegates;
        }

        public static remove<TEventArgs>(delegates: Delegate[], target: Object, eventHandler: EventHandler<TEventArgs>): Delegate[]
        {
            return delegates.filter(delegate => delegate.target != target || delegate.method != eventHandler);
        }

        //#endregion


        //#region Protected Properties

        protected delegates: Delegate[] = [];

        //#endregion


        //#region Private Fields

        private _add: (delegates: Delegate[], target: Object, eventHandler: EventHandler<TEventArgs>) => Delegate[];
        private _remove?: (delegates: Delegate[], target: Object, eventHandler: EventHandler<TEventArgs>) => Delegate[];

        //#endregion
    }
}