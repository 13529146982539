///<reference path="DirectiveCompiler.ts" />

namespace Sparks.UI
{    
    export abstract class BaseAttributeDirectiveCompiler extends DirectiveCompiler
    {
        //#region Constructor

        protected constructor(attribute: string)
        {
            super();
            this.attribute = attribute;
        }

        //#endregion


        //#region Public Methods

        public canCompile(node: Node): boolean
        {
            return Sparks.DOM.Node.isElement(node) && node.hasAttribute(this.attribute);
        }

        //#endregion


        //#region Public Properties
        
        public attribute: string;

        //#endregion


        //#region Protected Methods

        protected getArguments(node: HTMLElement, attribute?: string): string
        {
            var code = node.getAttribute(attribute || this.attribute);
            node.removeAttribute(this.attribute);
            return code;
        }

        protected compileArguments<TArguments>(node: HTMLElement): Expression<TArguments>
        {
            var code = this.getArguments(node);
            var compiledArguments = ExpressionCompiler.compileExpression<TArguments>(code);
            return compiledArguments;
        }

        protected parseNamedArguments<TNamedArguments extends Sparks.Map<Sparks.Code.ExpressionNode>>(node: HTMLElement): TNamedArguments
        {
            var code = node.getAttribute(this.attribute);
            var parsedArguments = ExpressionCompiler.parseExpression(code);

            if (!parsedArguments || !Sparks.Code.CodeNode.isObjectInstantiation(parsedArguments) || !parsedArguments.initializedProperties)
                return null;

            node.removeAttribute(this.attribute);

            return <TNamedArguments>parsedArguments.initializedProperties;
        }

        protected compileNamedArguments<TNamedArguments extends Sparks.Map<Expression<any>>>(node: HTMLElement): TNamedArguments
        {
            var parsedArguments = this.parseNamedArguments(node);
            if (!parsedArguments)
                return null;

            var compiledArguments = Map.remap(parsedArguments, (name, expressionNode) => ExpressionCompiler.compileExpression<any>(expressionNode));

            return <TNamedArguments>compiledArguments;
        }

        //#endregion
    }
}