/// <reference path="../Native/Object.ts" />

namespace Sparks
{
    export interface MapEntry<TValue>
    {
        key: string;
        value: TValue;
    }

    export interface Map<TValue>
    {
        [key: string]: TValue;
    }    

    export interface MapMethods
    {
        //#region Methods
        
        enumerate<TValue, TResult>(map: Map<TValue>, selector: (key: string, value: TValue) => TResult): TResult[];
        every<TValue>(map: Map<TValue>, predicate: (key: string, value: any) => boolean): boolean;
        filter<TValue>(map: Map<TValue>, predicate: (key: string, value: TValue) => boolean): Map<TValue>;
        forEach<TValue>(map: Map<TValue>, action: (key: string, value: TValue) => void): void;
        forEach<TValue>(map: Map<TValue>, action: (entry: MapEntry<TValue>) => void): void;
        fromArrays<TValue>(keys: string[], values: TValue[]): Map<TValue>;
        getEntries<TValue>(map: Map<TValue>): MapEntry<TValue>[];
        getKeys<TValue>(map: Map<TValue>): string[],
        getValues<TValue>(map: Map<TValue>): TValue[];
        intersectKeys<TValue>(map: Map<TValue>, other: Map<any>): string[];
        remap<TValue, TResult>(map: Map<TValue>, selector: (key: string, value: TValue) => TResult): Map<TResult>;
        remap<TValue, TResult>(map: Map<TValue>, keySelector: (key: string, value: TValue) => string, valueSelector: (key: string, value: TValue) => TResult): Map<TResult>;
        some<TValue>(map: Map<TValue>, predicate: (key: string, value: TValue) => boolean): boolean;

        //#endregion
    }

    export var Map: MapMethods =
        {
            enumerate<TValue, TResult>(map: Map<TValue>, selector: (key: string, value: TValue) => TResult): TResult[]
            {
                var keys = Native.Object.getOwnPropertyNames(map);
                return keys.map(key => selector(key, map[key]));
            },
            every<TValue>(map: Map<TValue>, predicate: (key: string, value: TValue) => boolean): boolean
            {
                var keys = Native.Object.getOwnPropertyNames(map);
                return keys.every(key => predicate(key, map[key]));
            },
            filter<TValue>(map: Map<TValue>, predicate: (key: string, value: TValue) => boolean): Map<any>
            {
                var keys = Native.Object.getOwnPropertyNames(map);
                var result: Map<TValue> = {};

                keys.forEach(
                    key =>
                    {
                        var value = map[key];
                        if (predicate(key, value))
                            result[key] = value;
                    });

                return result;
                },
            forEach<TValue>(map: Map<TValue>, action: Function): void
            {
                var keys = Native.Object.getOwnPropertyNames(map);
                if (action.length == 1)
                    keys.forEach(key => action({ key: key, value: map[key] }));
                else
                    keys.forEach(key => action(key, map[key]));
            },
            fromArrays<TValue>(keys: string[], values: TValue[]): Map<TValue>
            {
                var map: Map<TValue> = {};
                keys.forEach((key, index) => map[key] = values[index]);        
                return map;
            },
            getEntries<TValue>(map: Map<TValue>): MapEntry<TValue>[]
            {
                var keys = Native.Object.getOwnPropertyNames(map);
                return keys.map(key => ({ key: key, value: map[key] }));
            },
            getKeys: Native.Object.getOwnPropertyNames,
            getValues<TValue>(map: Map<any>): any[]
            {
                var keys = Native.Object.getOwnPropertyNames(map);
                return keys.map(key => map[key]);
            },
            intersectKeys<TValue>(map: Map<TValue>, other: Map<any>): string[]
            {
                return Map.getKeys(map).filter(key => other.hasOwnProperty(key));
            },
            remap<TValue, TResult>(
                map: Map<TValue>,
                selectorOrKeySelector: ((key: string, value: TValue) => TResult) | ((key: string, value: TValue) => string),
                valueSelector?: (key: string, value: TValue) => TResult): Map<TResult>
            {
                if (arguments.length < 3)
                {
                    var keys = Native.Object.getOwnPropertyNames(map);
                    var result: Map<TResult> = {};
                    keys.forEach(key => result[key] = <TResult>selectorOrKeySelector(key, map[key]));
                    return result;
                }
                else
                {
                    var keys = Object.getOwnPropertyNames(map);
                    var result: Map<TResult> = {};

                    keys.forEach(key => result[<string | number>selectorOrKeySelector(key, map[key])] = valueSelector(key, map[key]));

                    return result;
                }
            },
            some<TValue>(map: Map<TValue>, predicate: (key: string, value: TValue) => boolean): boolean
            {
                var keys = Native.Object.getOwnPropertyNames(map);
                return keys.some(key => predicate(key, map[key]));
            }
        };
}