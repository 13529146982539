
namespace Simpleo.CustomerPortal
{
    export interface ServiceInfo
    {
        //#region Properties

        name: string;

        number: string;

        start: Date;

        expiry: Date;

        renewal: RenewalInfo;

        //#endregion
    }

    export namespace ServiceInfo
    {
        export var serializer = new Sparks.Serializer<ServiceInfo>(
            {
                "start": (value: Date) => (value) ? value.toJSON() : null,
                "expiry": (value: Date) => (value) ? value.toJSON() : null,
                "renewal": (value) => (value) ? RenewalInfo.serializer.serialize(value) : null
            },
            {
                "start": value => (value) ? new Date(value) : null,
                "expiry": value => (value) ? new Date(value) : null,
                "renewal": (value) => (value) ? RenewalInfo.serializer.deserialize(value) : null
            }
        );
    }
}
