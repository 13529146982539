
namespace Sparks.Xml
{
    export interface Range
    {
        //#region Properties

        startNode: Node;
        startOffset: number;
        endNode: Node;
        endOffset: number;

        //#endregion
    }
}