///<reference path="Component.ts" />

namespace Sparks.Apps.Components
{
    export abstract class Creator<TObject> extends Component
    {
        //#region Constructor

        public constructor()
        {
            super();
        }

        //#endregion


        //#region Public Methods
        
        public start(...arg: any[]): Promise<TObject>;
        public start(): Promise<TObject>
        {
            if (this._creation)
                throw new Error("Already started.");

            var creation = this._creation = new Promise<TObject>(
                (resolve, reject) =>
                {
                    this._submit = resolve;
                    this._abort = reject;
                    this.started.invoke(this);
                });
            creation.finally(
                () =>
                {
                    this._creation = null;
                    this._submit = null;
                    this._abort = null;

                    this.ended.invoke(this);
                    this.updated.invoke(this);
                });

            this.updated.invoke(this);

            return creation;
        }

        public submit(...args: any[]): void;
        public submit(): void
        {
            if (!this._creation)
                throw new Error("Not started.");

            if (this.isWaiting())
                throw new Error("Already submitted");

            this.runTask(<Promise<TObject>>this.create.apply(this, arguments))
                .then(result => this._submit(result), error => this._abort(error));

            // Not needed
            //this.updated.invoke(this);
        }

        public cancel(): void
        {
            if (!this._creation)
                throw new Error("Not started.");

            this._submit(null);
        }

        public isStarted(): boolean
        {
            return !!this._creation;
        }

        //#endregion


        //#region Public Events

        public started = new Sparks.Event();
        public ended = new Sparks.Event();
        public updated = new Sparks.Event();

        //#endregion


        //#region Protected Methods

        protected abstract create(...args: any[]): Promise<TObject>;

        //#endregion


        //#region Private Fields

        private _creation: Promise<TObject> = null;
        private _submit: (result: TObject) => void = null;
        private _abort: (error: Error) => void = null;

        //#endregion
    }
}
