
namespace Sparks.Code
{
    export interface AssignmentExpressionNode extends ExpressionNode
    {
        //#region Properties

        target: ExpressionNode;
        expression: ExpressionNode;

        //#endregion
    }
}
