
namespace Sparks
{
    export class DeferredPromise<TResult>
    {
        //#region Public Methods

        public constructor()
        {
            this.promise =
                new Promise<TResult>(
                    (resolve, reject) =>
                    {
                        this.resolve = resolve;
                        this.reject = reject;
                    });
        }

        //#endregion

               
        //#region Public Properties

        public promise: Promise<TResult>;
        public resolve: (value?: TResult | PromiseLike<TResult>) => void;
        public reject: (reason?: any) => void;

        //#endregion
    }
}
