
namespace Sparks
{
    export class Expression<TValue>
    {
        //#region Constructor

        public constructor(evaluate: (context: IContext) => TValue);
        public constructor(evaluate: (context: IContext) => TValue, assign: (value: TValue, context: IContext) => void);
        public constructor(evaluate: (context: IContext) => TValue, assign: (value: TValue, context: IContext) => void, source: string);
        public constructor(evaluate: (context: IContext) => TValue, assign?: (value: TValue, context: IContext) => void, source?: string)
        {
            this.evaluate = evaluate;
            this.assign = assign || null;
            this.isAssignable = !!assign;
            this.source = source || null;
        }

        //#endregion


        //#region Public Methods

        public evaluate: (context: IContext) => TValue;

        public assign: (value: TValue, context: IContext) => void;

        //#endregion


        //#region Public Properties

        public isAssignable: boolean;
        public source: string;

        //#endregion
    }
}
