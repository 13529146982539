
namespace Sparks.Apps.Diagnostics
{
    export class ErrorHandler
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        public initialize(services: any): void
        {
            this._uiManager = services.uiManager;
        }

        public handle =
            (error: Error | string): Promise<void> =>
            {
                return this._uiManager.showMessage("Error", (error instanceof Error) ? error.message : error);
            }

        //#endregion


        //#region Private Fields

        private _uiManager: Sparks.Apps.UI.UIManager;

        //#endregion
    }
}
