///<reference path="../Native/Xml.ts" />
///<reference path="Node.ts" />

namespace Sparks.Xml
{
    export interface IDocumentExtension extends INodeExtension
    {
        //#region Methods

        loadXml(xml: string): Document;
        //getNodes(start: Position, end: Position): Node[];
        //getNodes(start: Position, end: Position, predicate: (node: Node) => boolean): Node[];
        walk(start: Position, end: Position, handler: (node: Node, enter?: boolean) => boolean): void;

        //#endregion
    }
    
    export class DocumentExtension extends NodeExtension
    {
        //#region Public Methods

        public static loadXml(xml: string): Document
        {
            var domParser = new DOMParser();
            return domParser.parseFromString(xml, "text/xml");
        }

        public static getNodes(start: Position, end: Position): Node[]
        public static getNodes(start: Position, end: Position, predicate: (node: Node) => boolean): Node[];
        public static getNodes(start: Position, end: Position, predicate?: (node: Node) => boolean): Node[]
        {
            var nodes: Node[] = [];
            var stack: Node[] = [];

            function walker(node: Node, enter: boolean): boolean
            {
                if (enter)
                {
                    stack.unshift(node);
                    if (!predicate || predicate(node))
                        nodes.push(node);
                }
                else 
                {
                    if (node != stack[0])
                    {
                        if (!predicate || predicate(node))
                        {
                            if (Sparks.DOM.Node.isElement(node))
                                nodes.unshift(node);
                            else
                                nodes.push(node);
                        }   
                    }
                    else
                    {
                        stack.shift();
                    }
                }

                return true;
            };

            this.walk(start, end, walker);

            return nodes;
        }

        public static walk(start: Position, end: Position, handler: (node: Node, enter?: boolean) => boolean): void
        {
            if (start.node == end.node && start.offset == end.offset)
                return;
            
            function $handler(node: Node, enter: boolean)
            {
                if (!Sparks.Xml.Node.isElement(node) && node == end.node)
                {
                    handler(node, true);
                    return false;
                }

                var ret = handler(node, enter);
                if (ret === false || (iterator.node == end.node && iterator.offset == end.offset))
                    return false;

                return true;
            }

            var iterator = new Iterator(start.node, start.offset);

            if (Sparks.Xml.Node.isAfter(end.node, end.offset, start.node, start.offset))
            {
                while (iterator.next($handler));
            }
            else
            {
                while (iterator.previous($handler));
            }
        }

        //#endregion
    }
    
    export interface DocumentConstructor extends IDocumentExtension
    {
        prototype: Document;
        new (): Document;
    }

    export type Document = Native.Document;

    export var Document: DocumentConstructor = <any>DocumentExtension;
}
