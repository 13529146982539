
namespace Sparks.UI
{
    export class ModelDirective extends Directive
    {
        //#region Constructor

        public constructor(getter: Expression<any>, setter: Expression<void>)
        {
            super();
            this.getter = getter;
            this.setter = setter || null;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: ActivationContext): void
        {
            var target = <HTMLElement>activationContext.target;
            var getter = ExpressionCompiler.bindLocals(this.getter, { "$node": activationContext.target, "$scope": activationContext.scope });

            // Methods
            var updateModel =
                () =>
                {
                    if (this.setter)
                    {
                        var viewValue = Sparks.DOM.Node.getValue(target);

                        if (this.setter != this.getter)
                        {
                            var setter = ExpressionCompiler.bindLocals(this.setter, { "$node": activationContext.target, "$scope": activationContext.scope, "$value": viewValue });
                            setter.evaluate(activationContext.scope.locals);
                        }
                        else
                        {
                            this.setter.assign(viewValue, activationContext.scope.locals);
                        }

                        var modelValue = getter.evaluate(activationContext.scope.locals);
                        if (modelValue != viewValue)
                            Sparks.DOM.Node.setValue(target, modelValue);
                    }

                    activationContext.scope.update();
                };

            var updateModelAsync = () => Sparks.Function.applyAsync(updateModel, null);

            var updateView =
                value =>
                {
                    var viewValue = Sparks.DOM.Node.getValue(target);
                    if (value != viewValue)
                        Sparks.DOM.Node.setValue(target, value);
                };

            // Initialize
            target.addEventListener("change", updateModel);
            target.addEventListener("input", updateModel);
            target.addEventListener("keypress", updateModelAsync);

            // Watch model
            activationContext.scope.watch(getter, updateView);

            // Disposal
            activationContext.scope.disposing.add(
                () =>
                {
                    target.removeEventListener("change", updateModel);
                    target.removeEventListener("input", updateModel);
                    target.removeEventListener("keypress", updateModelAsync);
                });
        }

        //#endregion


        //#region Public Properties

        public getter: Expression<any>;
        public setter: Expression<void>;

        //#endregion
    }

    export namespace ModelDirective
    {
        export class ModelDirectiveCompiler extends BaseAttributeDirectiveCompiler
        {
            //#region Constructor

            public constructor()
            {
                super("sx-model");
            }

            //#endregion


            //#region Public Methods

            public compile(node: HTMLElement, compilationContext: CompilationContext): Directive
            {
                var $arguments = this.compileNamedArguments<ModelDirective.Arguments>(node);
                if ($arguments)
                {
                    return new ModelDirective($arguments.get, $arguments.set);
                }
                else
                {
                    var model = this.compileArguments<any>(node);
                    return new ModelDirective(model, model);
                }
            }

            //#endregion
        }

        export interface Arguments extends Sparks.Map<Expression<any>>
        {
            //#region Properties

            get: Expression<any>;
            set: Expression<void>;

            //#endregion
        }
    }
}