
namespace Sparks.Apps.UI
{
    export class ConfirmationBox
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        public async confirm(title: string, statement: string): Promise<boolean>;
        public async confirm(title: string, statement: string, confirm: string, cancel: string): Promise<boolean>;
        public async confirm(title: string, statement: string, confirm?: string, cancel?: string): Promise<boolean>
        {
            if (this._confirmation)
                throw new Error("Already started");

            try
            {
                var confirmation = this._confirmation = new Promise<boolean>(resolve => this._resolve = resolve);

                this.content =
                {
                    title: title,
                    statement: statement,
                    confirm: confirm || "Yes",
                    cancel: cancel || "No"
                };

                this.updated.invoke(this);

                return await confirmation;
            }
            finally
            {
                this._confirmation = null;
                this._resolve = null;
                this.updated.invoke(this);
            }
        }

        public approve(): void
        {
            if (!this._confirmation)
                throw new Error("Not started");

            this._resolve(true);
        }

        public cancel(): void
        {
            if (!this._confirmation)
                throw new Error("Not started");

            this._resolve(false);
        }

        public isActive(): boolean
        {
            return !!this._confirmation;
        }

        //#endregion


        //#region Public Events

        public updated = new Sparks.Event();

        //#endregion


        //#region Protected Properties

        protected content: ConfirmationBox.Content = {};

        //#endregion


        //#region Private Fields

        private _confirmation: Promise<boolean> = null;
        private _resolve: (confirmed: boolean) => void;

        //#endregion
    }

    export namespace ConfirmationBox
    {
        export interface Content
        {
            title?: string;
            statement?: string;
            confirm?: string;
            cancel?: string;
        }
    }
}
