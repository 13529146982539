
declare interface Map<K, V>
{
}

declare module _Native
{
    type _Map<K, V> = Map<K, V>;
    type _MapConstructor = MapConstructor;
}

module Native
{
    export type Map<K, V> = _Native._Map<K, V>;
    export var Map: _Native._MapConstructor;
    export type MapConstructor = _Native._MapConstructor;
}

Native.Map = Map;
