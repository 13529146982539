
namespace Sparks.UI
{
    export class EvalDirective extends Directive
    {
        //#region Constructor

        public constructor(evaluation: Expression<any>)
        {
            super();
            this.evaluation = evaluation;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: ActivationContext): void
        {
            var locals = { "$node": activationContext.target, "$scope": activationContext.scope };
            var evaluation = ExpressionCompiler.bindLocals(this.evaluation, locals);
            evaluation.evaluate(activationContext.scope.locals);
        }

        //#endregion


        //#region Public Properties

        public evaluation: Expression<any>;

        //#endregion
    }

    export namespace EvalDirective
    {
        export class EvalDirectiveCompiler extends BaseAttributeDirectiveCompiler
        {
            //#region Constructor

            public constructor()
            {
                super("sx-eval");
            }

            //#endregion


            //#region Public Methods

            public compile(node: HTMLElement, compilationContext: CompilationContext): Directive
            {
                var evaluation = this.compileArguments<any>(node);
                return new EvalDirective(evaluation);
            }

            //#endregion
        }
    }
}