/// <reference path="../Native/Function.ts" />
/// <reference path="Interop.ts" />

namespace Sparks
{
    export interface IFunctionExtension
    {
        //#region Methods

        applyAsync(target: Function, thisArg: any, argArray?: any[]): Promise<any>;
        callAsync(target: Function, thisArg: any, ...args: any[]): Promise<any>;
        getName(target: Function): string;
        getParameters(target: Function): string[];
        isFunction(value: any): value is Function;

        //#endregion
    }
    
    export class FunctionExtension
    {
        //#region Public Methods

        public static applyAsync(target: Function, thisArg: any, argArray?: any[]): Promise<any>
        {
            return new Promise<any>(
                (resolve, reject) =>
                {
                    setTimeout(
                        () =>
                        {
                            var result: any;

                            try
                            {
                                result = target.apply(thisArg, argArray);
                            }
                            catch (e)
                            {
                                reject(e);
                                return;
                            }

                            resolve(result);
                        },
                        0);
                });
        }

        public static callAsync(target: Function, thisArg: any, ...arg: any[]): Promise<any>
        {
            return Function.applyAsync(target, thisArg, arg);
        }

        public static getParameters(target: Function): string[]
        {
            var result = /^function\s+\w*\(((\w+)\s*,?)*\)/.exec(target.toString());
            return result[1].split(',').map(name => name.trim());
        }

        public static getName(target: Function): string
        {
            if (target["name"])
                return target["name"];
            var code = target.toString();
            return code.substring("function ".length, code.indexOf("("));
        }
        
        public static isFunction(value: any): value is Function
        {
            return "function" == typeof value;
        }
        
        //#endregion
    }

    export interface FunctionConstructor extends Native.FunctionConstructor, IFunctionExtension
    {
    }
    
    export type Function = Native.Function;

    export var Function: FunctionConstructor = Interop.extend(Native.Function, FunctionExtension);
}