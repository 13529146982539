
namespace Sparks.Code.SourceMaps
{
    export enum VlqBitmask
    {
        Undefined = 0,

        Base = (1 << 5) - 1,
        Continuation = 1 << 5,
        Sign = 1,
    }
}
