
namespace Sparks.UI
{
    export abstract class Directive
    {
        // #region Public Methods

        public abstract activate(activationContext: ActivationContext): void;
        
        //#endregion
    }
}