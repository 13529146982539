
//namespace Sparks.Code
//{
//    export interface MethodReferenceNode extends ExpressionNode
//    {
//        //#region Properties

//        methodName: string;
//        targetObject: ExpressionNode;
//        targetType: TypeReferenceNode;

//        //#endregion
//    }
//}
