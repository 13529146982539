
namespace Simpleo.CustomerPortal
{
    export interface InvoiceItemInfo
    {
        //#region Properties

        type: string;

        description: string;

        amount: number;

        taxCode: number;

        //#endregion
    }
}
