
namespace Sparks.UI
{
    export class ExpressionCompiler
    {
        //#region Public Methods

        public static parseExpression(expressionCode: string): Sparks.Code.ExpressionNode
        {
            if (String.isNullOrWhiteSpace(expressionCode))
                return null;

            var parseResult = Sparks.Code.Compiler.parseExpression(expressionCode);
            return parseResult.result;
        }

        public static compileExpression(expressionCode: string, locals?: Map<any>): Expression<any>;
        public static compileExpression(expressionNode: Sparks.Code.ExpressionNode, locals?: Map<any>): Expression<any>;
        public static compileExpression<TValue>(expressionCode: string, locals?: Map<any>): Expression<TValue>;
        public static compileExpression<TValue>(expressionNode: Sparks.Code.ExpressionNode, locals?: Map<any>): Expression<TValue>;
        public static compileExpression(expressionCodeOrNode: string | Sparks.Code.ExpressionNode, locals?: Map<any>): Expression<any>
        {
            var expressionNode = (String.isString(expressionCodeOrNode)) ? ExpressionCompiler.parseExpression(expressionCodeOrNode) : expressionCodeOrNode;
            if (!expressionNode)
                return null;

            var compileResult = Sparks.Code.Compiler.compileExpression<any>(expressionNode);
            var expression = compileResult.result;
            
            if (locals)
                expression = this.bindLocals(expression, locals);

            return expression;
        }

        public static bindLocals<TValue>(expression: Expression<TValue>, locals: Map<any>): Expression<TValue>
        {
            var evaluate = expression.evaluate;
            var assign = expression.assign;
            return new Expression(
                (context) => evaluate(new LocalContext(locals, [context], context)),
                assign ?
                    (value, context) => assign(value, new LocalContext(locals, [context], context)) :
                    null,
                expression.source);
        }
        
        //#endregion
    }
}
