
//namespace Simpleo.CustomerPortal
//{
//    export class MessageBox
//    {
//        //#region Constructor

//        public constructor()
//        {
//        }

//        //#endregion


//        //#region Public Methods

//        public show(title: string, message: string): Promise<void>
//        {
//            if (this._notification)
//                throw new Error("Already started");

//            var notification = this._notification = new Promise<void>((resolve, reject) => this._close = resolve);
//            notification.finally(
//                () =>
//                {
//                    this._notification = null;
//                    this._close = null;
//                    this.updated.invoke(this);
//                })
//            this.content =
//                {
//                    title: title,
//                    message: message
//                };
//            this.updated.invoke(this);
            
//            return notification;
//        }
        
//        public close(): void
//        {
//            if (!this._notification)
//                throw new Error("Not started");

//            this._close();
//        }

//        public isVisible(): boolean
//        {
//            return !!this._notification;
//        }

//        //#endregion


//        //#region Public Events

//        public updated = new Sparks.Event();

//        //#endregion


//        //#region Protected Properties

//        protected content: MessageBox.IContent = {};

//        //#endregion


//        //#region Private Fields

//        private _notification: Promise<void> = null;
//        private _close: () => void;

//        //#endregion
//    }

//    export namespace MessageBox
//    {
//        export interface IContent
//        {
//            title?: string;
//            message?: string;
//        }
//    }
//}
