
namespace Sparks.UI
{
    export class LocalContext implements IContext
    {
        //#region Constructor
        
        public constructor(locals: Map<any>, contexts?: IContext[], assignmentContext?: IContext)
        {
            this._locals = locals || {};
            this.contexts = contexts || null;
            this._assignmentContext = assignmentContext || this;
        }

        //#endregion


        //#region Public Methods

        public get(name: string): any
        {
            if (this._locals.hasOwnProperty(name))
                return this._locals[name];

            if (this.contexts)
            {
                var context = this.contexts.find(context => context.has(name));
                if (context)
                    return context.get(name);
            }

            return undefined;
        }

        public set(name: string, value: any): void
        {
            if (this._assignmentContext == this)
                this._locals[name] = value;
            else
                this._assignmentContext.set(name, value);
        }

        public has(name: string): boolean;
        public has(name: string, local: boolean): boolean;
        public has(name: string, local?: boolean): boolean
        {
            return (this._locals.hasOwnProperty(name)) || (!local && this.contexts && this.contexts.some(context => context.has(name)));
        }

        public enumerate(): string[]
        {
            throw new Error();
            //return (this.contexts) ?
            //    Native.Object.getOwnPropertyNames(this._locals).concat(this.contexts.enumerate()) :
            //    Native.Object.getOwnPropertyNames(this._locals);
        }

        //#endregion


        //#region Public Properties

        public contexts: IContext[];

        //#endregion


        //#region Private Fields

        private _locals: Map<any>;
        private _assignmentContext: IContext;

        //#endregion
    }
}
