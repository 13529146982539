
namespace Sparks.UI
{
    export class ClickDirective extends Directive
    {
        //#region Constructor

        public constructor(onClick: Expression<any>)
        {
            super();
            this.onClick = onClick;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: ActivationContext): void
        {
            var target = <HTMLElement>activationContext.target;

            var handler =
                eventArgs =>
                {
                    if (this.onClick)
                    {
                        var locals = { "$node": target, $event: eventArgs, "$scope": activationContext.scope };
                        var onClick = ExpressionCompiler.bindLocals(this.onClick, locals);
                        onClick.evaluate(activationContext.scope);
                    }   

                    activationContext.scope.update();
                };
            activationContext.target.addEventListener("click", handler);
            activationContext.scope.disposing.add(() => activationContext.target.removeEventListener("click", handler));
        }

        //#endregion


        //#region Public Properties

        public onClick: Expression<any>;

        //#endregion
    }

    export namespace ClickDirective
    {
        export class ClickDirectiveCompiler extends BaseAttributeDirectiveCompiler
        {
            public constructor()
            {
                super("sx-click");
            }
                        
            public compile(node: HTMLElement, compilationContext: CompilationContext): Directive
            {
                var onClick = this.compileArguments<any>(node);
                return new ClickDirective(onClick);
            }
        }
    }
}