
namespace Simpleo.CustomerPortal
{
    export class AuthenticationService extends Sparks.Net.WebService
    {
        //#region Constructor

        public constructor(serviceUrl: string)
        {
            super(serviceUrl);
        }

        //#endregion


        //#region Public Methods
        
        public authenticate(emailAddress: string, password: string): Promise<UserInfo>
        {
            return this.invoke("POST", "", { emailAddress: emailAddress, password: password });
        }

        public terminate(): Promise<void>
        {
            return this.invoke("DELETE", "");
        }

        public getAuthentication(): Promise<UserInfo>
        {
            return this.invoke("GET", "");
        }

        public isResetKeyValid(key: string): Promise<boolean>
        {
            return this.invoke("GET", "/keys/" + key);
        }

        public resetPassword(emailAddress: string): Promise<void>
        {
            return this.invoke("POST", "/reset", { emailAddress: emailAddress });
        }

        public updatePassword(key: string, password: string): Promise<void>
        {
            return this.invoke("POST", "/update", { key: key, password: password });
        }

        //#endregion
    }
}
