
declare interface String
{
    startsWith(searchString: string, position?: number): boolean;
    endsWith(searchString: string, endPosition?: number): boolean;
}

declare module _Native
{
    type _String = String;
    type _StringConstructor = StringConstructor;
}

module Native
{
    export type String = _Native._String;
    export var String: _Native._StringConstructor;
    export type StringConstructor = _Native._StringConstructor;
}

Native.String = String;
