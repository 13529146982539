
namespace Sparks.Apps.UI
{
    export class Tree
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        public find(path: string): Tree.Item
        {
            return this._items[path] || null;
        }

        public toggleExpand(item: Tree.Item): void;
        public toggleExpand(item: Tree.Item, expanded: boolean): void;
        public toggleExpand(item: Tree.Item, expanded?: boolean): void
        {
            if (this._items[item.path] != item)
                throw new Error("Invalid tree item supplied");
                
            if (arguments.length < 2)
                expanded = !this._expanded[item.path];

            if (expanded)
                this._expanded[item.path] = true;
            else
                delete this._expanded[item.path];

            // TODO: update selection

            this.updated.invoke(this);
        }

        public isExpanded(item: Tree.Item): boolean
        {
            return !!this._expanded[item.path];
        }

        public select(item: Tree.Item): void
        {
            if (item != this.selectedItem)
            {
                this.selectedItem = item;
                this.updated.invoke(this);
            }
        }

        public update(item?: Tree.Item): void
        {
            // Use supplied item or root
            item = (arguments.length > 0) ? item : this.root;

            // Clear tree if no items available
            if (!item)
            {
                if (this.root)
                {
                    this.root = null;
                    this._items = {};
                    this.selectedItem = null;
                    this.updated.invoke(this);
                }
                return;
            }

            // Remove items from map (self and children)
            var existing = this._items[item.path];
            if (existing)
            {
                var items = Tree.getItems(existing);
                items.forEach(item => delete this._items[item.path]);
            }

            // Update parent
            var parentPath = Sparks.Path.getParent(item.path);
            if (parentPath)
            {
                var parent = this._items[parentPath];
                if (!parent)
                    throw new Error("Can't find item '" + parentPath + "'");
                parent.children = parent.children || [];
                parent.children = parent.children.filter(child => child.path != item.path);
                parent.children.push(item);
                parent.children.sort((left, right) => Sparks.String.compare(left.path, right.path));
            }
            else
            {
                this.root = item;
            }

            // Update map
            var items = Tree.getItems(item);
            items.forEach(item => this._items[item.path] = item);

            // Update expanded
            var expanded = Sparks.Map.getKeys(this._expanded);
            var removed = expanded.filter(path => !this._items[path]);
            removed.forEach(path => delete this._expanded[path]);

            // Update selection
            if (this.selectedItem)
            {
                // TODO: select closest parent available
                this.selectedItem = this._items[this.selectedItem.path] || this.root;
            }

            // Invoke updated
            this.updated.invoke(this);
        }

        public static buildHierarchy(items: Tree.Item[]): Tree.Item
        {
            var itemsByPath = Sparks.Array.toMap(items, item => item.path);
            var root: Tree.Item = itemsByPath["/"] = itemsByPath["/"] || { path: "/", type: null, label: "", children: [] };

            items
                .sort((left, right) => Sparks.String.compare(left.path, right.path))
                .forEach(
                    item =>
                    {
                        var parentPath = Sparks.Path.getParent(item.path);
                        if (parentPath)
                        {
                            var parent = itemsByPath[parentPath];
                            if (!parent)
                                throw new Error("No parent item for '" + item.path + "'");
                            parent.children = parent.children || [];
                            parent.children.push(item);
                        }
                        else
                        {
                            item.children = root.children;
                            root = itemsByPath["/"] = item;
                        }
                    });

            return root;
        }

        //#endregion


        //#region Public Events

        public updated = new Sparks.Event();

        //#endregion


        //#region Public Properties

        public root: Tree.Item = null;

        public selectedItem: Tree.Item = null;

        //#endregion


        //#region Private Methods

        private static getItems(item: Tree.Item): Tree.Item[]
        {
            return (item.children && item.children.length > 0) ?
                [item].concat(Sparks.Array.join(item.children.map(child => this.getItems(child)))) :
                [item];
        }

        //#endregion


        //#region Private Fields

        private _items: Sparks.Map<Tree.Item> = {};
        private _expanded: Sparks.Map<boolean> = {};

        //#endregion
    }

    export namespace Tree
    {
        export interface Item
        {
            //#region Properties

            path: string;
            type?: string;
            label: string;
            children?: Tree.Item[];
            data?: any;

            //#endregion
        }
    }
}