
namespace Sparks
{
    export class Error extends Native.Error
    {
        //#region Constructor

        public constructor(message?: string, innerError?: Native.Error)
        {
            super(message);
            //Error.initialize(this);
            
            this.innerError = innerError || null;
        }

        //#endregion


        //#region Public Properties

        public innerError?: Native.Error;

        //#endregion


        ////#region Protected Methods

        //protected static initialize(error: Error): void
        //{
        //    var ctor = arguments.callee.caller;

        //    while (ctor.caller.prototype instanceof Native.Error)
        //        ctor = ctor.caller;

        //    Native.Object['setPrototypeOf'](error, ctor.prototype);
        //}

        ////#endregion
    }
}