///<reference path="../Core/Interop.ts" />
///<reference path="../Native/DOM.ts" />
///<reference path="../Xml/Node.ts" />
///<reference path="../Xml/Element.ts" />
///<reference path="Node.ts" />
///<reference path="HTMLElement.ts" />

namespace Sparks.DOM
{
    export interface IHTMLTextAreaElementExtension
    {
        //#region Methods        

        //#endregion
    }
    
    export class HTMLTextAreaElementExtension
    {
        //#region Public Methods

        //#endregion
    }

    export var HtmlTextAreaElement: IHTMLTextAreaElementExtension =
        Interop.extend(
            Native.HTMLTextAreaElement,
            [
                Sparks.Xml.NodeExtension,
                Sparks.Xml.ElementExtension,
                Sparks.DOM.NodeExtension,
                Sparks.DOM.HTMLElementExtension,
                Sparks.DOM.HTMLTextAreaElementExtension
            ]);
}
