
namespace Sparks.Apps.Forms
{
    export class FieldDirective extends Sparks.UI.Directive
    {
        //#region Constructors

        public constructor(fieldName: string)
        {
            super();
            this.fieldName = fieldName;
        }

        //#endregion


        //#region Public Methods

        public activate(activationContext: Sparks.UI.ActivationContext): void
        {
            // Retrieve FormController
            var formController: FormController = activationContext.scope.get("form");
            if (!formController)
                throw new Error("Unable to resolve " + FormController.name + " from context");

            // Create and assign form controller
            if (activationContext.scope.has(this.fieldName, true))
                console.log("Warning: there is already a symbol '" + this.fieldName + "' in the way");
            var fieldController = new FieldController(<HTMLElement>activationContext.target, this.fieldName);
            formController.addField(fieldController);
            activationContext.scope.set(this.fieldName, fieldController);

            // Finish activation
            activationContext.activator.activate(activationContext);

            // Initialize
            fieldController.updated.add(() => activationContext.scope.update());
            fieldController.initialize();
        }

        //#endregion


        //#region Public Properties

        public fieldName: string;

        //#endregion
    }

    export namespace FieldDirective
    {
        export class FieldDirectiveCompiler extends Sparks.UI.BaseAttributeDirectiveCompiler
        {
            //#region Constructor

            public constructor()
            {
                super("sx-field");
            }

            //#endregion


            //#region Public Methods

            public compile(node: HTMLElement, compilationContext: Sparks.UI.CompilationContext): Sparks.UI.Directive
            {
                var name = this.getArguments(node);
                return new FieldDirective(name);
            }

            //#endregion
        }
    }
}