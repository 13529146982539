
namespace Simpleo.CustomerPortal
{
    export class InvoiceManager implements IService
    {
        //#region Constructor

        public constructor()
        {
        }

        //#endregion


        //#region Public Methods

        public initialize(services: Application.ServiceProvider): void
        {
            this.invoiceManagementService = services.invoiceManagementService;

            this.serviceManager = services.serviceManager;
            this.serviceManager.updated.add(() => this.update());

            this.serviceViewer = services.serviceViewer;

            this.uiManager = services.uiManager;
        }

        public payInvoice(invoice: InvoiceInfo): Promise<void>
        {
            var renewedService = this.serviceManager.services.find(service => service.renewal && service.renewal.invoiceNumber == invoice.number);
            if (renewedService)
            {
                this.serviceViewer.load(renewedService);
                return this.serviceViewer.payRenewal();
            }
            else
            {
                return this.uiManager.showMessage("Pay invoice", "We can't process payment for this invoice at this time.");
            }
        }

        public update(): Promise<void>
        {
            if (!this._update)
            {
                var update = this._update =
                    this.invoiceManagementService.getInvoices().then(
                        invoices =>
                        {
                            this.invoices = invoices;
                            this.updated.invoke(this);
                        });
                update.finally(() => this._update = null);
            }

            return this._update;
        }

        //#endregion


        //#region Public Events

        public updated = new Sparks.Event();

        //#endregion


        //#region Public Properties

        public invoices: InvoiceInfo[] = null;

        //#endregion


        //#region Protected Methods

        //#endregion


        //#region Protected Properties

        protected invoiceManagementService: InvoiceManagementService;
        protected serviceManager: ServiceManager;
        protected serviceViewer: ServiceViewer;
        protected uiManager: UIManager;

        //#endregion


        //#region Private Fields

        private _update: Promise<void> = null;

        //#endregion
    }
}
